import { useQuery } from "react-query";
import axios from "infra/http";
import { B2cGroupDetails } from "core/features/b2c/typings";

function useB2cGroup(id?: string) {
  const fetchData = async (): Promise<B2cGroupDetails> => {
    const { data } = await axios.get<B2cGroupDetails>(`/v2/b2c/groups/${id}`);

    return data;
  };

  return useQuery(`/v2/b2c/groups/${id}`, fetchData, {
    enabled: !!id,
  });
}

export default useB2cGroup;
