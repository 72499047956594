import { CiHeart } from 'react-icons/ci';
import { LiaBombSolid } from 'react-icons/lia';
import { PiHeadphonesLight, PiWarningDiamond } from 'react-icons/pi';
import { GoUnverified } from 'react-icons/go';
import EyesIcon from 'components/icons/EyesIcon';
import { t } from 'core/resources/strings';
import brFlag from 'assets/images/flags/br.svg';
import esFlag from 'assets/images/flags/es.svg';
import usFlag from 'assets/images/flags/us.svg';
import heart from 'assets/images/safespace/heart.png';
import eyes from 'assets/images/safespace/eyes.png';
import attention from 'assets/images/safespace/attention.png';
import problem from 'assets/images/safespace/serious-problem.png';
import notClassified from 'assets/images/safespace/not-classified.png';
import technical from 'assets/images/Lumi_com_microfone.png';
import bad from 'assets/images/sad.png';
import good from 'assets/images/happy.png';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import verybad from 'assets/images/angry.png';


export interface FilterType {
  [key: string]: {
    value: any;
    label: string;
  }[];
}
export interface FilterProps {
  onChangeFilters: (value: any) => void;
  filters: {
    [key: string]: {
      value: any;
      label: string;
    }[];
  };
}


export const safeSpaceFlags = [
  {
    label: t('app.naoClassificado'),
    bg: '#F5F5F5',
    color: '#7B7B7B',
    icon: GoUnverified,
    value: 0,
    shortDescription: t('app.safeSpaceFlag0ShortDescription'),
    description: t('app.safeSpaceFlag0Description'),
    image: notClassified,
  },
  {
    label: t('app.coracaoQuentinho'),
    bg: '#CEEACF',
    color: '#336732',
    icon: CiHeart,
    value: 1,
    shortDescription: t('app.safeSpaceFlag1ShortDescription'),
    description: t('app.safeSpaceFlag1Description'),
    image: heart,
  },
  {
    label: t('app.bomFicarDeOlho'),
    bg: '#B2EBFE',
    color: '#0067AA',
    icon: EyesIcon,
    value: 2,
    shortDescription: t('app.safeSpaceFlag2ShortDescription'),
    description: t('app.safeSpaceFlag2Description'),
    image: eyes,
  },
  {
    label: t('app.redobreAtencao'),
    bg: '#FFE4A9',
    color: '#8A4C00',
    icon: PiWarningDiamond,
    value: 3,
    shortDescription: t('app.safeSpaceFlag3ShortDescription'),
    description: t('app.safeSpaceFlag3Description'),
    image: attention,
  },
  {
    label: t('app.problemaSerio'),
    bg: '#FFCDD3',
    color: '#BB191E',
    icon: LiaBombSolid,
    value: 4,
    shortDescription: t('app.safeSpaceFlag4ShortDescription'),
    description: t('app.safeSpaceFlag4Description'),
    image: problem,
  },
  {
    label: t('app.suporte'),
    bg: '#EFE8F6',
    color: '#452392',
    icon: PiHeadphonesLight,
    value: 5,
    shortDescription: t('app.safeSpaceFlag5ShortDescription'),
    description: t('app.safeSpaceFlag5Description'),
    image: technical,
  },
];

export const languages = [
  {
    name: t('app.portugues'),
    flag: brFlag,
    value: 'pt-br',
  },
  {
    name: t('app.ingles'),
    flag: usFlag,
    value: 'en',
  },
  {
    name: t('app.espanhol'),
    flag: esFlag,
    value: 'es',
  },
];

export const storeItemTypes = [
  {
    label: 'Ícone',
    value: 'icon',
  },
  {
    label: 'Moldura',
    value: 'frame',
  },
  {
    label: 'Fundo',
    value: 'background',
  },
  {
    label: 'Personagem',
    value: 'character',
  },
  {
    label: 'Cenário',
    value: 'scenario',
  },
  {
    label: 'Objeto',
    value: 'object',
  },
]

export const emotions = [
  {
    color: '#FFBC00',
    name: t('app.feliz'),
    img: good,
    id: 1,
    intensities: [
      {
        id: 1,
        text: {
          'pt-br': 'Contente',
          en: 'Content',
          es: 'Contento',
        },
        phrases: [
          {
            'pt-br': 'Tô de boa, igual gato tomando sol na janela!',
            en: 'I\'m fine, like a cat taking sun on the window!',
            es: 'Estoy bien, como un gato tomando sol en la ventana!'
          },
          {
            'pt-br': 'Nada espetacular, mas também nada ruim. Nota 5 de felicidade!',
            en: 'Not spectacular, but also not bad. Note 5 of happiness!',
            es: 'No es espectacular, pero tampoco es malo. Nota 5 de felicidad!',
          },
        ],
      },
      {
        id: 2,
        text: {
          'pt-br': 'Empolgado',
          en: 'Excited',
          es: 'Emocionado',
        },
        phrases: [
          {
            'pt-br': 'Se a vida fosse um filme, agora estaria tocando minha trilha sonora favorita!',
            en: 'If life were a movie, now I would be playing my favorite soundtrack!',
            es: 'Si la vida fuera una película, ahora estaría tocando mi banda sonora favorita!',
          },
          {
            'pt-br': 'Feliz igual adolescente quando recebe um "oi" do crush na escola.',
            en: 'Happy like a teenager when receiving a "hi" from a crush at school!',
            es: 'Feliz como adolescente cuando recibe un "hola" de su crush en la escuela!',
          },
        ],
      },
      {
        id: 3,
        text: {
          'pt-br': 'Alegre',
          en: 'Happy',
          es: 'Alegre',
        },
        phrases: [
          {
            'pt-br': 'Hoje, nem café requentado estraga meu dia!',
            en: 'Today, even the requent coffee doesn\'t spoil my day!',
            es: 'Hoy, incluso el café requentado no me arruina el día!',
          },
          {
            'pt-br': 'Se felicidade fosse dinheiro, eu estaria pagando boleto sorrindo!',
            en: 'If happiness were money, I would be paying the bill smiling!',
            es: 'Si la felicidad fuera dinero, estaría pagando el recibo sonriendo!',
          },
        ],
      },
      {
        id: 4,
        text: {
          'pt-br': 'Animado',
          en: 'Vivacious',
          es: 'Animado',
        },
        phrases: [
          {
            'pt-br': 'Tô igual criança no parque de diversões: rindo à toa e pronto pra roda-gigante!',
            en: 'I\'m like a child at the amusement park: laughing at nothing and ready for the carousel!',
            es: 'Soy como un niño en el parque de diversiones:riendo sin sentido y listo para la rueda de la fortuna!',
          },
          {
            'pt-br': 'Se eu fosse um emoji, seria aquele que a avó manda junta com a mensagem de bom dia!',
            en: 'If I were an emoji, I would be the one that the grandmother sends together with the message of good morning!',
            es: 'Si fuera un emoji, sería el que la abuela envía junto con el mensaje de buenos días!',
          },
        ],
      },
      {
        id: 5,
        text: {
          'pt-br': 'Eufórico',
          en: 'Ecstatic',
          es: 'Eufórico',
        },
        phrases: [
          {
            'pt-br': 'Tô vibrando mais que o celular silencioso!',
            en: 'I\'m vibrating more than the silent phone!',
            es: 'Estoy vibrando más que el teléfono silencioso!',
          },
          {
            'pt-br': 'Quem tem limite é município, hoje eu tô sem!',
            en: 'Who has limits is the city, today I don\'t have any!',
            es: 'Quien tiene límites es la ciudad, hoy no tengo ninguno!',
          },
        ],
      },
    ],
  },
  {
    color: '#216EB4',
    name: t('app.triste'),
    img: bad,
    id: 2,
    intensities: [
      {
        id: 1,
        text: {
          'pt-br': 'Entediado',
          en: 'Bored',
          es: 'Aburrido'
        },
        phrases: [
          {
            'pt-br': 'Se olhar pro teto fosse um esporte, eu já tinha ganhado medalha de ouro!',
            en: 'If looking at the ceiling was a sport, I would have won a gold medal!',
            es: '¡Si mirar al techo fuera un deporte, ya habría ganado la medalla de oro!'
          },
          {
            'pt-br': 'Tô tão sem emoção que até minha sombra tá desistindo de me seguir!',
            en: 'I\'m so emotionless that even my shadow is giving up following me!',
            es: '¡Estoy tan sin emoción que hasta mi sombra está renunciando a seguirme!'
          }
        ],
      },
      {
        id: 2,
        text: {
          'pt-br': 'Tristonho',
          en: 'Gloomy',
          es: 'Melancólico'
        },
        phrases: [
          {
            'pt-br': 'Se eu fosse um dia da semana, seria segunda-feira chuvosa… depois das férias!',
            en: 'If I were a day of the week, I would be a rainy Monday... after vacation!',
            es: '¡Si fuera un día de la semana, sería un lunes lluvioso... después de las vacaciones!'
          },
          {
            'pt-br': 'Tô mais triste que acordar é ver que o celular não carregou a noite toda.',
            en: 'I\'m sadder than waking up to see that the phone didn\'t charge all night.',
            es: 'Estoy más triste que despertar y ver que el teléfono no se cargó toda la noche.'
          }
        ],
      },
      {
        id: 3,
        text: {
          'pt-br': 'Angustiado',
          en: 'Distressed',
          es: 'Angustiado'
        },
        phrases: [
          {
            'pt-br': 'Igual quando você segura o choro, mas ele fica ali, na fila do SUS da alma.',
            en: 'Like when you hold back tears, but they stay there, in the soul\'s waiting line.',
            es: 'Como cuando contienes el llanto, pero se queda ahí, en la fila de espera del alma.'
          },
          {
            'pt-br': 'Meu peito tá mais apertado que receber por mensagem "precisamos conversar"',
            en: 'My chest is tighter than receiving a "we need to talk" message',
            es: 'Mi pecho está más apretado que recibir un mensaje de "tenemos que hablar"'
          }
        ],
      },
      {
        id: 4,
        text: {
          'pt-br': 'Deprimido',
          en: 'Depressed',
          es: 'Deprimido'
        },
        phrases: [
          {
            'pt-br': 'Se tristeza pagasse em dólar eu já tava usando GUCCI e PRADA',
            en: 'If sadness paid in dollars, I\'d already be wearing GUCCI and PRADA',
            es: 'Si la tristeza pagara en dólares, ya estaría usando GUCCI y PRADA'
          },
          {
            'pt-br': 'Minha energia social e emocional foi sugada igual o último gole de refrigerante no canudo!',
            en: 'My social and emotional energy was sucked out like the last sip of soda through the straw!',
            es: '¡Mi energía social y emocional fue absorbida como el último sorbo de refresco en el popote!'
          }
        ],
      },
      {
        id: 5,
        text: {
          'pt-br': 'Em Desespero',
          en: 'In Despair',
          es: 'Desesperado'
        },
        phrases: [
          {
            'pt-br': 'Chorando tanto que até minha planta está recebendo irrigação extra!',
            en: 'Crying so much that even my plant is getting extra irrigation!',
            es: '¡Llorando tanto que hasta mi planta está recibiendo riego extra!'
          },
          {
            'pt-br': 'Minha alma pediu demissão e deixou só um bilhete dizendo: "Se vira!"',
            en: 'My soul quit and left just a note saying: "Deal with it!"',
            es: '¡Mi alma renunció y solo dejó una nota diciendo: "¡Arréglatelas!"'
          }
        ],
      },
    ],
  },
  {
    color: '#E35809',
    name: t('app.raiva'),
    img: verybad,
    id: 3,
    intensities: [
      {
        id: 1,
        text: {
          'pt-br': 'Incomodado',
          en: 'Annoyed',
          es: 'Molesto'
        },
        phrases: [
          {
            'pt-br': 'Algo tá errado, mas ainda não sei o quê... Só sei que tá me incomodando!',
            en: 'Something\'s wrong, but I don\'t know what yet... I just know it\'s bothering me!',
            es: '¡Algo está mal, pero aún no sé qué... Solo sé que me está molestando!'
          },
          {
            'pt-br': 'Parece que tem um mosquito zumbindo na minha cabeça o dia todo!',
            en: 'Feels like there\'s a mosquito buzzing in my head all day!',
            es: '¡Parece que hay un mosquito zumbando en mi cabeza todo el día!'
          }
        ],
      },
      {
        id: 2,
        text: {
          'pt-br': 'Nervoso',
          en: 'Nervous',
          es: 'Nervioso'
        },
        phrases: [
          {
            'pt-br': 'Se respirar fundo fosse resolver, eu já estaria zen, mas... NÃO TÁ FUNCIONANDO!',
            en: 'If deep breathing would solve it, I\'d be zen by now, but... IT\'S NOT WORKING!',
            es: '¡Si respirar profundo lo resolviera, ya estaría zen, pero... NO ESTÁ FUNCIONANDO!'
          },
          {
            'pt-br': 'Segurando a paciência com um fio de cabelo, e ele tá prestes a arrebentar!',
            en: 'Holding my patience by a hair, and it\'s about to break!',
            es: '¡Sosteniendo mi paciencia por un pelo, y está a punto de romperse!'
          }
        ],
      },
      {
        id: 3,
        text: {
          'pt-br': 'Bravo',
          en: 'Angry',
          es: 'Enfadado'
        },
        phrases: [
          {
            'pt-br': 'Respira... Inspira... Não pira... Ah, quer saber? JÁ PIRANDO!',
            en: 'Breathe in... Breathe out... Don\'t freak out... Oh, you know what? ALREADY FREAKING OUT!',
            es: '¡Respira... Inspira... No te vuelvas loco... Ah, ¿sabes qué? ¡YA ME VOLVÍ LOCO!'
          },
          {
            'pt-br': 'Hoje, se olharem torto pra mim, viro estatística!',
            en: 'Today, if someone looks at me wrong, I\'ll become a statistic!',
            es: '¡Hoy, si alguien me mira mal, me convierto en estadística!'
          }
        ],
      },
      {
        id: 4,
        text: {
          'pt-br': 'Furioso',
          en: 'Furious',
          es: 'Furioso'
        },
        phrases: [
          {
            'pt-br': 'Se eu fosse um desenho animado, já tava soltando fumaça pelas orelhas!',
            en: 'If I were a cartoon, I\'d be letting smoke out of my ears!',
            es: '¡Si fuera un dibujo animado, ya estaría echando humo por las orejas!'
          },
          {
            'pt-br': 'Meu limite? Passou faz tempo! Agora é sobrevivência!',
            en: 'My limit? Passed it long ago! Now it\'s survival mode!',
            es: '¡¿Mi límite? ¡Lo pasé hace tiempo! ¡Ahora es supervivencia!'
          }
        ],
      },
      {
        id: 5,
        text: {
          'pt-br': 'Irado',
          en: 'Enraged',
          es: 'Iracundo'
        },
        phrases: [
          {
            'pt-br': 'Se raiva queimasse calorias, eu já tinha sumido do mapa!',
            en: 'If anger burned calories, I would have vanished from the map!',
            es: '¡Si la rabia quemara calorías, ya habría desaparecido del mapa!'
          },
          {
            'pt-br': 'Chamem os bombeiros porque eu tô em CHAMAS e vou incendiar tudo isso aqui',
            en: 'Call the firefighters because I\'m on FIRE and I\'m going to burn everything down here',
            es: '¡Llamen a los bomberos porque estoy en LLAMAS y voy a incendiar todo esto aquí!'
          }
        ],
      }
    ],
  },
  {
    color: '#00B8B8',
    name: t('app.nojo'),
    img: disgusted,
    id: 4,
    intensities: [
      {
        id: 1,
        text: {
          'pt-br': 'Ojeriza',
          en: 'Aversion',
          es: 'Aversión'
        },
        phrases: [
          {
            'pt-br': 'Olhei, não gostei, já quero distância!',
            en: 'I looked, I didn\'t like it, I already want distance!',
            es: '¡Miré, no me gustó, ya quiero distancia!'
          },
          {
            'pt-br': 'Tô sentindo uma leve necessidade de revirar os olhos a cada cinco segundos!',
            en: 'I\'m feeling a slight need to roll my eyes every five seconds!',
            es: '¡Siento una leve necesidad de poner los ojos en blanco cada cinco segundos!'
          }
        ],
      },
      {
        id: 2,
        text: {
          'pt-br': 'Repulsa',
          en: 'Repulsion',
          es: 'Repulsión'
        },
        phrases: [
          {
            'pt-br': 'Meu corpo inteiro dizendo \'eca\', mas por educação, ainda tô aqui!',
            en: 'My whole body saying \'yuck\', but out of politeness, I\'m still here!',
            es: '¡Todo mi cuerpo diciendo \'qué asco\', pero por educación, ¡todavía estoy aquí!'
          },
          {
            'pt-br': 'Tem coisa que a gente queria desver, mas já tá tatuada na alma!',
            en: 'There are things we\'d like to unsee, but they\'re already tattooed on our soul!',
            es: '¡Hay cosas que quisiéramos desver, pero ya están tatuadas en el alma!'
          }
        ],
      },
      {
        id: 3,
        text: {
          'pt-br': 'Antipatia',
          en: 'Antipathy',
          es: 'Antipatía'
        },
        phrases: [
          {
            'pt-br': 'Com alergia daquela pessoa…!',
            en: 'Allergic to that person...!',
            es: '¡Con alergia a esa persona...!'
          },
          {
            'pt-br': 'Onde fica o botão "pular propaganda" pra gente chata',
            en: 'Where\'s the "skip ad" button for annoying people',
            es: '¡Dónde está el botón de "saltar anuncio" para la gente molesta!'
          }
        ],
      },
      {
        id: 4,
        text: {
          'pt-br': 'Enojado',
          en: 'Disgusted',
          es: 'Asqueado'
        },
        phrases: [
          {
            'pt-br': 'Sabe quando a vibe da pessoa é tão ruim que parece que tem cheiro ruim junto?',
            en: 'You know when someone\'s vibe is so bad it seems to come with a bad smell?',
            es: '¿Sabes cuando la vibra de alguien es tan mala que parece que viene con mal olor?'
          },
          {
            'pt-br': 'Tô engolindo seco, mas por dentro já tô lavando a alma com álcool 70%!',
            en: 'I\'m swallowing dry, but inside I\'m already washing my soul with 70% alcohol!',
            es: '¡Estoy tragando seco, pero por dentro ya estoy lavando mi alma con alcohol al 70%!'
          }
        ],
      },
      {
        id: 5,
        text: {
          'pt-br': 'Nojo',
          en: 'Disgust',
          es: 'Asco'
        },
        phrases: [
          {
            'pt-br': 'Se pudesse deletar isso da minha vida, já tinha formatado a minha memória!',
            en: 'If I could delete this from my life, I would have already formatted my memory!',
            es: '¡Si pudiera borrar esto de mi vida, ya habría formateado mi memoria!'
          },
          {
            'pt-br': 'A vontade de correr é grande, mas a de vomitar é maior!',
            en: 'The urge to run is big, but the urge to throw up is bigger!',
            es: '¡Las ganas de correr son grandes, pero las de vomitar son mayores!'
          }
        ],
      }
    ],
  },
  {
    color: '#8C6B84',
    name: t('app.medo'),
    img: fear,
    id: 5,
    intensities: [
      {
        id: 1,
        text: {
          'pt-br': 'Inseguro',
          en: 'Insecure',
          es: 'Inseguro'
        },
        phrases: [
          {
            'pt-br': 'Será que eu devia estar aqui? Estou tão seguro quanto uma gelatina.',
            en: 'Should I be here? I\'m as secure as jelly.',
            es: '¿Debería estar aquí? Estoy tan seguro como una gelatina.'
          },
          {
            'pt-br': 'Hoje tô parecendo um Wi-Fi público, minha maior segurança é a senha 123456',
            en: 'Today I\'m like public Wi-Fi, my best security is the password 123456',
            es: 'Hoy soy como un Wi-Fi público, mi mejor seguridad es la contraseña 123456'
          }
        ],
      },
      {
        id: 2,
        text: {
          'pt-br': 'Preocupado',
          en: 'Worried',
          es: 'Preocupado'
        },
        phrases: [
          {
            'pt-br': 'Minha cabeça virou uma reunião infinita de \'E se...?\' e nenhum \'Vai dar tudo certo!\'',
            en: 'My head became an endless meeting of \'What if...?\' and no \'It\'ll be alright!\'',
            es: '¡Mi cabeza se convirtió en una reunión infinita de \'¿Y si...?\' y ningún \'¡Todo saldrá bien!\''
          },
          {
            'pt-br': 'Se preocupação desse milhas, eu já tinha dado a volta ao mundo três vezes!',
            en: 'If worry gave miles, I would have gone around the world three times!',
            es: '¡Si la preocupación diera millas, ya habría dado la vuelta al mundo tres veces!'
          }
        ],
      },
      {
        id: 3,
        text: {
          'pt-br': 'Assustado',
          en: 'Scared',
          es: 'Asustado'
        },
        phrases: [
          {
            'pt-br': 'Isso foi um barulho ou um aviso do universo pra eu correr?',
            en: 'Was that a noise or the universe telling me to run?',
            es: '¿Eso fue un ruido o el universo diciéndome que corra?'
          },
          {
            'pt-br': 'Minha alma já saiu do corpo três vezes hoje e ainda nem é meio-dia!',
            en: 'My soul has left my body three times today and it\'s not even noon!',
            es: '¡Mi alma ya ha dejado mi cuerpo tres veces hoy y ni siquiera es mediodía!'
          }
        ],
      },
      {
        id: 4,
        text: {
          'pt-br': 'Aterrorizado',
          en: 'Terrified',
          es: 'Aterrorizado'
        },
        phrases: [
          {
            'pt-br': 'Eu ia correr, mas minhas pernas decidiram que agora somos um enfeite de pedra!',
            en: 'I was going to run, but my legs decided we\'re now a stone ornament!',
            es: '¡Iba a correr, pero mis piernas decidieron que ahora somos un adorno de piedra!'
          },
          {
            'pt-br': 'Se eu piscasse mais forte, já tinha desmaiado!',
            en: 'If I blinked any harder, I would have fainted!',
            es: '¡Si parpadeara más fuerte, ya me habría desmayado!'
          }
        ],
      },
      {
        id: 5,
        text: {
          'pt-br': 'Em pânico',
          en: 'In panic',
          es: 'En pánico'
        },
        phrases: [
          {
            'pt-br': 'SOCORRO! Meu cérebro fechou para balanço e não avisou quando volta!',
            en: 'HELP! My brain closed for inventory and didn\'t say when it\'s coming back!',
            es: '¡SOCORRO! ¡Mi cerebro cerró por inventario y no avisó cuándo vuelve!'
          },
          {
            'pt-br': 'Se fosse um filme de terror, eu seria aquele personagem que tropeça e já sabe que deu ruim!',
            en: 'If this was a horror movie, I\'d be that character who trips and knows it\'s all over!',
            es: '¡Si fuera una película de terror, sería ese personaje que tropieza y ya sabe que todo salió mal!'
          }
        ],
      }
    ],
  },
];

export interface ComparativeItem {
  type: 'student' | 'class';
  id: string;
  name: string;
}

export enum ContentType {
  DIALOGUE = 0,
  DECISION = 1,
  REINFORCEMENT = 2,
  END = 3,
}

export const contentTypes: {
  label: string;
  value: ContentType;
}[] = [
  {
    label: 'Diálogo/Narração',
    value: 0,
  },
  {
    label: 'Pergunta com Decisão',
    value: 1,
  },
  {
    label: 'Pergunta de Reforço',
    value: 2,
  },
  {
    label: 'Final da História',
    value: 3,
  },
];


export type SoundEffect = {
  id: number;
  audioUrl: string;
  triggerTime: 'start' | 'end';
};
