import { Checkbox, Flex } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { StoreItemDetails } from 'core/features/store-items/typings';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';

function EventItemCard({
  item,
  value,
  onCheckChange,
  disabled,
}: {
  item: StoreItemDetails;
  value?: boolean;
  onCheckChange?: (checked: boolean) => void;
  disabled?: boolean;
}) {
  const { colorPrimary } = useU4heroColors();

  return (
    <GenericCard padding="0px 16px 16px 16px" height="100%">
      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Flex
          style={{
            background: colorPrimary,
            padding: '9px 33px',
            borderRadius: '0px 0px 10px 10px',
            width: 'fit-content',
            alignSelf: 'center',
          }}
        >
          <Text color="white" bold>
            {item.name}
          </Text>
        </Flex>
        <Flex
          style={{
            borderRadius: '20px',
            background: '#F5F5F5',
            boxShadow:
              '0px -0.5px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            padding: '8px 8px 0px 8px',
            position: 'relative',
          }}
          vertical
        >
          <Checkbox
            checked={value}
            onChange={(e) => onCheckChange?.(e.target.checked)}
            disabled={disabled}
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
              zIndex: 1,
            }}
          />
          <img src={item.imageUrl} alt={item.name} />
          <Text
            style={{
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
              padding: '4px 8px',
              width: 'fit-content',
              borderRadius: '5px',
              alignSelf: 'center',
              position: 'absolute',
              bottom: -15,
              background: '#F5F5F5',
            }}
            size="sm"
          >
            {t(`app.${item.type}`)}
          </Text>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default EventItemCard;
