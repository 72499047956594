import { useQuery } from 'react-query'
import axios from 'infra/http'
import { EventDetails } from '../typings'
import { useEffect, useState } from 'react';


export interface UsePostsParams {
  page: number;
  search?: string;
  active?: boolean;
  limit?:number;
}

function useBlogPosts(params: UsePostsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UsePostsParams
  ): Promise<{ data: EventDetails[]; total: number }> => {
    const { data, headers } = await axios.get<EventDetails[]>(`/v2/events/`, {
      params: {

        ...params,
        search: debouncedQuery,
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/events`,{...params,search:debouncedQuery}],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  )

}

export default useBlogPosts
