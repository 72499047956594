import { useMutation } from "react-query";
import http from "infra/http";

function useToggleEvent() {
  return useMutation(
    (param: { id: string; isActive: boolean }) => {
      return http.put(

        `/v2/events/${param.id}/${param.isActive ? "deactivate" : "active"}`
      );
    },
    { retry: false }
  );
}
export default useToggleEvent