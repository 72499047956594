import { Flex } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useStudentAutoAvaliation from 'core/features/students/hooks/useStudentAutoAvaliation';
import { t } from 'core/resources/strings';
import lumi from 'assets/images/lumi-empty.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import AutoAvaliationCard from 'components/Views/autoAvaliationCard';
import { currentLocale } from 'core/resources/strings/polyglot';

function AutoAvaliationResults({
  startDate,
  endDate,
  userId,
  classId,
}: {
  startDate: string;
  endDate: string;
  userId?: string;
  classId?: string;
}) {
  const { colorGreyBorderAndFont } = useU4heroColors();
  const { data } = useStudentAutoAvaliation({
    userId: userId,
    classId: classId,
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
  });

  return (
    <GenericCard height="100%">
      <Flex
        vertical
        gap={12}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <Flex justify="space-between">
          <SubTitle primary>{t('app.resultadoDaAutoAvaliacao')}</SubTitle>
          {data?.data?.[0]?.createdAt && (
            <Text size="sm" color={colorGreyBorderAndFont}>
              {dayjs(data?.data?.[0]?.createdAt).format('DD/MM/YYYY')}
            </Text>
          )}
        </Flex>
        {data && data?.data?.length > 0 ? (
          data?.data?.map((item, index) => (
            <AutoAvaliationCard
              key={index}
              competence={item.name[currentLocale]}
              percentage={item.percentage}
              background={item.background}
              borderColor={item.borderColor}
              image={item.image}
            />
          ))
        ) : (
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ height: '100%' }}
            gap={12}
          >
            <img src={lumi} alt="lumi" />
            <Text align="center" size="sm" color={colorGreyBorderAndFont}>
              {t('app.mensagemNaoRealizouAutoAvaliacao')}
            </Text>
          </Flex>
        )}
      </Flex>
    </GenericCard>
  );
}

export default AutoAvaliationResults;
