import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import { Col, Flex, Pagination, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import { FilterType } from 'infra/helpers/types';
import { useEffect, useState } from 'react';
import SearchBar from 'components/V2/SearchBar';
import Button from 'components/buttons/button';
import lumiEmpty from 'assets/images/lumi-no-events.gif';
import lumiSearching from 'assets/images/lumi-searching.png';
import SubTitle from 'components/typography/subTitle';
import EventsFilter from 'ui/pages/app/posts-hub/events/components/EventsFilter';
import { useHistory } from 'react-router-dom';
import useEvents from 'core/features/events/hooks/useEvents';
import EventCard from 'ui/pages/app/posts-hub/events/components/EventCard';

function EventsManager() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [search, setSearch] = useState('');

  const history = useHistory();
  const [filters, setFilters] = useState<FilterType>({
    status: [],
  });

  const { data, isLoading } = useEvents({
    page,
    limit: pageSize,
    search,
    active: filters.status?.length
      ? filters.status.map((item) => item.value)[0] === 1
      : undefined,
  });

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: isMobile ? '16px' : '24px',
        }}
      >
        Gerenciador de Eventos
      </Title>

      <Flex
        vertical
        gap="8px"
        style={{ marginBottom: isMobile ? '20px' : '40px' }}
      >
        <Flex vertical gap={16}>
          <Button
            size="md"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => history.push('/events/create')}
          >
            Criar evento
          </Button>

          <Flex gap="16px" align="center">
            <SearchBar
              onSearch={setSearch}
              width="100%"
              placeholder="Pesquisar evento"
            />
            <EventsFilter filters={filters} onChangeFilters={setFilters} />
          </Flex>
        </Flex>

        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </Flex>

      {isLoading ? <Spin size={'large'} /> : null}

      {data && data.data.length > 0 ? (
        <>
          <Row gutter={[36, isMobile ? 24 : 32]} align="stretch">
            {data?.data.map((item) => (
              <Col key={item.id} span={isMobile ? 24 : 8}>
                <EventCard
                  key={item.id}
                  item={item}
                  onEdit={(id) => {
                    history.push(`/events/edit/${id}`);
                  }}
                  onView={(id) => {
                    history.push(`/events/edit/${id}?preview=true`);
                  }}
                />
              </Col>
            ))}
          </Row>
          <Flex
            justify="center"
            style={{
              marginTop: isMobile ? '18px' : '26px',
              marginBottom: isMobile ? '10px' : '18px',
            }}
          >
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              current={page}
              pageSize={pageSize}
              onChange={(v) => setPage(v)}
              onShowSizeChange={(v, size) => setPageSize(size)}
              total={data?.total}
            />
          </Flex>
        </>
      ) : null}

      {data?.data.length === 0 && !search && filters.status.length === 0 ? (
        <Flex
          vertical
          gap={16}
          style={{ marginBottom: '100px' }}
          justify="center"
          align="center"
        >
          <img
            src={lumiEmpty}
            style={{ maxWidth: '600px' }}
            alt={'nenhum item encontrado'}
          />
          <SubTitle>Nenhum evento criado!</SubTitle>
          <Text size="lg" align="center">
            Atualmente, não há eventos disponíveis. Que tal criar o primeiro
            agora?
          </Text>
          <Button size="md" onClick={() => history.push('/events/create')}>
            Criar evento
          </Button>
        </Flex>
      ) : null}

      {data?.data.length === 0 && (search || filters.status.length > 0) ? (
        <Flex
          vertical
          gap={8}
          style={{ marginBottom: '100px' }}
          justify="center"
          align="center"
        >
          <img src={lumiSearching} width={198} alt={'nenhum item encontrado'} />
          <SubTitle>Evento não encontrado</SubTitle>
          <Text size="lg" align="center">
            Ops, não há eventos com esse nome. Por favor, verifique e tente
            novamente!
          </Text>
        </Flex>
      ) : null}
    </Layout.Container>
  );
}

export default EventsManager;
