import moment from 'moment';
import { SafeSpaceItem } from 'core/features/safeSpace/typings';
import { Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import useSafespaces from 'core/features/safeSpace/hooks/useSafeSpaces';
import { useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Classification from '../Classification';
import Status from 'components/status';
import Dot from 'components/dot';
import SafeSpaceFilter from '../SafeSpaceFilter';
import { useHistory, useLocation } from 'react-router-dom';
import Tag from 'components/V2/tag';
import { useToggleMessage } from 'core/features/safeSpace/hooks/useToggleMessage';
import { FilterType, safeSpaceFlags } from 'infra/helpers/types';
import { t } from 'core/resources/strings';
import GroupButton from 'components/buttons/group-button';
import lumiInDesert from 'assets/images/lumi-in-desert.gif';
import emptySafespace from 'assets/images/white-label/empty-safespace.gif';
import { useCurrentUser } from 'core/features/user/store';

function SafeSpaceTable({
  showClassification = false,
  unseen = false,
  showNotProcessed = false,
}: {
  showClassification?: boolean;
  unseen?: boolean;
  showNotProcessed?: boolean;
}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const { mutate: handleToggle } = useToggleMessage();
  const { user } = useCurrentUser();
  const [classification, setClassification] = useState<number>(4);
  const [filters, setFilters] = useState<FilterType>({
    types: [],
    series: [],
    classes: [],
    status: [],
  });
  const { data, isLoading, isFetching } = useSafespaces({
    page,
    search,
    series: filters?.series?.map((item) => item.value) ?? undefined,
    classes: filters?.classes?.map((item) => item.value) ?? undefined,
    anonymous: filters?.types.map((item) => item.value).includes('anonymous'),
    disableConcat: true,
    status: filters?.status?.map((item) => item.value) ?? undefined,
    unseen: unseen ?? undefined,
    limit: pageSize,
    flags: !unseen && !showNotProcessed ? [classification] : undefined,
    notProcessed: showNotProcessed ?? undefined,
  });

  const isEachLoading = isLoading || isFetching;

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  console.log('classification->', classification);
  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.nome')}</p>,
      name: t('app.nome'),
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (name: string, record: SafeSpaceItem) => (
        <Flex gap={8} align="center" style={tableRowStyle}>
          {!record.viewed ? <Dot color={colorPrimary} size={8} /> : null}{' '}
          {record.anonymous ? t('app.anonimo') : record.name}
        </Flex>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.name || t('app.anonimo');
        const nameB = b?.name || t('app.anonimo');
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.classificacao')}</p>,
      name: t('app.classificacao'),
      dataIndex: 'classification',
      key: 'classification',
      mobile: true,
      render: (_: string, record: SafeSpaceItem) => (
        <div>
          {showClassification ? (
            <>
              <p style={{ fontSize: '12px' }}>
                {record.teacherClassification === Number(classification)
                  ? t('app.educador')
                  : t('app.IA')}{' '}
              </p>
              <Classification
                size={isMobile ? 'sm' : 'md'}
                type={Number(classification)}
              />
            </>
          ) : (
            <>
              <p style={{ fontSize: '12px' }}>
                {record.teacherClassification !== 0
                  ? t('app.educador')
                  : t('app.IA')}{' '}
              </p>
              {record.teacherClassification !== 0 ? (
                <Classification
                  size={isMobile ? 'sm' : 'md'}
                  type={record.teacherClassification}
                />
              ) : (
                <Classification
                  size={isMobile ? 'sm' : 'md'}
                  type={record.systemClassification}
                />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.data')}</p>,
      name: t('app.data'),
      dataIndex: 'date',
      key: 'date',
      render: (date: string, record: SafeSpaceItem) => (
        <div style={tableRowStyle}>
          {moment.utc(record.date).format('DD/MM/YYYY')}
        </div>
      ),
      sorter: (a: any, b: any) => a.date.localeCompare(b.date),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.sérieTurma')}</p>,
      name: t('app.sérieTurma'),
      dataIndex: 'class.name',
      key: 'class.name',
      render: (_: string, record: SafeSpaceItem) => (
        <div style={tableRowStyle}>
          <p>{record.class.name}</p>
          <Tag text={record.class.serie.name} />
        </div>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.class.name || '';
        const nameB = b?.class.name || '';
        return nameA.localeCompare(nameB);
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.status')}</p>,
      name: t('app.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: SafeSpaceItem) => (
        <Status
          size={isMobile ? 'sm' : 'md'}
          type={
            record.status === 0
              ? 'notStarted'
              : record.status === 1
              ? 'progress'
              : 'done'
          }
        />
      ),
      sorter: (a: any, b: any) => b.status - a.status,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
  ];

  let location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search.includes('flag')) {
      const flag = queryParams.get('flag');
      const safeSpaceFlag = safeSpaceFlags.find(
        (item) => item.value === Number(flag)
      );
      if (safeSpaceFlag) {
        setFilters({
          ...filters,
          flags: [{ label: safeSpaceFlag?.label, value: safeSpaceFlag?.value }],
        });
      }
    }

    if (location.search.includes('classId')) {
      const id = queryParams.get('classId');
      if (id) {
        setFilters({
          ...filters,
          classes: [{ label: t('app.turma'), value: id }],
        });
      }
    }
    if (location.search.includes('search')) {
      const query = queryParams.get('search');
      if (query !== null) {
        setSearch(query);
      }
    }
  }, [location]);

  useEffect(() => {
    setPage(1);
  }, [search, filters, classification]);

  const flags = safeSpaceFlags
    .sort((a, b) => b.value - a.value)
    .map((item) => ({
      label: item.label,
      value: item.value,
    }));

  return (
    <>
      {showClassification && (
        <Flex
          justify="flex-end"
          style={{ marginTop: '8px', marginBottom: '20px' }}
        >
          <GroupButton
            options={flags}
            value={classification!}
            onChange={(v) => setClassification(Number(v))}
          />
        </Flex>
      )}

      {(data && data.data.length > 0) || isLoading ? (
        <DynamicTable
          id="id-tour-safe-space-section-table"
          itemName={t('app.registro').toLowerCase()}
          data={data?.data ?? []}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          title=""
          total={data?.total ?? 0}
          page={page}
          pageSize={pageSize}
          isLoading={isEachLoading}
          onClickRow={(row) => {
            handleToggle([row.id]);
            history.push(`/safe-space/${row.id}`);
          }}
          defaultColumns={
            isMobile ? columns.filter((item) => item.mobile) : columns
          }
          allColumns={columns}
          onSearch={setSearch}
          search={search}
          appliedFilters={filters}
          onChangeFilters={setFilters}
          filters={
            <SafeSpaceFilter onChangeFilters={setFilters} filters={filters} />
          }
        />
      ) : (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ marginTop: '40px' }}
        >
          <img
            src={user?.whiteLabel ? emptySafespace : lumiInDesert}
            alt="No records"
            width="420px"
          />
          <p>{t('app.naoHaNenhumRegistroNoMomento')}</p>
        </Flex>
      )}
    </>
  );
}

export default SafeSpaceTable;
