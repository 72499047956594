import { useMediaQuery } from '@chakra-ui/react';
import DynamicTable from 'components/V2/dinamic-table';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { Dropdown, Flex, Menu } from 'antd';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  notificateSucess,
  notificateError,
  showConfirm,
} from 'infra/helpers/notifications';
import Status from 'components/status';
import useUsers from 'core/features/user/hooks/useUsers';
import { UserDetails } from 'core/features/user/typings';
import { FilterType } from 'infra/helpers/types';
import { useToggleUser } from 'core/features/user/hooks/useToggleUser';
import Tag from 'components/V2/tag';
import { t } from 'core/resources/strings';
import CollaboratorsFilter from 'ui/pages/app/mySchool/pages/B2cUsers/components/CollaboratorsFilter';
import { calcAge } from 'infra/helpers/date';
import dayjs from 'dayjs';
import { B2cGroupDetails } from 'core/features/b2c/typings';
import useB2cGroups from 'core/features/b2c/hooks/useB2cGroups';

function B2cGroupsTable({ onEdit }: { onEdit: (v: B2cGroupDetails) => void }) {
  const [check] = useCheckPermissions();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const { mutate: handleToggle, isLoading: isLoadingToggle } = useToggleUser();
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    profiles: [],
    status: [],
  });
  const { tenantId } = useParams<{ tenantId: string }>();

  const { data, isLoading, isFetching, refetch } = useB2cGroups({
    page,
    limit: pageSize,
    search,
    tenantId,
  });

  const isEachLoading = isLoading || isFetching;

  useEffect(() => {
    setPage(1);
  }, [filters, search]);

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.nome')}</p>,
      name: t('app.nome'),
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (_: string, record: B2cGroupDetails) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: B2cGroupDetails, b: B2cGroupDetails) =>
        a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.participantes')}</p>,
      name: t('app.participantes'),
      dataIndex: 'participants',
      key: 'participants',
      render: (_: string, record: B2cGroupDetails) => (
        <p style={tableRowStyle}>{record.participants.length}</p>
      ),
      sorter: (a: B2cGroupDetails, b: B2cGroupDetails) =>
        (a.participants?.length || 0) - (b.participants?.length || 0),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.dataDeCriacao')}</p>,
      name: t('app.dataDeCriacao'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, record: B2cGroupDetails) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: B2cGroupDetails, b: B2cGroupDetails) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.status')}</p>,
      name: t('app.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: B2cGroupDetails) => (
        <div>
          {record.active ? (
            <Status type={'active'} />
          ) : (
            <Status type={'inactive'} />
          )}
        </div>
      ),
      sorter: (a: B2cGroupDetails, b: B2cGroupDetails) =>
        Number(a.active) - Number(b.active),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.acoes')}</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: B2cGroupDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(event) => {
                if (event.key === 'edit') {
                  onEdit(record);
                }
                if (event.key === 'toggle') {
                  showConfirm({
                    message: `${t('app.temCertezaQueDeseja')} ${
                      record.active
                        ? t('app.desativar').toLowerCase()
                        : t('app.ativar').toLowerCase()
                    } ${t('app.oCadastroDoColaborador')}`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      if (
                        check([
                          Permissions.Collaborators.Activate,
                          Permissions.U4hero.Collaborators.Activate,
                          Permissions.Org.Collaborators.Activate,
                        ])
                      ) {
                        handleToggle(
                          {
                            id: record.id,
                            isActive: record.active,
                          },
                          {
                            onSuccess: () => {
                              refetch();
                              notificateSucess(
                                `${t('app.oCadastroDoColaboradorFoi')} ${
                                  record.active
                                    ? t('app.desativado')
                                    : t('app.ativado')
                                } ${t('app.comSucesso')}!`
                              );
                            },
                            onError: () =>
                              notificateError(
                                `${t('app.houveUmProblemaAo')} ${
                                  record.active
                                    ? t('app.desativar').toLowerCase()
                                    : t('app.ativar').toLowerCase()
                                } ${t('app.oCadastro')}. ${t(
                                  'app.verifiqueSuaConexaoETenteNovamente'
                                )}`
                              ),
                          }
                        );
                      } else {
                        notificateError(
                          `${t('app.voceNaoTemPermissaoPara')} ${
                            record.active
                              ? t('app.desativar').toLowerCase()
                              : t('app.ativar').toLowerCase()
                          } ${t('app.oCadastro')} ${t('app.do colaborador')}!`
                        );
                      }
                    }
                  });
                }
              }}
            >
              <Menu.Item key="edit">
                <p>{t('app.editar')}</p>
              </Menu.Item>
              <Menu.Item key={'toggle'}>
                <p>{record.active ? t('app.desativar') : t('app.ativar')}</p>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            size={24}
            style={{ cursor: 'pointer' }}
            id="id-tour-action-collaborator-table"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <Flex gap={8} vertical>
      <DynamicTable
        id="id-tour-my-school-b2c-users-table"
        itemName={t('app.grupo')}
        data={data?.data ?? []}
        onChangePage={setPage}
        appliedFilters={filters}
        onChangeFilters={setFilters}
        filters={
          <CollaboratorsFilter filters={filters} onChangeFilters={setFilters} />
        }
        onChangePageSize={setPageSize}
        title=""
        total={data?.total ?? 0}
        page={page}
        pageSize={pageSize}
        isLoading={isEachLoading}
        defaultColumns={
          isMobile ? columns.filter((column) => column.mobile) : columns
        }
        allColumns={columns}
        onSearch={setSearch}
      />
    </Flex>
  );
}

export default B2cGroupsTable;
