import { Flex, Progress, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { ClockCircleOutlined } from '@ant-design/icons';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router-dom';
import ModalStudentLogins from 'components/modals/modal-student-logins';
import U4Avatar from 'components/u4avatar';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';
import ObservationDrawer from 'ui/pages/app/mySchool/pages/Students/components/StudentPanel/components/observationDrawer';
import { IStudentInfo } from 'core/features/students/typings';
import { emotions } from 'infra/helpers/types';

function StudentInformation({ userInfo }: { userInfo: IStudentInfo }) {
  const { id } = useParams<{ id: string }>();
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emotion, setEmotion] = useState<{
    color: string;
    name: string;
  } | null>(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const predominantEmotion = userInfo?.predominantEmotion.toLowerCase();

    if (predominantEmotion) {
      const emotion = emotions.find((emotion) =>
        emotion.name.toLowerCase().includes(predominantEmotion)
      );
      if (emotion) {
        setEmotion(emotion);
      }
    }
  }, [userInfo?.predominantEmotion]);

  return (
    <GenericCard width={'100%'} heigth={'189px'}>
      {isOpenModal && (
        <ModalStudentLogins userId={id} onClose={() => setIsOpenModal(false)} />
      )}
      {isMobile ? (
        <Flex gap={16} align="center" style={{ width: '100%' }} vertical>
          <Flex justify="center" align="center" gap={29}>
            <Flex
              align="center"
              justify="start"
              style={{ marginRight: '24px' }}
              vertical
            >
              <U4Avatar
                icon={userInfo?.avatar?.iconImageUrl ?? defaultIcon}
                background={
                  userInfo?.avatar?.backgroundImageUrl ?? defaultBackground
                }
                frame={userInfo?.avatar?.frameImageUrl ?? defaultFrame}
                size="large"
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  strokeColor={emotion?.color}
                  trailColor="#F5F5F5"
                  status="normal"
                  percent={userInfo?.emotionPercentage}
                />
              </Flex>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: emotion?.color,
                }}
              >
                {emotion?.name}
              </p>
            </Flex>
            <Flex gap={12} vertical>
              <p style={{ color: 'black', fontSize: '14px' }}>
                {userInfo?.name}
              </p>
              <p
                style={{ color: 'black', fontSize: '14px' }}
              >{`${userInfo?.age} anos`}</p>
              <p style={{ color: 'black', fontSize: '14px' }}>
                {userInfo?.className
                  ? `${userInfo?.className} - ${t(`app.${userInfo?.period}`)}`
                  : '-'}
              </p>
              <p style={{ color: 'black', fontSize: '14px' }}>
                {userInfo?.lastSync
                  ? dayjs(userInfo?.lastSync).format('DD/MM/YYYY HH[h]mm')
                  : '-'}
                <ClockCircleOutlined style={{ marginLeft: '15.79px' }} />
              </p>
            </Flex>
          </Flex>

          <Flex style={{ width: '100%' }} justify="space-around">
            <Button onClick={() => setIsDrawerOpen(true)} size="lg">
              {t('app.observacao')}
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => setIsOpenModal(true)}
            >
              {t('app.historicoDeAcessos')}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex style={{ width: '100%' }} gap={16} justify="space-between">
          <Flex justify="center" align="center" gap={58}>
            <Flex
              align="center"
              justify="start"
              style={{ marginRight: '24px' }}
              vertical
            >
              <U4Avatar
                frame={userInfo?.avatar?.frameImageUrl ?? defaultFrame}
                background={
                  userInfo?.avatar?.backgroundImageUrl ?? defaultBackground
                }
                icon={userInfo?.avatar?.iconImageUrl ?? defaultIcon}
                size="large"
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  status="normal"
                  strokeColor={emotion?.color}
                  trailColor="#F5F5F5"
                  percent={userInfo?.emotionPercentage}
                />
              </Flex>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: emotion?.color,
                }}
              >
                {emotion?.name}
              </p>
            </Flex>
            {userInfo ? (
              <Flex gap={12} vertical>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    {t('app.nome')}:{' '}
                  </span>
                  {userInfo?.name}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    {t('app.idade')}:{' '}
                  </span>
                  {`${userInfo?.age} anos`}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    {t('app.chave')}:{' '}
                  </span>
                  {userInfo?.license}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    {t('app.matriculado')}:{' '}
                  </span>
                  {userInfo.className
                    ? `${userInfo?.className} - ${t(`app.${userInfo?.period}`)}`
                    : '-'}
                </p>
                <p style={{ color: 'black', fontSize: '18px' }}>
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    {t('app.ultimoAcesso')}:{' '}
                  </span>
                  {userInfo.lastSync
                    ? dayjs(userInfo?.lastSync).format('DD/MM/YYYY HH[h]mm')
                    : '-'}
                  <ClockCircleOutlined style={{ marginLeft: '15px' }} />
                </p>
              </Flex>
            ) : (
              <Spin />
            )}
          </Flex>
          <Flex vertical style={{ maxWidth: '284px' }} justify="space-around">
            <Button onClick={() => setIsDrawerOpen(true)} size="lg">
              {t('app.observacao')}
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => setIsOpenModal(true)}
            >
              {t('app.historicoDeAcessos')}
            </Button>
          </Flex>
        </Flex>
      )}

      {isDrawerOpen && (
        <ObservationDrawer onClose={() => setIsDrawerOpen(false)} />
      )}
    </GenericCard>
  );
}

export default StudentInformation;
