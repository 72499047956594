import { Flex, Spin, Tooltip } from 'antd';
import GenericFilterComponent from 'components/generic-filter';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { Graph, GraphCategory } from 'core/features/indicators-profile/typings';
import useIndicatorsData from 'core/features/indicators/hooks/useIndicatorsData';
import { t } from 'core/resources/strings';
import { FilterProps, FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { TfiPencil } from 'react-icons/tfi';
import AreaGraph from './graphs/areaGraph';
import BarGraph from './graphs/barGraph';
import ColumnGraph from './graphs/columnGraph';
import DonutGraph from './graphs/donutGraph';
import LineGraph from './graphs/lineGraph';
import PieGraph from './graphs/pieGraph';
import RadarGraph from './graphs/radarGraph';
import StackedGraph from './graphs/stackedGraph';
import SadIcon from 'components/icons/SadIcon';
import { useParams } from 'react-router';

const graphsTypes = [
  {
    value: 'line-graph',
    label: 'Gráfico de linha',
    time: true,
    graphComponent: LineGraph,
  },
  {
    value: 'area-graph',
    label: 'Gráfico de área',
    time: true,
    graphComponent: AreaGraph,
  },
  {
    value: 'bar-graph',
    label: 'Gráfico de barra',
    comparative: true,
    graphComponent: BarGraph,
  },

  {
    value: 'radar-graph',
    label: 'Gráfico de radar',
    comparative: true,
    emotion: true,
    graphComponent: RadarGraph,
  },
  {
    value: 'pizza-graph',
    label: 'Gráfico de pizza',
    emotion: true,
    graphComponent: PieGraph,
  },
  {
    value: 'donut-graph',
    label: 'Gráfico de donut',
    emotion: true,
    graphComponent: DonutGraph,
  },
  {
    value: 'column-graph',
    label: 'Gráfico de coluna',
    time: true,
    comparative: true,
    graphComponent: ColumnGraph,
  },
  {
    value: 'stacked-graph',
    label: 'Gráfico de coluna empilhada',
    comparative: true,
    graphComponent: StackedGraph,
  },
];

function CustomGraph({
  graph,
  onRemove,
  onEdit,
  onDuplicate,
}: {
  graph: Graph;
  onRemove?: () => void;
  onEdit?: () => void;
  onDuplicate?: () => void;
}) {
  const { tenantId } = useParams<{ tenantId: string }>();

  const graphType = graphsTypes.find((g) => g.value === graph.type);
  const GraphComponent = graphType?.graphComponent;
  const [filters, setFilters] = useState<FilterType>({
    age: [],
  });
  const { data, isLoading } = useIndicatorsData({
    ...graph,
    tenantId,
    minAge: filters.age.find((item) => item.label === 'Idade Mínima')?.value,
    maxAge: filters.age.find((item) => item.label === 'Idade Máxima')?.value,
  });

  const sources = {
    school: t('app.escola'),
    serie: t('app.serie'),
    class: t('app.turma'),
    student: t('app.estudante'),
  };

  const views = {
    time: t('app.temporal'),
    year: t('app.ano'),
    comparative: t('app.comparativo'),
  };
  const analysisTypes = [
    t('app.conhecimento'),
    t('app.comportamento'),
    t('app.percepcao'),
    t('app.todos'),
  ];
  const environments = [
    t('app.Familiar'),
    t('app.School'),
    t('app.Digital'),
    t('app.Social'),
    t('app.Work'),
    t('app.todos'),
  ];

  return (
    <GenericCard height="100%">
      <Flex vertical style={{ width: '100%' }}>
        <Flex vertical style={{ width: '100%' }}>
          <Flex justify="space-between">
            <SubTitle primary>{graph.name}</SubTitle>
            <Flex vertical gap={8} style={{ marginBottom: 16 }}>
              <GraphhFilter filters={filters} onChangeFilters={setFilters} />
              <Flex gap={16} align="center">
                {onEdit && (
                  <Tooltip title={t('app.editar')}>
                    <TfiPencil
                      onClick={onEdit}
                      style={{
                        fontSize: 16,
                        color: 'black',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}

                {onDuplicate && (
                  <Tooltip title={t('app.copiar')}>
                    <IoDuplicateOutline
                      onClick={onDuplicate}
                      style={{
                        fontSize: 16,
                        color: 'black',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}

                {onRemove && (
                  <Tooltip title={t('app.deletar')}>
                    <FiTrash2
                      onClick={onRemove}
                      style={{
                        fontSize: 16,
                        color: 'black',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={4} justify="flex-end">
            <GraphTag text={t(`app.${graph.category}`)} />
            <GraphTag text={sources[graph.source]} />
            {graph.analysisTypeId !== undefined && (
              <GraphTag text={analysisTypes[graph.analysisTypeId]} />
            )}
            {graph.analysisEnvironmentId !== undefined && (
              <GraphTag text={environments[graph.analysisEnvironmentId]} />
            )}
            <GraphTag text={views[graph.graphView]} />
          </Flex>
        </Flex>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <>
            {GraphComponent && (
              <GraphComponent
                categories={data?.data.categories}
                series={data?.data.series}
                colors={data?.data.colors}
              />
            )}
          </>
        )}
      </Flex>
    </GenericCard>
  );
}

export default CustomGraph;

const GraphTag = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        padding: '4px',
        background: '#E9E9E9',
        color: 'black',
        fontSize: '12px',
      }}
    >
      {text}
    </div>
  );
};

function GraphhFilter({ filters, onChangeFilters }: FilterProps) {
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.faixaEtaria'),
          filterName: 'age',
          open: true,
          type: 'range',
          options: [
            { label: t('app.idadeMinima'), value: undefined },
            { label: t('app.idadeMaxima'), value: undefined },
          ],
        },
      ]}
    />
  );
}
