import { useMediaQuery } from '@chakra-ui/react';
import {
  Col,
  Collapse,
  Flex,
  Modal,
  Pagination,
  Row,
  Spin,
  Progress,
  Divider,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import useActivityProgress from 'core/features/activity/hooks/useActivityProgress';
import useActivitySimpleDetails from 'core/features/activity/hooks/useActivitySimpleDetails';
import { currentLocale } from 'core/resources/strings/polyglot';
import dayjs from 'dayjs';
import useStudentsByActivity from 'core/features/students/hooks/useStudentsByActivity';
import { RightOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import Tag from 'components/V2/tag';
import { PiDownloadSimpleLight } from 'react-icons/pi';
import { FilterType } from 'infra/helpers/types';
import StudentFilter from 'ui/pages/app/mySchool/pages/Students/components/StudentFilter';
import AppliedFilters from 'components/applied-filters';
import { useReactToPrint } from 'react-to-print';
import ActivityProgress from '../activityProgress';
import { ActivityTypes } from 'core/resources/enums/activityTypes';
import avaliationImage from 'assets/images/avaliation.png';
import initialAvaliationImage from 'assets/images/initial-avaliation.png';

function ModalActivityProgress({
  onClose,
  activityId,
}: {
  activityId: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filters, setFilters] = useState<FilterType>({
    progress: [],
    gender: [],
  });
  const { data: activityProgress } = useActivityProgress(activityId);
  const { data: activity } = useActivitySimpleDetails(activityId);
  const { data, isLoading } = useStudentsByActivity({
    activityId: activityId,
    page: 1,
    limit: 1000,
    progress: filters?.progress?.map((item: any) => item.value) ?? undefined,
    gender: filters?.gender?.map((item: any) => item.value)?.[0] ?? undefined,
  });

  const studentsByClass = groupBy(data?.data || [], 'class');

  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getProgressStatus = () => {
    if (Number(activityProgress?.progressOverall) === 0) {
      return {
        text: t('app.naoIniciada'),
      };
    }
    if (Number(activityProgress?.progressOverall) >= 80) {
      return {
        text:
          t('app.concluido') +
          ' - ' +
          Number(activityProgress?.progressOverall) +
          '%',
      };
    }
    return {
      text:
        t('app.emAndamento') +
        ' - ' +
        Number(activityProgress?.progressOverall) +
        '%',
    };
  };

  const { text } = getProgressStatus();

  return (
    <Modal
      title={
        <SubTitle primary style={{ maxWidth: isMobile ? '238px' : undefined }}>
          {t('app.progressoPorTurma')}
        </SubTitle>
      }
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '95%' : 1091}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      styles={{
        content: {
          padding: isMobile ? '8px' : '20px 24px',
        },
      }}
    >
      <Flex
        style={{
          boxShadow:
            '0px -0.5px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
          padding: '16px',
          background: colorPrimary,
          borderRadius: '10px',
          position: 'relative',
        }}
        gap={22}
        align="center"
        vertical={isMobile}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${Number(activityProgress?.progressOverall) || 0}%`,
            height: '10px',
            background: 'rgba(255, 255, 255, 0.50)',
            borderRadius: '10px 10px 10px 0px',
            transition: 'width 0.3s ease-in-out',
          }}
        />
        <div
          style={{
            position: 'relative',
            marginBottom: isMobile ? '16px' : undefined,
          }}
        >
          <img
            src={
              activity?.gameImage
                ? activity.gameImage
                : activity?.typeId === ActivityTypes.Avaliation
                ? avaliationImage
                : activity?.typeId === ActivityTypes.SelfAvaliation
                ? initialAvaliationImage
                : undefined
            }
            style={{
              borderRadius: '15px',
              width: isMobile ? '100%' : '140px',
            }}
          />
          {isMobile && (
            <div
              style={{
                borderRadius: '5px',
                background: 'rgba(255, 255, 255, 0.80)',
                padding: '4px',
                position: 'absolute',
                bottom: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                color: colorGreyBorderAndFont,
                fontSize: '12px',
                fontWeight: 700,
              }}
            >
              {text.toUpperCase()}
            </div>
          )}
        </div>
        {isMobile ? (
          <Flex
            justify="space-between"
            align="center"
            style={{ width: '100%' }}
          >
            <Text size="md" color="white">
              <strong>Autor: </strong>
              <br />
              {activity?.responsible}
            </Text>
            <Divider
              type="vertical"
              style={{
                margin: '0px',
                height: '40px',
                backgroundColor: 'white',
              }}
            />
            <Text size="md" color="white">
              <strong>Competência: </strong>
              <br />
              {activity?.competence?.[currentLocale]}
            </Text>
          </Flex>
        ) : (
          <>
            <Text size="md" color="white">
              <strong>Autor: </strong>
              {activity?.responsible}
            </Text>
            <Text size="md" color="white">
              <strong>Competência: </strong>
              {activity?.competence?.[currentLocale]}
            </Text>
          </>
        )}

        {isMobile ? (
          <>
            <Divider
              type="horizontal"
              style={{
                margin: '0px',
                backgroundColor: 'white',
              }}
            />
            <Text size="md" color="white">
              <strong>Restam: </strong>
              {activity?.endDate
                ? `${Math.max(
                    0,
                    dayjs(activity.endDate).diff(dayjs(), 'day')
                  )} dias`
                : '-'}
            </Text>
          </>
        ) : (
          <Text size="md" color="white">
            <strong>Restam: </strong>
            {activity?.endDate
              ? `${Math.max(
                  0,
                  dayjs(activity.endDate).diff(dayjs(), 'day')
                )} dias`
              : '-'}
          </Text>
        )}
        {!isMobile && (
          <Text size="md" color="white">
            <strong>Progresso Geral: </strong>
            {Number(
              (activityProgress?.answeredQuestions! /
                activityProgress?.totalQuestions!) *
                100
            ).toFixed(1)}
            %
          </Text>
        )}
      </Flex>
      <Flex
        gap={16}
        justify="flex-end"
        align="center"
        style={{ marginBottom: '4px', marginTop: '24px' }}
      >
        <StudentFilter filters={filters} onChangeFilters={setFilters} />
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </Flex>
      {isLoading && (
        <Flex justify="center" align="center">
          <Spin size="large" />
        </Flex>
      )}

      <Row gutter={[16, 16]}>
        {Object.entries(studentsByClass).map(([className, students]) => (
          <Col xs={24} md={12} key={className}>
            <Collapse
              style={{ width: '100%' }}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <RightOutlined
                  style={{
                    fontSize: '14px',
                    color: colorGreyBorderAndFont,
                    transform: isActive ? 'rotate(90deg)' : 'none',
                  }}
                />
              )}
            >
              <Collapse.Panel
                style={{
                  background: '#fff',
                  borderRadius: '10px',
                  width: '100%',
                  boxShadow:
                    '0px -0.5px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
                  padding: '8px',
                }}
                header={
                  <Flex align="center" gap={16}>
                    <Progress
                      type="circle"
                      size={60}
                      strokeWidth={10}
                      strokeColor={colorPrimary}
                      status={'normal'}
                      percent={Math.round(
                        students.reduce(
                          (acc, student) =>
                            acc + (Number(student.progress) || 0),
                          0
                        ) / students.length
                      )}
                    />
                    <Flex
                      justify="space-between"
                      align="center"
                      style={{ width: '100%' }}
                      vertical={isMobile}
                    >
                      <Text size="lg" color="black">
                        {className}
                      </Text>
                      <Text size="sm" color={colorGreyBorderAndFont}>
                        {t('app.Estudantes')}
                      </Text>
                    </Flex>
                  </Flex>
                }
                key={className}
              >
                <Flex justify="space-between" align="center">
                  {!isMobile && (
                    <PiDownloadSimpleLight
                      size={20}
                      color={colorPrimary}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '18px',
                        marginTop: '8px',
                      }}
                      onClick={() => handlePrint()}
                    />
                  )}

                  <div style={{ display: 'none' }}>
                    <ActivityProgress
                      ref={componentRef}
                      progress={Math.round(
                        students.reduce(
                          (acc, student) =>
                            acc + (Number(student.progress) || 0),
                          0
                        ) / students.length
                      )}
                      className={className}
                      students={students}
                      activityName={
                        activity?.typeId === ActivityTypes.Avaliation
                          ? t('app.avaliacao')
                          : activity?.typeId === ActivityTypes.SelfAvaliation
                          ? t('app.avaliacaoInicial')
                          : t('app.atividade')
                      }
                    />
                  </div>
                  <Flex
                    justify="flex-end"
                    align="center"
                    gap={16}
                    style={{
                      marginBottom: '18px',
                      marginTop: '8px',
                    }}
                  >
                    <Tag text={`${students.length} ${t('app.Estudantes')}`} />
                    <Tag
                      text={`${
                        students.filter((student) => student.gender === 'F')
                          .length
                      } ${t('app.meninas')}`}
                    />
                    <Tag
                      text={`${
                        students.filter((student) => student.gender === 'M')
                          .length
                      } ${t('app.meninos')}`}
                    />
                  </Flex>
                </Flex>

                <div
                  style={{
                    maxHeight: isMobile ? '' : '300px',
                    overflowY: 'auto',
                    paddingRight: '8px',
                  }}
                >
                  {students.map((student) => (
                    <Flex
                      key={student.id}
                      justify="space-between"
                      align="center"
                      style={{ marginBottom: 12 }}
                    >
                      <Text size="sm">{student.name}</Text>
                      <Progress
                        percent={Math.round(Number(student.progress) || 0)}
                        percentPosition={{ align: 'center', type: 'inner' }}
                        size={[isMobile ? 106 : 260, 14]}
                        strokeColor={colorPrimary}
                        style={{ width: 'fit-content' }}
                      />
                    </Flex>
                  ))}
                </div>
                {isMobile && (
                  <Flex justify="center ">
                    <PiDownloadSimpleLight
                      size={24}
                      color={colorPrimary}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '18px',
                        marginTop: '8px',
                      }}
                      onClick={() => handlePrint()}
                    />
                  </Flex>
                )}
              </Collapse.Panel>
            </Collapse>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default ModalActivityProgress;
