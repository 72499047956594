import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useCurrentUser } from 'core/features/user/store';
import { useHistory, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import InstitutionHeader from 'components/institution-header';
import HeaderImage from 'assets/images/classes-header-image.png';
import HeaderImageWl from 'assets/images/white-label/students.png';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import { t } from 'core/resources/strings';
import { useState } from 'react';
import B2cGroupsTable from 'ui/pages/app/mySchool/pages/B2cGroups/components/B2cGroupsTable';
import B2cGroupModal from 'ui/pages/app/mySchool/pages/B2cGroups/components/B2cGroupModal';

function B2cGroups() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  if (
    !check([
      Permissions.Students.View,
      Permissions.U4hero.Tenant.Edit,
      Permissions.Org.Tenant.Edit,
    ])
  ) {
    history.push('/errors/no-permission');
  }

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState<string>();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant } = useTenant(tenantId);
  const { user } = useCurrentUser();

  return (
    <Layout.Container>
      {isOpenModal ? (
        <B2cGroupModal
          onClose={() => setIsOpenModal(false)}
          id={id}
          tenantId={tenantId}
        />
      ) : null}

      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
          onClick={() => {
            if (tenant) {
              history.push(`/schools?tab=${tenant?.master ? 1 : 2}`);
            } else {
              history.push('/my-school');
            }
          }}
        />
        <Title primary id="groups-title">
          {t('app.grupos')}
        </Title>
      </Flex>

      {tenant ? (
        <InstitutionHeader
          image={user?.whiteLabel ? HeaderImageWl : HeaderImage}
          tenantName={tenant?.name}
        />
      ) : null}

      <B2cGroupsTable
        onEdit={(group) => {
          setId(group.id);
          setIsOpenModal(true);
        }}
      />
    </Layout.Container>
  );
}

export default B2cGroups;
