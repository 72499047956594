import { useQuery } from 'react-query';
import axios from 'infra/http';
import { currentLocale } from 'core/resources/strings/polyglot';

function useEmotionometerIntensityGraph(params?: { tenantId?: string }) {
  const fetchData = async (): Promise<{
    categories: string[];
    data: number[];
  }> => {
    const { data } = await axios.get<{ categories: string[]; data: number[] }>(
      `/v2/indicators/emotionometer-intensity`,
      {
        params: { ...params, locale: currentLocale },
      }
    );

    return data;
  };

  return useQuery([`/v2/indicators/emotionometer-intensity`], () =>
    fetchData()
  );
}

export default useEmotionometerIntensityGraph;
