import { TagType } from 'antd';
import http from 'infra/http';
import { useQuery } from 'react-query';
import { GraphTagHistory } from '../typings';

function useGraphTagAvg(
  tags: string[],
  tenantId: string,
  startDate: string,
  endDate: string,
  analysisType: 'Compare' | 'Temporal' | 'General',
  weekly: boolean,
  extras?: {
    serieId?: string | null;
    classId?: string | null;
    userId?: string | null;
    interval?: number | null;
    analysisTypeId?: number | null;
  }
) {
  return useQuery(
    [
      `v2/graphs/history?tags`,
      [extras, tags, startDate, endDate, analysisType, weekly],
    ],
    async () => {
      const { data } = await http.get<GraphTagHistory[]>(
        `v2/graphs/history`,
        {
          params: {
            tags,
            startDate,
            endDate,
            tenantId,
            weekly,
            ...extras,
          },
        }
      );

      // let newData = [...data];
      // for (let i = 0; i < data.length; i++) {
      //   newData.push(generateMovingAvg(data[i], extras?.interval ?? 2));
      // }
      if (analysisType === 'Compare') {
        let compareData = [];
        for (let i = 0; i < data.length; i++) {
          compareData.push(generate(data[i], extras?.interval ?? 2));
        }
        // if(filtersIndicators){
        //   return compareData.filter((item) => filtersIndicators.includes(item.name))
        // }
        return compareData;
      }
      if (analysisType === 'General') {
        let compareData = [];
        for (let i = 0; i < data.length; i++) {
          compareData.push(generate(data[i], 12));
        }
        return compareData;
      }
      // if(filtersIndicators){
      //   return data.filter((item) => filtersIndicators.includes(item.name));
      // }
      return data;
    },
    {
      enabled: tags.length > 0,
    }
  );
}

export default useGraphTagAvg;

const generateMovingAvg = (data: any, interval: number) => {
  const calcPercent = (items: any[], month: number, interval: number) => {
    let sum = 0;
    for (let i = month; i > month - interval; i--) {
      sum += items[i]?.percent ? items[i]?.percent : 0;
    }
    return sum / interval;
  };
  const avg: any = {};
  avg.name = `Média ${data.name}`;
  avg.items = [];
  avg.hidden = true;
  for (let i = 0; i < data.items.length; i++) {
    let item = {
      total: data.items[i].total,
      value: data.items[i].value,
      percent: calcPercent(data.items, i, interval),
      mes: `${i + 1 < 10 ? '0' + (i + 1).toString() : (i + 1).toString()}`,
      color: data.items[i].color,
    };
    avg.items.push(item);

  }

  return avg;
};

const generate = (data: any, interval: number) => {
  const calcPercent = (items: any[], group: number, interval: number) => {
    let arr;
    if (group === 0) {
      arr = items.slice(group, interval);
    } else {
      arr = items.slice(group * interval, group * interval + interval);
    }
    let sum = arr.reduce((acc, curr) => acc + curr.value, 0);
    let total = arr.reduce((acc, curr) => acc + curr.total, 0);
    const percent = total > 0 ? sum / total : 0;
    if (percent === 0) {
      return null;
    }
    return percent;
  };
  const avg: any = {};
  avg.name = `${data.name}`;
  avg.description = data.description;
  avg.items = [];
  for (let i = 0; i < data.items.length / interval; i++) {
    let item = {
      percent: calcPercent(data.items, i, interval),
      color: data.items[i].color,
    };
    avg.items.push(item);

  }
  return avg;
};
