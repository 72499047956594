import ClassRoomIcon from 'assets/images/icons/ClassRoomIcon';
import useTotalClasses from 'core/features/indicators/hooks/useTotalClasses';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router-dom';
import StatCard from '../statCard';

function TotalClasses({ onRemove }: { onRemove?: () => void }) {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data } = useTotalClasses({ tenantId });
  return (
    <StatCard
      title={t('app.turma')}
      total={data?.total ?? 0}
      Icon={ClassRoomIcon}
      onRemove={onRemove}
    />
  );
}

export default TotalClasses;
