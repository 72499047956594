import { Col, Flex, Form, Input, Row } from 'antd';
import Text from 'components/typography/text';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import UploadWithPreview from 'components/modals/modal-interaction/components/uploadImageWithPreview';
import SelectMusic from 'ui/pages/app/posts-hub/events/components/SelectMusic';
import { useMediaQuery } from '@chakra-ui/react';

function EventDetails() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex vertical gap={24}>
      <GenericCard>
        <Flex vertical style={{ width: '100%' }}>
          <SubTitle primary>Informações Básicas</SubTitle>
          <Row
            style={{
              width: '100%',
            }}
            gutter={[
              { xs: 0, md: 48 },
              { xs: 0, md: 0 },
            ]}
          >
            <Col xs={24} md={16}>
              <Form.Item
                label="Nome do Evento"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, crie um título para o evento!',
                  },
                ]}
                name="name"
              >
                <Input
                  id="id-tour-create-event-input-name"
                  placeholder="Crie um nome para esse evento"
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Form.Item
                label="Data de Início"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, defina uma data',
                  },
                ]}
              >
                <Input
                  id="id-tour-create-event-input-start-date"
                  type="date"
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Form.Item
                label="Data de Término"
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, defina uma data',
                  },
                ]}
              >
                <Input
                  id="id-tour-create-event-input-end-date"
                  type="date"
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item
                label="Descrição"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, crie uma descrição',
                  },
                ]}
                name="description"
              >
                <Input.TextArea
                  id="id-tour-create-event-input-description"
                  placeholder="Crie uma breve descrição do evento"
                  size={isMobile ? 'middle' : 'large'}
                  rows={3}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex>
      </GenericCard>

      <GenericCard>
        <Flex vertical style={{ width: '100%' }}>
          <SubTitle primary>Aparência</SubTitle>
          <Row
            style={{
              width: '100%',
            }}
            gutter={[
              { xs: 0, md: 48 },
              { xs: 0, md: 0 },
            ]}
          >
            <Col xs={24} md={12}>
              <Form.Item
                name={'splashArt'}
                label={'Splash Art'}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, carregue uma imagem',
                  },
                ]}
              >
                {/* @ts-ignore */}
                <UploadWithPreview folderKey="u4heroEvents" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'billboard'}
                label={'Letreiro'}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, carregue uma imagem',
                  },
                ]}
              >
                {/* @ts-ignore */}
                <UploadWithPreview folderKey="u4heroEvents" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Text size="md" style={{ marginBottom: '8px' }}>
                Imagens Adicionais:
              </Text>
              <Row
                gutter={[
                  { xs: 0, md: 48 },
                  { xs: 0, md: 0 },
                ]}
              >
                <Col xs={24} md={8}>
                  <Form.Item name={'additionalImage1'} label={''}>
                    {/* @ts-ignore */}
                    <UploadWithPreview folderKey="u4heroEvents" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item name={'additionalImage2'} label={''}>
                    {/* @ts-ignore */}
                    <UploadWithPreview folderKey="u4heroEvents" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item name={'additionalImage3'} label={''}>
                    {/* @ts-ignore */}
                    <UploadWithPreview folderKey="u4heroEvents" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Flex>
      </GenericCard>

      <GenericCard style={{ marginBottom: '16px' }}>
        <Flex vertical style={{ width: '100%' }}>
          <SubTitle primary>Música Tema</SubTitle>
          <Flex align="center" gap="16px">
            <Text size="md">Carregue o som do evento:</Text>
            <Form.Item
              name={'music'}
              // label={'Carregue o som do evento'}
              layout="horizontal"
              style={{
                margin: '0px',
              }}
            >
              {/* @ts-ignore */}
              <SelectMusic />
            </Form.Item>
          </Flex>
        </Flex>
      </GenericCard>
    </Flex>
  );
}

export default EventDetails;
