import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseActivitiesParams {
  page: number;
  pageSize?: number;
  classId?: string;
  disableConcat?: boolean;
  initialDate?: string;
  endDate?: string;
  userId?: string;
  tenantId?: string;
  optionIds?: number[];
  intensity?: number;
}
export interface Emotional {
  class: {
    id: string;
    name: string;
    serie: {
      id: string;
      name: string;
    };
  };
  user: {
    id: string;
    name: string;
    imageUrl: string;
    avatar: {
      id: string;
      background: {
        id: string;
        imageUrl: string;
      };
      frame: {
        id: string;
        imageUrl: string;
      };
      icon: {
        id: string;
        imageUrl: string;
      };
    };
  };
  optionId: number;
  name: string;
  createdAt: string;
  intensity: number;
  image: string;
}
function useEmotions(params: UseActivitiesParams) {
  const fetchActivities = async (
    params: UseActivitiesParams
  ): Promise<{ data: Emotional[]; total: number }> => {
    const { data, headers } = await axios.get<Emotional[]>(
      `/v2/graphs/emotional/list`,
      {
        params: {
          page: params.page,
          pageSize: params.pageSize,
          classId: params.classId,
          initialDate: params.initialDate,
          endDate: params.endDate,
          userId: params.userId,
          optionIds: params.optionIds,
          tenantId: params.tenantId,
          intensity: params.intensity,
        },
      }
    );

    let returnData: Emotional[] = [];

    returnData = [...data];

    return { data: returnData, total: Number(headers['total']) };
  };

  return useQuery([`/v2/graphs/emotional/list`, params], () =>
    fetchActivities(params)
  );
}

export default useEmotions;
