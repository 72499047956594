import { useMediaQuery } from '@chakra-ui/media-query';
import { Button, Flex, Modal, Spin } from 'antd';
import dayjs from 'dayjs';
import useGenerateReport from 'core/features/report/hooks/useGenerateReport';
import { useCurrentUser } from 'core/features/user/store';
import { ActivityTypes } from 'core/resources/enums/activityTypes';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { forwardRef, useRef } from 'react';
import { TfiPrinter } from 'react-icons/tfi';
import { useReactToPrint } from 'react-to-print';
import ReportDataAnalysis from 'ui/pages/app/indicators/components/new-report/components/data-analysis';
import ReportDataInfo from 'ui/pages/app/indicators/components/new-report/components/data-info';
import ReportGuide from 'ui/pages/app/indicators/components/new-report/components/guide';
import ReportIntroduction from 'ui/pages/app/indicators/components/new-report/components/introduction';
import ReportReferences from 'ui/pages/app/indicators/components/new-report/components/references';
import ReportSummary from 'ui/pages/app/indicators/components/new-report/components/summary';
import ReportCover from './components/cover';
import { Report as ReportInterface } from 'core/features/report/types';
import { t } from 'core/resources/strings';
import { emotions } from 'infra/helpers/types';
import { useParams } from 'react-router';

export default function Report({
  activityId,
  userId,
  classId,
  tenantId,
  modelType,
  onClose,
  studentName,
  className,
  classPeriod,
  studentsCount,
}: {
  activityId?: string;
  userId?: string;
  classId?: string;
  tenantId?: string;
  onClose: () => void;
  modelType: 'simplified' | 'complete';
  studentName?: string;
  className?: string;
  classPeriod?: string;
  studentsCount?: number;
}) {
  const componentRef = useRef<any>();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Relatório U4Hero',
  });
  const { user } = useCurrentUser();
  const { data, isLoading } = useGenerateReport({
    activityId,
    userId,
    classId,
    tenantId,
  });

  const schoolName = user?.tenantName;
  return (
    <Modal width="900px" footer={null} onCancel={onClose} visible title={' '}>
      <Flex justify="flex-end" style={{ marginRight: 30 }}>
        <Button
          onClick={handlePrint}
          style={{ border: `solid 1px ${colorPrimary}` }}
          size={isMobile ? 'small' : 'middle'}
        >
          <TfiPrinter size={isMobile ? 14 : 20} color={colorPrimary} />
        </Button>
      </Flex>
      {isLoading && (
        <Flex justify="center" align="center">
          <Spin size="large" />
        </Flex>
      )}
      {!isLoading && (
        <DocumentPdf
          ref={componentRef}
          schoolName={schoolName!}
          report={data!}
          modelType={modelType}
          className={className}
          studentName={studentName}
          classPeriod={classPeriod}
          studentsCount={studentsCount}
        />
      )}
    </Modal>
  );
}

interface Props {
  schoolName: string;
  report: ReportInterface;
  modelType: 'simplified' | 'complete';
  className?: string;
  studentName?: string;
  classPeriod?: string;
  studentsCount?: number;
}
export const DocumentPdf = forwardRef(
  (
    {
      schoolName,
      report,
      modelType,
      className,
      studentName,
      classPeriod,
      studentsCount,
    }: Props,
    ref: any
  ) => {
    return (
      <div id="report" ref={ref}>
        <ReportCover
          modelType={modelType}
          reportType={
            report.activity.typeId === ActivityTypes.Avaliation ||
            report.activity.typeId === ActivityTypes.IndividualAvaliation
              ? 'avaliation'
              : report.activity.typeId === ActivityTypes.SelfAvaliation
              ? 'initialAvaliation'
              : 'activity'
          }
          year={dayjs(report?.activity?.publishDate).year()}
          className={className}
          studentName={studentName}
          period={classPeriod}
        />
        {modelType === 'complete' && (
          <>
            <ReportSummary />
            <ReportIntroduction
              schoolName={schoolName}
              typeId={report.activity.typeId}
              analysisType={0}
              competenceName={report.activity?.tags?.[0]?.parent?.name['pt-br']}
              skillName={report.activity?.tags?.[0]?.name['pt-br']}
            />
            <ReportGuide />
            <ReportDataInfo />
          </>
        )}

        <ReportDataAnalysis
          {...report.activity}
          countStudents={studentsCount ?? report.activity.countStudents}
          countClasses={studentsCount ? 1 : report.activity.countClasses}
          countAnswers={Object.values(report.results).reduce(
            (acc: number, curr: any) =>
              acc +
              curr.reduce((acc: number, curr: any) => acc + curr.totalCount, 0),
            0
          )}
          countFlag1={
            report.countAvaliations.classifications.find(
              (item: any) => item.safeSpaceClassification === '1'
            )?.count ?? 0
          }
          countFlag2={
            report.countAvaliations.classifications.find(
              (item: any) => item.safeSpaceClassification === '2'
            )?.count ?? 0
          }
          countFlag3={
            report.countAvaliations.classifications.find(
              (item: any) => item.safeSpaceClassification === '3'
            )?.count ?? 0
          }
          countFlag4={
            report.countAvaliations.classifications.find(
              (item: any) => item.safeSpaceClassification === '4'
            )?.count ?? 0
          }
          predominantEmotion={report.predominantEmotion}
          results={report.results}
          name={studentName ? studentName : className ? className : schoolName}
          modelType={modelType}
          startPage={modelType === 'simplified' ? 1 : 15}
          isStudent={!!studentName}
        />

        {modelType === 'complete' && (
          <ReportReferences
            startPage={
              23 -
              Math.round(
                Object.values(report.results).filter(
                  (result) => result.length === 0
                ).length * 1.5
              )
            }
          />
        )}
      </div>
    );
  }
);
