import http from "infra/http";
import { useMutation } from "react-query";
import { EventForm } from "../typings";



export function useSaveEvent() {
  return useMutation(
    (data: EventForm) => {
      if (data.id) {
        return http.put(`/v2/events/`, data);
      }
      return http.post(`/v2/events`, data);

    },
    { retry: false }
  );
}
