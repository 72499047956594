import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';

function EventsFilter({ filters, onChangeFilters }: FilterProps) {
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Status',
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 2 },
          ],
          filterName: 'status',
          type: 'radio',
        },
      ]}
    />
  );
}

export default EventsFilter;
