import { useQuery } from "react-query";
import axios from "infra/http";
import { ClassDetails } from "core/features/classes/types";

interface B2cGroup {
  id: string;
  name: string;
  participants: string[];
  imageUrl: string;
  active: boolean;
  createdAt: string;
}

function useB2cGroups({
  page,
  limit,
  search,
  tenantId,
}: {
  page: number;
  limit: number;
  search: string;
  tenantId?: string;
}) {
  const fetchData = async (): Promise<{ data: B2cGroup[]; total: number }> => {
    const { data, headers } = await axios.get<B2cGroup[]>(`/v2/b2c/groups`, {
      params: {
        page,
        limit,
        search,
        tenantId,
      },
    });
    return { data: data, total: Number(headers['total']) };
  };

  return useQuery([`/v2/b2c/groups`], () => fetchData());
}

export default useB2cGroups;
