import { Modal, Flex, Row, Col } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { IoClose } from 'react-icons/io5';
import useActivityProgress from 'core/features/activity/hooks/useActivityProgress';
import {
  PiCalendarCheckLight,
  PiCalendarXLight,
  PiPuzzlePieceLight,
  PiUserLight,
} from 'react-icons/pi';
import moment from 'moment';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import InfoCard from './components/InfoCard';
import ActivityStudentsTable from './components/ActivityStudentsTable';
import CustomCarousel from 'components/custom-carousel';
import useActivitySimpleDetails from 'core/features/activity/hooks/useActivitySimpleDetails';
import { calcDiffDays } from 'infra/helpers/calcDiffDays';
import Text from 'components/typography/text';
import disk from 'assets/images/flying-disk.png';
import { currentLocale } from 'core/resources/strings/polyglot';

function ModalProgress({
  onClose,
  activityId,
}: {
  activityId: string;
  onClose: () => void;
}) {
  const { data: activityProgress } = useActivityProgress(activityId);
  const { data: activity } = useActivitySimpleDetails(activityId);
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const daysLeft = calcDiffDays(activity?.endDate as string);

  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        dataLabels: {
          show: true,
          value: {
            show: true,
            fontSize: '2vh',
            fontWeight: 800,
            formatter: function (val: any) {
              return Math.trunc(val) + '%';
            },
          },
        },
      },
    },
    labels: [''],
    colors: [colorPrimary],
  };
  let items = [
    {
      content: (
        <InfoCard
          title="Responsável"
          text={activity?.responsible}
          icon={<PiUserLight />}
          type="primary"
        />
      ),
      avaliation: true,
    },
    {
      content: (
        <InfoCard
          title="Competência"
          text={activity?.competence?.[currentLocale]}
          iconUrl={activity?.competenceIcon}
          type="secondary"
        />
      ),
      avaliation: false,
    },
    {
      content: (
        <InfoCard
          title="Habilidade"
          text={activity?.skill?.[currentLocale]}
          iconUrl={activity?.skillIcon}
          type="primary"
        />
      ),
      avaliation: false,
    },
    {
      content: (
        <InfoCard
          title="Jogo"
          text={activity?.game?.[currentLocale]}
          icon={<PiPuzzlePieceLight />}
          type="secondary"
        />
      ),
      avaliation: false,
    },

    {
      content: (
        <InfoCard
          title="Data de Inicio"
          text={moment.utc(activity?.publishDate).format('DD/MM/YYYY')}
          icon={<PiCalendarCheckLight />}
          type={
            activity?.typeId === 1 || activity?.typeId === 2
              ? 'secondary'
              : 'primary'
          }
        />
      ),
      avaliation: true,
    },
    {
      content: (
        <InfoCard
          title="Data de Término"
          text={moment.utc(activity?.endDate).format('DD/MM/YYYY')}
          icon={<PiCalendarXLight />}
          type={
            activity?.typeId === 1 || activity?.typeId === 2
              ? 'primary'
              : 'secondary'
          }
        />
      ),
      avaliation: true,
    },
  ];
  if (
    activity?.typeId === 1 ||
    activity?.typeId === 2 ||
    activity?.typeId === 4
  ) {
    items = items.filter((item) => item.avaliation);
  }

  return (
    <Modal
      title={
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          Progresso da Atividade
        </p>
      }
      width={isMobile ? undefined : 600}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <Flex vertical gap={isMobile ? '16px' : '24px'}>
        {!isMobile ? (
          <Row gutter={[16, 16]} id="id-tour-modal-progress-cards">
            {items.map((item, key) => (
              <Col span={8} key={key}>
                {item.content}
              </Col>
            ))}
          </Row>
        ) : (
          <CustomCarousel
            slidesToShow={2}
            slidesToScroll={2}
            arrows
            id="id-tour-modal-progress-cards"
          >
            {items.map((item) => item.content)}
          </CustomCarousel>
        )}

        <Row
          align="stretch"
          style={{ padding: '12px' }}
          gutter={[isMobile ? 0 : 16, isMobile ? 16 : 0]}
          id="id-tour-modal-progress-big-cards"
        >
          <Col span={isMobile ? 24 : 12}>
            <GenericCard padding="8px">
              <Flex
                vertical
                justify="space-between"
                align="center"
                style={{ width: '100%' }}
              >
                <p
                  style={{
                    fontSize: isMobile ? '16px' : '22px',
                    color: colorPrimary,
                    fontWeight: 700,
                    alignSelf: 'self-start',
                  }}
                >
                  Progresso
                </p>
                {activityProgress &&
                activityProgress?.answeredQuestions >
                  activityProgress?.totalQuestions ? (
                  <Flex
                    vertical
                    justify="center"
                    align="center"
                    gap={16}
                    style={{ width: '100%' }}
                  >
                    <img src={disk} width="88px" />
                    <Text size="md">Não há informações</Text>
                  </Flex>
                ) : (
                  <>
                    <ReactApexChart
                      width="100%"
                      height="290px"
                      options={options}
                      series={[
                        isNaN(Number(activityProgress?.progressOverall))
                          ? 0
                          : Number(activityProgress?.progressOverall),
                      ]}
                      type="radialBar"
                    />

                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '18px',
                        fontWeight: 400,
                      }}
                    >
                      Respostas: {activityProgress?.answeredQuestions}/
                      {activityProgress?.totalQuestions}
                    </p>
                  </>
                )}
              </Flex>
            </GenericCard>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <GenericCard padding="8px" height="100%">
              <Flex
                vertical
                align="center"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <p
                  style={{
                    fontSize: isMobile ? '16px' : '22px',
                    color: colorPrimary,
                    fontWeight: 700,
                    alignSelf: 'self-start',
                  }}
                >
                  Tempo Restante
                </p>
                {daysLeft > 0 ? (
                  <PiCalendarCheckLight size={75} />
                ) : (
                  <PiCalendarXLight size={75} />
                )}

                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '18px',
                    fontWeight: 400,
                  }}
                >
                  Restam <strong>{daysLeft > 0 ? daysLeft : 0}</strong> dias
                </p>
              </Flex>
            </GenericCard>
          </Col>
        </Row>
        <ActivityStudentsTable activityId={activityId} />
      </Flex>
    </Modal>
  );
}

export default ModalProgress;
