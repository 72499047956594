import {
  Flex,
  Form,
  Radio,
  Collapse,
  SliderSingleProps,
  Input,
  Slider,
  Row,
  Col,
  Switch,
} from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import '../style.css';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import useAnalysisTypes from 'core/features/questions/hooks/useAnalysisTypes';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { FiTrash2 } from 'react-icons/fi';
import Button from 'components/buttons/button';
import { PlusOutlined } from '@ant-design/icons';
import useChatGPT from 'core/features/chatgpt/hooks/useChatGpt';
import { useState } from 'react';
import ModalAges, { series } from '../ModalAges';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import { TfiInfo } from 'react-icons/tfi';
import { t } from 'core/resources/strings';
import UploadImage from 'components/inputs/upload-image';
import { currentLocale } from 'core/resources/strings/polyglot';
import UploadWithPreview from 'components/modals/modal-interaction/components/uploadImageWithPreview';

const { TextArea } = Input;

function QuestionDetails() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const allAnalysisTypes = useAnalysisTypes();
  const environments = useEnvironments();
  const form = Form.useFormInstance();
  const methodology = Form.useWatch('methodology', form);
  const questionText = Form.useWatch('text', form);
  const competence = Form.useWatch('competence', form);
  const skill = Form.useWatch('skill', form);
  const options = Form.useWatch('options', form);
  const ages = Form.useWatch('ageRange', form);
  const analysisType = Form.useWatch('analysisTypeId', form);
  const optionsWithImage = Form.useWatch('optionsWithImage', form);

  const [isOpenAgesModal, setIsOpenAgesModal] = useState(false);

  const { mutate, isLoading: isLoadingAi } = useChatGPT();

  const ordinal = [
    t('app.primeira'),
    t('app.segunda'),
    t('app.terceira'),
    t('app.quarta'),
    t('app.quinta'),
  ];

  const marks: SliderSingleProps['marks'] = {
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
    16: '16',
    17: '17',
    18: '18',
  };

  const comprehentionMarks: SliderSingleProps['marks'] = {
    0: {
      label: t('app.muitoBaixa'),
      style: {
        transform: 'translate(0px)',
      },
    },
    25: t('app.baixa'),
    50: t('app.moderado'),
    75: t('app.boa'),
    100: t('app.muitoBoa'),
  };

  const addLikertOptions = (likertType: 'positive' | 'negative') => {
    const likertOptions = [
      {
        id: undefined,
        text: t('app.discordoCompletamente'),
        orientation: undefined,
        hypothesis: undefined,
        value: likertType === 'positive' ? 0 : 100,
        audio: {
          'pt-br':
            'https://s3.us-east-1.amazonaws.com/u4hero-audios-polly/localization/audios-polly/pt-BR/705bab35-7d18-4b42-9f02-160c6bfb6849.eeddd0c3-a2d0-42b6-bdba-ab9beb3f428a.mp3',
          en: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/I+completely+disagree.mp3',
          es: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Completamente+en+desacuerdo.mp3',
        },
      },
      {
        id: undefined,
        text: t('app.discordo'),
        orientation: undefined,
        hypothesis: undefined,
        value: likertType === 'positive' ? 25 : 75,
        audio: {
          'pt-br':
            'https://s3.us-east-1.amazonaws.com/u4hero-audios-polly/localization/audios-polly/pt-BR/9e3c5851-9c3a-472d-b6f1-256e9e47682b.745a3d93-34cd-4c79-a918-3ebd7ba2d719.mp3',
          en: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/I+disagree.mp3',
          es: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/no+estoy+de+acuerdo.mp3',
        },
      },
      {
        id: undefined,
        text: t('app.neutro'),
        orientation: undefined,
        hypothesis: undefined,
        value: likertType === 'positive' ? 50 : 50,
        audio: {
          'pt-br':
            'https://s3.us-east-1.amazonaws.com/u4hero-audios-polly/localization/audios-polly/pt-BR/713690ea-e25a-401f-9ea3-66274618abfa.987dc08f-8a92-4880-be4e-cbb3ec7674eb.mp3',
          en: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/neutral.mp3',
          es: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/neutral-es.mp3',
        },
      },
      {
        id: undefined,
        text: t('app.concordo'),
        orientation: undefined,
        hypothesis: undefined,
        value: likertType === 'positive' ? 75 : 25,
        audio: {
          'pt-br':
            'https://s3.us-east-1.amazonaws.com/u4hero-audios-polly/localization/audios-polly/pt-BR/950e7e06-b847-4e68-8dba-c7b7152182e0.df17c1e7-d4c4-499a-9bf2-28cccd77035b.mp3',
          en: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/I+agree.mp3',
          es: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Estoy+de+acuerdo.mp3',
        },
      },
      {
        id: undefined,
        text: t('app.concordoCompletamente'),
        orientation: undefined,
        hypothesis: undefined,
        value: likertType === 'positive' ? 100 : 0,
        audio: {
          'pt-br':
            'https://s3.us-east-1.amazonaws.com/u4hero-audios-polly/localization/audios-polly/pt-BR/2984fc19-6778-4be4-9386-286a6143b868.3789a850-a55a-4936-91d4-8fd6227efed7.mp3',
          en: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/I+completely+agree.mp3',
          es: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Estoy+completamente+de+acuerdo.mp3',
        },
      },
    ];
    form.setFieldValue(
      'options',
      likertOptions.map((option) => ({
        ...option,
        audio: option.audio?.[currentLocale as keyof typeof option.audio],
      }))
    );
  };

  const handleAgeChange = (values: number[]) => {
    form.setFieldsValue({
      minAge: values[0],
      maxAge: values[1],
    });
  };

  const generateWithAi = (values: any) => {
    let prompt;
    if (values.isHypothesis) {
      prompt = `Gere cinco hipóteses sobre o comportamento de um aluno de faixa etária de "${values.minAge}" a "${values.maxAge}" anos que para a pergunta "${values.question}" respondeu "${values.answer}" não inclua hipóteses relacionadas a transtornos ou traumas.`;
    } else {
      prompt = `Conceitue em linguagem formal pedagógica em até 4 linhas o que é a "${values.skill}" no contexto de "${values.competence}", descreva como ela impacta na aprendizagem e nas relações entre os estudantes dentro e fora do ambiente escolar.

Gere três dinâmicas para turmas a partir de 10 alunos de faixa etária "${values.minAge}" a "${values.maxAge}" anos baseadas na resposta "${values.answer}" da pergunta "${values.question}". escreva o objetivo de cada uma delas, o passo a passo das dinâmicas e uma consideração final para cada dinâmica. Não aborde temas relacionados a transtornos ou traumas. Siga o exemplo de estrutura abaixo:

"conceito de 4 linhas"

Dinâmica 1: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:

Dinâmica 2: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:

Dinâmica 3: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:`;
    }

    mutate(
      { prompt },
      {
        onSuccess: (v: any) => {
          if (values.isHypothesis) {
            form.setFields([
              {
                name: ['options', values.index, 'hypothesis'],
                value: v.data,
                touched: true,
              },
            ]);
          } else {
            form.setFields([
              {
                name: ['options', values.index, 'orientation'],
                value: v.data,
                touched: true,
              },
            ]);
          }
        },
        onError: (err: any) => {
          notificateError(err?.response?.data?.details[0]?.message);
        },
      }
    );
  };

  return (
    <GenericCard>
      {isOpenAgesModal ? (
        <ModalAges onClose={() => setIsOpenAgesModal(false)} />
      ) : null}
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              {t('app.detalhesDaPergunta')}
            </Text>
          }
        >
          <Flex gap={14} vertical style={{ width: '100%' }}>
            <div style={{ marginTop: '8px' }}>
              <Form.Item
                label={t('app.tipoAnalise')}
                rules={[
                  {
                    required: true,
                    message: t('app.mensagemErroTipoAnalise'),
                  },
                ]}
                name="analysisTypeId"
              >
                <Radio.Group id="id-tour-question-creation-input-analysisType">
                  <Flex
                    wrap={!isMobile ? 'wrap' : 'nowrap'}
                    vertical={isMobile}
                  >
                    {allAnalysisTypes?.data?.map((type) => (
                      <Radio
                        value={type.id}
                        key={type.id}
                        disabled={methodology === 1 && type.id === 0}
                      >
                        {t(`app.${type.name}`)}
                      </Radio>
                    ))}
                  </Flex>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('app.mensagemErroAmbienteAnalise'),
                  },
                ]}
                label={t('app.ambienteAnalise')}
                name="analysisEnvironmentId"
              >
                <Radio.Group id="id-tour-question-creation-input-analysisEnvironment">
                  <Flex
                    wrap={!isMobile ? 'wrap' : 'nowrap'}
                    vertical={isMobile}
                  >
                    {environments?.data?.map((env) => (
                      <Radio value={env.id} key={env.id}>
                        {t(`app.${env.name}`)}
                      </Radio>
                    ))}
                  </Flex>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="minAge" hidden />
              <Form.Item name="maxAge" hidden />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('app.mensagemErroFaixaEtaria'),
                  },
                ]}
                label={t('app.faixaEtaria')}
                name={'ageRange'}
              >
                <Slider
                  id="id-tour-question-creation-input-ageRange"
                  min={5}
                  max={18}
                  range
                  marks={isMobile ? { 5: '5', 12: '12', 18: '18' } : marks}
                  onChange={handleAgeChange}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Flex
                vertical={isMobile}
                justify="space-between"
                style={{ marginTop: '-14px', marginBottom: '14px' }}
              >
                <div
                  style={{
                    background: hex2rgba(colorPrimary, 0.2),
                    padding: '2px 8px',
                    borderRadius: '5px',
                    width: 'fit-content',
                  }}
                >
                  {t('app.aplicavelEm')}:{' '}
                  {series.find((item) => item.min === ages?.[0])?.name} à{' '}
                  {series.find((item) => item.max === ages?.[1])?.name}{' '}
                </div>
                <div
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'flex-end' : undefined,
                    gap: '8px',
                    fontWeight: 400,
                  }}
                  onClick={() => setIsOpenAgesModal(true)}
                >
                  {t('app.saibaMaisSobreAsFaixasEtarias')}
                  <div
                    style={{
                      background: colorPrimary,
                      borderRadius: '50%',
                      padding: '2px',
                    }}
                  >
                    <TfiInfo style={{ color: 'white', fontWeight: 900 }} />
                  </div>
                </div>
              </Flex>

              <Row
                gutter={{
                  xs: 8,
                  md: 24,
                }}
              >
                <Col xs={24} md={18}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroEnunciado'),
                      },
                    ]}
                    label={
                      methodology === 1
                        ? t('app.digiteAafirmaçãoDaEscalaLikertAqui')
                        : t('app.enunciado')
                    }
                    name="text"
                  >
                    <TextArea
                      id="id-tour-question-creation-input-statement"
                      rows={4}
                      placeholder={
                        methodology === 1
                          ? t('app.placeholderLikert')
                          : t('app.placeholderEnunciado')
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item name={'image'} label={t('app.adicionarImagem')}>
                    {/* @ts-ignore */}
                    <UploadWithPreview
                      folderKey="u4heroQuestions"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {methodology === 1 && (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('app.mensagemErroTipoAfirmaçãoLikert'),
                    },
                  ]}
                  label={t('app.estaAfirmaçãoÉ')}
                  name="likertType"
                >
                  <Radio.Group
                    onChange={(e) => addLikertOptions(e.target.value)}
                  >
                    <Flex
                      wrap={!isMobile ? 'wrap' : 'nowrap'}
                      vertical={isMobile}
                    >
                      <Radio value={'positive'}>{t('app.positiva')}</Radio>
                      <Radio value={'negative'}>{t('app.negativa')}</Radio>
                    </Flex>
                  </Radio.Group>
                </Form.Item>
              )}
            </div>

            <Flex
              justify="flex-end"
              gap="12px"
              style={{
                marginBottom: '12px',
              }}
            >
              <Text size="sm">{t('app.alternativasComImagem')}:</Text>
              <Form.Item
                style={{ margin: '0px' }}
                name="optionsWithImage"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Flex>

            <Form.List name="options">
              {(fields, { add, remove }) => (
                <Flex
                  vertical
                  gap="16px"
                  style={{
                    display: methodology === 0 ? 'flex' : 'none',
                  }}
                >
                  {fields.map((field, index) => (
                    <Flex
                      vertical
                      key={field.key}
                      style={{
                        background: '#F8F8F8',
                        borderRadius: '15px',
                        padding: '0px 16px 16px 16px',
                        position: 'relative',
                      }}
                    >
                      <Flex
                        style={{
                          width: 'fit-content',
                          borderRadius: '0px 10px',
                          background: hex2rgba(colorPrimary, 0.6),
                          padding: '3px 10px',
                          alignSelf: 'center',
                        }}
                      >
                        <Text style={{ fontWeight: 600 }} color="white">
                          {`${index + 1}° ${t(
                            'app.alternativa'
                          ).toLowerCase()}`}
                        </Text>
                      </Flex>

                      <FiTrash2
                        size={20}
                        id={`id-tour-trash-can-button-option-${index + 1}`}
                        style={{
                          justifySelf: 'flex-end',
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          showConfirm({
                            message: `${t('app.temCertezaQueDeseja')} ${t(
                              'app.excluirA'
                            )} “${index + 1}° ${t('app.alternativa')}” ?`,
                            colorPrimary,
                            isMobile,
                          }).then(({ isConfirmed }) => {
                            if (isConfirmed) {
                              remove(index);
                              notificateSucess(
                                `${t('app.alternativaExcluidaComSucesso')}`
                              );
                            }
                          });
                        }}
                      />

                      {!optionsWithImage && (
                        <Form.Item
                          label={`${ordinal[index]} ${t(
                            'app.alternativa'
                          ).toLowerCase()}`}
                          rules={[
                            {
                              required: true,
                              message: t('app.mensagemErroAlternativa'),
                            },
                          ]}
                          name={[index, 'text']}
                        >
                          <Input id="id-tour-question-creation-input-firstOption" />
                        </Form.Item>
                      )}

                      {analysisType === 0 && (
                        <Row
                          gutter={{
                            xs: 8,
                            md: 24,
                          }}
                        >
                          <Col xs={24} md={optionsWithImage ? 6 : 0}>
                            <Form.Item
                              name={[index, 'image']}
                              label={t('app.adicionarImagem')}
                              rules={[
                                {
                                  required: true,
                                  message: t('app.mensagemErroImagem'),
                                },
                              ]}
                            >
                              {/* @ts-ignore */}
                              <UploadWithPreview
                                folderKey="u4heroQuestions"
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={optionsWithImage ? 18 : 24}>
                            <Form.Item
                              label={'Feedback'}
                              rules={[
                                {
                                  required: analysisType === 0,
                                  message: t('app.mensagemErroAlternativa'),
                                },
                              ]}
                              name={[index, 'feedback']}
                            >
                              <Input.TextArea
                                id="id-tour-question-creation-input-firstFeedback"
                                rows={4}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      <Row
                        gutter={{
                          xs: 8,
                          md: 24,
                        }}
                      >
                        <Col
                          xs={24}
                          md={optionsWithImage && analysisType !== 0 ? 6 : 0}
                        >
                          <Form.Item
                            name={[index, 'image']}
                            label={t('app.adicionarImagem')}
                            rules={[
                              {
                                required:
                                  optionsWithImage && analysisType !== 0,
                                message: t('app.mensagemErroImagem'),
                              },
                            ]}
                          >
                            {/* @ts-ignore */}
                            <UploadWithPreview
                              folderKey="u4heroQuestions"
                              size="small"
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          xs={24}
                          md={optionsWithImage && analysisType !== 0 ? 18 : 24}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t('app.mensagemErroCompreensao'),
                              },
                            ]}
                            label={t('app.grauDeCompreensao')}
                            name={[index, 'value']}
                          >
                            <Slider
                              step={25}
                              marks={comprehentionMarks}
                              defaultValue={0}
                              id="id-tour-question-creation-input-firstComprehention"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Flex gap={8} vertical>
                        <Collapse
                          expandIconPosition="end"
                          items={[
                            {
                              key: '1',
                              label: `${t('app.hipotese')} - ${
                                ordinal[index]
                              } ${t('app.alternativa')} `,
                              children: (
                                <Flex
                                  gap={16}
                                  style={{ padding: '10px' }}
                                  vertical
                                >
                                  <Form.Item
                                    name={[index, 'hypothesis']}
                                    label={t('app.hipotese')}
                                  >
                                    <TextArea
                                      style={{ minHeight: '135px' }}
                                      id="id-tour-question-creation-input-firstHypothesis"
                                    />
                                  </Form.Item>
                                  <Button
                                    style={{
                                      alignSelf: 'center',
                                      marginBottom: '16px',
                                    }}
                                    size="md"
                                    variant="outline"
                                    loading={isLoadingAi}
                                    onClick={() => {
                                      generateWithAi({
                                        question: questionText,
                                        answer: form.getFieldValue([
                                          'options',
                                          index,
                                          'text',
                                        ]),
                                        minAge:
                                          form.getFieldValue('ageRange')[0],
                                        maxAge:
                                          form.getFieldValue('ageRange')[1],
                                        isHypothesis: true,
                                        index: index,
                                      });
                                    }}
                                  >
                                    {t('app.gerar')} {t('app.hipotese')}
                                  </Button>
                                </Flex>
                              ),
                            },
                          ]}
                        />

                        <Collapse
                          expandIconPosition="end"
                          items={[
                            {
                              label: `${t('app.sugestoes')} - ${
                                ordinal[index]
                              } ${t('app.alternativa')}`,
                              children: (
                                <Flex
                                  style={{ padding: '10px' }}
                                  gap={16}
                                  vertical
                                >
                                  <Form.Item
                                    name={[index, 'orientation']}
                                    label={t('app.sugestoes')}
                                  >
                                    <TextArea
                                      style={{ minHeight: '135px' }}
                                      id="id-tour-question-creation-input-firstSuggestion"
                                    />
                                  </Form.Item>
                                  <Button
                                    style={{
                                      alignSelf: 'center',
                                      marginBottom: '16px',
                                    }}
                                    variant="outline"
                                    size="md"
                                    loading={isLoadingAi}
                                    onClick={() => {
                                      generateWithAi({
                                        question: questionText,
                                        answer: form.getFieldValue([
                                          'options',
                                          index,
                                          'text',
                                        ]),
                                        competence: competence?.name,
                                        skill: skill?.name,
                                        minAge:
                                          form.getFieldValue('ageRange')[0],
                                        maxAge:
                                          form.getFieldValue('ageRange')[1],
                                        isHypothesis: false,
                                        index: index,
                                      });
                                    }}
                                  >
                                    {t('app.gerar')} {t('app.sugestao')}
                                  </Button>
                                </Flex>
                              ),
                            },
                          ]}
                        />
                      </Flex>
                    </Flex>
                  ))}
                  <Flex justify="center">
                    <Button
                      disabled={options?.length >= 5}
                      onClick={() => add()}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      size="md"
                    >
                      <PlusOutlined
                        style={{ color: 'white', fontSize: '24px' }}
                      />
                      {t('app.alternativas')}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Form.List>
          </Flex>
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
}

export default QuestionDetails;
