import { useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  Modal,
  Input,
  Row,
  Col,
  Form,
  Radio,
  Space,
  Switch,
  Select,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import LoadingDots from 'components/V2/loading-dots';
import CustomizedRequiredMark from 'components/required-mark';
import { useEffect, useState } from 'react';
import { StoreItemDetails } from 'core/features/store-items/typings';
import { ContentType, contentTypes } from 'infra/helpers/types';
import UploadWithPreview from 'components/modals/modal-interaction/components/uploadImageWithPreview';
import GenerateImage from 'components/modals/modal-interaction/components/generateImage';
import InteractionOptions from './components/InteractionOptions';
import {
  Interaction,
  InteractionRole,
  StoryRoute,
} from 'core/features/stories/typings';
import SoundsSection from 'components/modals/modal-interaction/components/soundsSection';
import { notificateError, openNotification } from 'infra/helpers/notifications';

interface ModalInteractionProps {
  onClose: () => void;
  onSave: (interaction: Interaction) => void;
  interaction?: Interaction;
  characters?: StoreItemDetails[];
  routes?: StoryRoute[];
  locale: string;
  index: number;
}

function ModalInteraction({
  interaction,
  onClose,
  onSave,
  index,
  characters,
  routes,
  locale,
}: ModalInteractionProps) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [interactionForm] = Form.useForm();
  const [useAiImage, setUseAiImage] = useState(false);
  const formValues = Form.useWatch('interaction', interactionForm);

  useEffect(() => {
    interactionForm.resetFields();
  }, [interaction, interactionForm]);

  const validateOptions = () => {
    const options = formValues.options;
    if (formValues.contentType === ContentType.REINFORCEMENT) {
      const hasCorrectOption = options.some((option: any) => option.isCorrect);
      if (!hasCorrectOption) {
        throw new Error('Por favor, marque pelo menos uma opção como correta!');
      }
      return Promise.resolve();
    }
  };

  const handleSave = (createAnother?: boolean) => {
    interactionForm
      .validateFields()
      .then(() => validateOptions())
      .then(() => {
        const values = interactionForm.getFieldsValue();
        const newInteraction: Interaction = {
          ...values.interaction,
          index,
        };
        onSave(newInteraction);
        if (createAnother) {
          interactionForm.resetFields();
        } else {
          onClose();
        }
      })
      .catch((error) => {
        notificateError(error.message);
      });
  };

  const textAreaLabels: Record<
    ContentType,
    {
      label: string;
      required: boolean;
      message: string;
      placeholder: string;
    }
  > = {
    [ContentType.DIALOGUE]: {
      label: 'Conteúdo Textual (máximo 300 caracteres)',
      required: true,
      message: 'Por favor, insira o conteúdo textual!',
      placeholder:
        'Adicione o texto do narrador ou do personagem que será exibido.',
    },
    [ContentType.END]: {
      label: 'Reflexão (máximo 300 caracteres)',
      required: true,
      message: 'Por favor, insira a reflexão!',
      placeholder:
        'Inclua a reflexão, aprendizado ou mensagem de encerramento! ',
    },
    [ContentType.DECISION]: {
      label: 'Pergunta (máximo 300 caracteres)',
      required: true,
      message: 'Por favor, insira o enunciado da pergunta!',
      placeholder: 'Crie um enunciado',
    },
    [ContentType.REINFORCEMENT]: {
      label: 'Pergunta (máximo 300 caracteres)',
      required: true,
      message: 'Por favor, insira o enunciado da pergunta!',
      placeholder: 'Crie um enunciado',
    },
  };

  return (
    <Modal
      title={
        <SubTitle primary>
          {interaction ? 'Editar Interação' : 'Nova Interação'}
        </SubTitle>
      }
      open={true}
      onCancel={() => {
        if (interactionForm.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={
        <Flex
          vertical
          justify="center"
          align="center"
          gap={isMobile ? '12px' : '24px'}
        >
          <Button
            htmlType="submit"
            size="md"
            onClick={() => handleSave()}
            id="id-tour-modal-button-save"
            disabled={formValues?.contentType === undefined}
          >
            {t('app.salvar')}
          </Button>
          <Button
            variant="outline"
            size="md"
            onClick={() => handleSave(true)}
            id="id-tour-modal-button-save-and-create-new-route"
            disabled={formValues?.contentType === undefined}
          >
            SALVAR E CRIAR NOVA INTERAÇÃO
          </Button>
        </Flex>
      }
      width={isMobile ? '90%' : 770}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        form={interactionForm}
        layout="vertical"
        validateTrigger="submit"
        requiredMark={CustomizedRequiredMark}
        initialValues={{
          interaction: {
            contentType: interaction?.contentType ?? undefined,
            role: interaction?.role ?? undefined,
            textContent: interaction?.textContent ?? undefined,
            characterId: interaction?.characterId ?? undefined,
            options: interaction?.options ?? [],
            optionsWithImage: interaction?.optionsWithImage ?? false,
            soundEffect: interaction?.soundEffect ?? undefined,
            voiceId: interaction?.voiceId ?? undefined,
            imageUrl: interaction?.imageUrl ?? undefined,
            nextRouteIndex: interaction?.nextRouteIndex ?? undefined,
          },
        }}
      >
        <Form.Item
          label="Tipo de Conteúdo:"
          rules={[
            {
              required: true,
              message: 'Por favor, defina o tipo de conteúdo!',
            },
          ]}
          name={['interaction', 'contentType']}
        >
          <Radio.Group
            id="id-tour-modal-story-interaction-content-type"
            onChange={(e) => {
              interactionForm.setFields([
                {
                  name: ['interaction', 'role'],
                  value: undefined,
                },
                {
                  name: ['interaction', 'characterId'],
                  value: undefined,
                },
              ]);
              if (
                e.target.value === ContentType.DECISION ||
                e.target.value === ContentType.REINFORCEMENT
              ) {
                interactionForm.setFields([
                  {
                    name: ['interaction', 'options'],
                    value: [
                      {
                        index: 0,
                        text: undefined,
                        imageUrl: undefined,
                        isCorrect: undefined,
                        nextRouteIndex: undefined,
                        feedback: undefined,
                      },
                      {
                        index: 1,
                        text: undefined,
                        imageUrl: undefined,
                        isCorrect: undefined,
                        nextRouteIndex: undefined,
                        feedback: undefined,
                      },
                    ],
                  },
                ]);
              }
            }}
          >
            <Space direction="horizontal" wrap>
              {contentTypes.map((item, key) => (
                <Radio key={key} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item name={['interaction', 'optionsWithImage']} hidden />

        {formValues?.contentType === undefined ? (
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ marginTop: '40px' }}
            gap={'8px'}
          >
            <LoadingDots />
            <Text size="md" color={colorPrimary}>
              Escolha o tipo de conteúdo para continuar preenchendo as
              informações
            </Text>
          </Flex>
        ) : (
          <>
            <Form.Item
              rules={[
                {
                  required: formValues.contentType === ContentType.DIALOGUE,
                  message: 'Por favor, defina o papel da interação!',
                },
              ]}
              name={['interaction', 'role']}
              label="Defina o Papel"
              hidden={formValues.contentType !== ContentType.DIALOGUE}
            >
              <Radio.Group id="id-tour-modal-story-interaction-role">
                <Space direction="horizontal" wrap>
                  {[
                    {
                      label: 'Narrador',
                      value: InteractionRole.NARRATOR,
                    },
                    {
                      label: 'Personagem',
                      value: InteractionRole.CHARACTER,
                    },
                  ].map((item, index) => (
                    <Radio value={item.value} key={index}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name={['interaction', 'characterId']}
              label="Personagem"
              rules={[
                {
                  required: formValues.role === InteractionRole.CHARACTER,
                  message: 'Por favor, defina o personagem da interação!',
                },
              ]}
              hidden={formValues.role !== InteractionRole.CHARACTER}
            >
              <Radio.Group style={{ width: '100%' }}>
                <Row
                  gutter={[16, 16]}
                  style={{ maxHeight: '300px', overflowY: 'auto' }}
                >
                  {characters?.map((item) => (
                    <Col xs={12} md={8} key={item.id}>
                      <Radio value={item.id}>
                        <img
                          src={item.imageUrl}
                          alt={item.name}
                          style={{
                            width: '100%',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                            backgroundColor: '#4891e0',
                          }}
                        />
                        <div>{item.name}</div>
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={
                textAreaLabels?.[formValues?.contentType as ContentType]?.label
              }
              rules={[
                {
                  required: true,
                  message:
                    textAreaLabels?.[formValues?.contentType as ContentType]
                      ?.message,
                },
              ]}
              name={['interaction', 'textContent']}
              trigger="onBlur"
              valuePropName="defaultValue"
            >
              <Input.TextArea
                id="id-tour-create-store-item-modal-input-description"
                placeholder={
                  textAreaLabels?.[formValues?.contentType as ContentType]
                    ?.placeholder
                }
                maxLength={300}
                rows={3}
              />
            </Form.Item>

            {formValues.contentType !== ContentType.END && (
              <>
                {isMobile && (
                  <Flex gap="16px" align="center" justify="flex-end">
                    <Text size="sm">Utilizar IA:</Text>
                    <Switch
                      onChange={(checked) => {
                        setUseAiImage(checked);
                      }}
                    />
                  </Flex>
                )}

                <Row align="middle" gutter={[16, 16]}>
                  <Col xs={isMobile && !useAiImage ? 24 : 0} md={7}>
                    <Form.Item
                      label={t('app.adicionarImagem')}
                      name={['interaction', 'imageUrl']}
                    >
                      {/* @ts-ignore */}
                      <UploadWithPreview folderKey="u4heroStories" />
                    </Form.Item>
                  </Col>
                  <Col xs={0} md={2}>
                    <SubTitle primary>OU</SubTitle>
                  </Col>
                  <Col xs={isMobile && useAiImage ? 24 : 0} md={15}>
                    <Form.Item
                      label={'Gerar Imagem com IA'}
                      name={['interaction', 'imageUrl']}
                    >
                      {/* @ts-ignore */}
                      <GenerateImage />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Form.Item
              hidden={formValues.contentType !== ContentType.DIALOGUE}
              name={['interaction', 'nextRouteIndex']}
            >
              <Select
                size={isMobile ? 'middle' : 'large'}
                placeholder="Destino"
                options={routes?.map((route) => ({
                  label: route.name,
                  value: route.index,
                }))}
                allowClear
              />
            </Form.Item>

            <InteractionOptions routes={routes} />
            <SoundsSection locale={locale} />
          </>
        )}
      </Form>
    </Modal>
  );
}

export default ModalInteraction;
