import { Col, Flex, Form, Input, Radio, Row, Select } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/layout';
import { useEffect, useState } from 'react';
import {
  notificateSucess,
  openNotification,
  notificateError,
} from 'infra/helpers/notifications';
import { useCurrentUser } from 'core/features/user/store';
import { CompetenceDetails } from 'core/features/questions/types/QuestionsDetails';
import BackButton from 'components/buttons/back-button';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import CustomizedRequiredMark from 'components/required-mark';
import { t } from 'core/resources/strings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import SelectTagType from 'components/select-tag-type';
import Button from 'components/buttons/button';
import lumiWithPencil from 'assets/images/lumi-with-pencils.png';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import SelectSkill from 'ui/pages/app/practices/pages/CreatePractice/components/selectSkill';
import SelectCompetence from 'components/modals/modal-tag/select-competence';
import useChatGPT from 'core/features/chatgpt/hooks/useChatGpt';
import LoadingDots from 'components/V2/loading-dots';
import { useSavePractice } from 'core/features/practices/hooks/useSavePractice';
import { PracticeForm } from 'core/features/practices/typings';

const subjects = [
  'Arte',
  'Ciências',
  'Educação Física',
  'Geografia',
  'História',
  'Língua Inglesa',
  'Língua Portuguesa',
  'Matemática',
];

const analysisTypes = [
  {
    id: 0,
    description:
      'O tipo de análise Conhecimento, em um contexto mais teórico, refere-se ao entendimento dos princípios e conceitos relacionados à educação socioemocional. Nesse sentido, é o nível de conhecimento sobre os componentes (competências, habilidades e temas) da educação socioemocional, sem necessariamente esta compreensão refletir seu Comportamento.',
  },
  {
    id: 1,
    name: 'O tipo de análise "Comportamento" descreve uma escolha feita em um momento específico, guiada pela interação entre a reflexão e a resposta imediata às demandas socioemocionais do ambiente. Essa decisão pode ocorrer tanto de maneira impulsiva quanto reflexiva, mas é influenciada pelo contexto social e história de vida da pessoa, assim como a habilidade em aplicar suas competências socioemocionais ou não em determinada situação.',
  },
  {
    id: 2,
    name: 'A Percepção refere-se à avaliação subjetiva que um indivíduo faz dos comportamentos e habilidades e competências socioemocionais sobre ele, outra pessoa, de um grupo, ou de seu ambiente como um todo, baseando-se em observações limitadas e na interação superficial. Essa análise muitas vezes carece do contexto completo e aprofundado necessário para uma compreensão abrangente das capacidades socioemocionais. A despeito, a Percepção externa pode se configurar como uma forma de mapear o envolvimento do estudante na escola e na família e em como ele vem desenvolvendo suas relações sociais.',
  },
];

interface GptResponse {
  title: string;
  preRequisites: string;
  goal: string;
  observationPoints: string;
  content: string;
  inclusionGuide: string;
  resources: string;
  suggestedTag: string;
  previousContext: string;
}

const dificulties = ['Fácil', 'Médio', 'Difícil'];

const modalityTypes = [
  { label: 'Presencial', value: 0 },
  { label: 'Remoto', value: 1 },
  { label: 'Múltiplos Contextos', value: 3 },
];

function CreatePracticeWithAi() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const { user } = useCurrentUser();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSavePractice();
  const { mutate, isLoading: isLoadingGenerate } = useChatGPT();
  const environments = useEnvironments();
  const [competenceDetails, setCompetenceDetails] = useState<
    CompetenceDetails | undefined
  >();

  const [skillDetails, setSkillDetails] = useState<
    CompetenceDetails | undefined
  >();

  const [response, setResponse] = useState<GptResponse | undefined>();

  const tagType = Form.useWatch('tagType', form);
  const analysisEnvironmentId = Form.useWatch('analysisEnvironmentId', form);
  const modalityId = Form.useWatch('practiceModalityId', form);

  const competence = Form.useWatch('competence1', form);
  const subject = Form.useWatch('subject', form);
  const { colorGreyBorderAndFont, colorSecondary } = useU4heroColors();

  const history = useHistory();

  const handleOk = () => {
    history.push('/practices');
  };

  const onSubmit = (values: PracticeForm) => {
    delete values.context;
    delete values.tagType;

    if (values.subject === null) {
      delete values.subject;
    }
    handleSave(
      { ...values, published: true },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao publicar a inspiração pedagógica. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(`Inspiração pedagógica publicada com sucesso!`);
          setTimeout(() => {
            history.push('/practices/manager');
          }, 200);
        },
      }
    );
  };

  const handleBack = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose: handleOk, colorPrimary, isMobile });
    } else {
      handleOk();
    }
  };

  const handleGeneratePractice = (values: any) => {
    const environment = environments?.data?.find(
      (item) => item.id === analysisEnvironmentId
    )?.name;
    const environmentName = t(`app.${environment}`);
    const modality = modalityTypes.find(
      (item) => item.value === modalityId
    )?.label;
    const promptCustom = `
Vamos criar uma atividade dinâmica para ser executada em um ambiente ${environmentName} na modalidade ${modality}, para uma ${
      values.context
    } com tempo máximo de execução de ${
      values.duration
    }. Essa atividade deve contribuir no desenvolvimento da habilidade ${
      skillDetails?.name
    }, que significa ${skillDetails?.description}, em estudantes do ${
      values.maxAge > 16 ? 'ensino médio' : 'ensino fundamental'
    }, que possuem no mínimo ${
      values.maxAge
    } anos. Esta habilidade pertence à competência ${
      competenceDetails?.name
    }, por isso ao elaborar a dinâmica leve também em consideração o seguinte conceito:  ${
      competenceDetails?.description
    }. O nível de dificuldade de execução da atividade deve ser ${
      values.dificulty
    }, tanto para o professor como para os estudantes. Essa atividade deve estar diretamente relacionada com a seguinte habilidade da bncc ${
      values.bnccCode
    }, ${
      values.bnccDescription
    }, referente ao seguinte componente curricular ${subject}.
Essa atividade deve respeitar a seguinte estrutura:
Título - Escreva um título claro e objetivo, engajante, relevante ao conteúdo, podendo usar de metáforas e analogias, personalizados e podendo ser em formato de pergunta, o máximo de 30 caracteres. 
Pré-Requisitos - Liste quais são as exigências básicas para execução dessa inspiração.
Contextos Prévios - Descreva o conhecimento, habilidades ou experiências que os alunos podem ter experimentados antes de participar da atividade proposta. 
//Instruções//
Resumo - Descreva brevemente a atividade, de forma contextualizada e relevante, apresentando ao final uma menção aos resultados esperados da atividade, tanto em termos de  aprendizado quanto de desenvolvimento socioemocional
Objetivo Específico - Escreva o desdobramento do objetivo geral, use uma linguagem clara e precisa, evitando ambiguidades, sempre que possível, os objetivos específicos devem ser mensuráveis, cada objetivo específico deve focar em uma competência ou habilidade distinta.Cada objetivo específico deve descrever uma ação e o resultado esperado desta ação.
Passo-a-passo: Escreva todas as instruções com detalhes, mais claras, sobre como a atividade deve ser conduzida, desde a preparação até a conclusão. Estruture as etapas de maneira lógica e sequencial, começando pela preparação e seguindo através da execução até o encerramento da atividade. Descreva cada etapa de forma detalhada, especificando o que o educador e os alunos devem, Indique o tempo estimado que cada etapa deve levar, para ajudar o educador a manter o controle do tempo. Forneça dicas ou orientações específicas para o educador e por último inclua uma etapa final de encerramento onde o educador pode resumir o que foi aprendido e oferecer uma oportunidade para os alunos refletirem sobre a experiência.
Avaliação - Crie uma sugestão de formas de avaliar a atividade, ou seja, medir o sucesso da atividade pedagógica e o desenvolvimento dos alunos em relação aos objetivos propostos. Ela pode ser: Diagnóstica, Formativa ou  Somativa. Além disso, ela pode ocorrer por alguns instrumentos, como observação direta, rubricas, autoavaliação, ou feedback dos pares.
//Objetivos//
Objetivo Geral - Escreva um objetivo geral, com foco no resultado principal, deve descrever uma conexão com a competência e habilidade, uma linguagem clara e objetiva, alinhada ao público-alvo e com ênfase no processo de aprendizagem. O objetivo geral deve ser único e coeso, evitando a inclusão de múltiplos objetivos que poderiam ser melhor abordados nos objetivos específicos.
Pontos de Observação - Escreva quais são os pontos que os educadores devem estar atendo ao executar a dinâmica para realizar uma avaliação.
//Inclusão//
Inclusão - Descreva como adaptar a atividade em situações onde seja necessário incluir estudantes com deficiência ou neurotípicos. Elabore estratégias para tornar a inspiração pedagógica acessível a todos os alunos, independentemente de suas necessidades ou habilidades. Liste algumas sugestões de adaptação para pessoas com necessidades com Deficiência Visual, Deficiência Auditiva, Mobilidade Reduzida. Além de transtornos como TDAH. Escreva sugestões de avaliações adaptadas para cada tipo.
Materiais Necessários - Liste todos os materiais que são necessários para executar a atividade e onde eles serão usados na dinâmica. Ex: Papel A4 (1 folha por aluno) para escreverem responderem as perguntas.
Sugestão de Trilha - Dentre as competências socioemocionais trabalhadas pela U4Hero, selecione uma para dar continuidade ao aprendizado.
    `;

    const response_format = {
      type: 'json_schema',
      json_schema: {
        name: 'practice_schema',
        schema: {
          type: 'object',
          properties: {
            title: {
              description: 'O título da inspiração pedagógica',
              type: 'string',
              maxLength: 30,
            },
            preRequisites: {
              description:
                'Os pré-requisitos necessários para realizar a atividade',
              type: 'string',
            },
            goal: {
              description: 'Os objetivos da atividade',
              type: 'string',
            },
            observationPoints: {
              description: 'Os pontos de observação da atividade',
              type: 'string',
            },
            content: {
              description:
                'Resumo - objetivo - passo a passo - avaliação devem ser todos adicionados a esse campo',
              type: 'string',
            },
            inclusionGuide: {
              description: 'As orientações para inclusão na atividade',
              type: 'string',
            },
            resources: {
              description: 'Os materiais necessários para realizar a atividade',
              type: 'string',
            },
            suggestedTag: {
              description:
                'A trilha sugerida para dar continuidade ao aprendizado',
              type: 'string',
            },
            previousContext: {
              description: 'O contexto prévio da atividade',
              type: 'string',
            },
          },
          required: [
            'title',
            'preRequisites',
            'goal',
            'observationPoints',
            'previousContext',
            'content',
            'inclusionGuide',
            'resources',
            'suggestedTag',
          ],
          additionalProperties: false,
        },
      },
    };

    mutate(
      {
        prompt: promptCustom,
        response_format,
      },
      {
        onSuccess: (v: any) => {
          setResponse(JSON.parse(v.data));
        },
        onError: (err: any) => {
          notificateError(err?.response?.data?.details[0]?.message);
        },
      }
    );
  };

  const handleGenerateClick = async () => {
    try {
      const values = await form.validateFields([
        'context',
        'maxAge',
        'bnccCode',
        'tagType',
        'competence',
        'skill',
        'target',
        'analysisEnvironmentId',
        'dificulty',
        'practiceModalityId',
        'bnccDefinition',
        'duration',
      ]);
      handleGeneratePractice(values);
    } catch (error) {}
  };

  useEffect(() => {
    form.setFieldsValue({
      title: response?.title,
      preRequisites: response?.preRequisites,
      goal: response?.goal,
      observationPoints: response?.observationPoints,
      content: response?.content,
      inclusionGuide: response?.inclusionGuide,
      resources: response?.resources,
      suggestedTag: response?.suggestedTag,
      previousContext: response?.previousContext,
    });
  }, [response]);

  return (
    <Layout.Container>
      <Form
        onFinish={onSubmit}
        layout="vertical"
        form={form}
        initialValues={{}}
        requiredMark={CustomizedRequiredMark}
      >
        <Flex gap="23px" style={{ marginBottom: '16px' }}>
          <BackButton onClick={handleBack} />
          <Title primary>{t('app.gerarInspiracaoComIA')}</Title>
        </Flex>

        <Flex vertical gap={16} style={{ marginBottom: '24px' }}>
          <Row
            gutter={[
              { xs: 16, md: 48 },
              { xs: 0, md: 0 },
            ]}
            align="stretch"
          >
            <Col xs={24} md={9}>
              <Flex vertical>
                <Text
                  size="md"
                  bold
                  style={{
                    backgroundColor: hex2rgba(colorPrimary, 0.7),
                    borderRadius: '20px 20px 0px 0px',
                    padding: '8px',
                    width: '100%',
                  }}
                  color="white"
                  align="center"
                >
                  {t('app.geracaoDaInspiracao')}
                </Text>
                <Flex
                  vertical
                  style={{
                    borderRadius: '0px 0px 10px 10px',
                    padding: '16px 8px 16px 8px',
                    boxShadow:
                      '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <Form.Item
                    name="context"
                    label={t('app.contexto')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroContexto'),
                      },
                    ]}
                  >
                    <Input.TextArea
                      id="id-tour-create-question-with-ai-input-context"
                      placeholder={t('app.descrevaOContexto')}
                      rows={3}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Faixa Etária"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, determine a faixa etária!',
                      },
                    ]}
                    name="maxAge"
                  >
                    <Radio.Group id="id-tour-create-practice-input-max-age">
                      <Row gutter={[8, 8]} wrap>
                        {[3, 6, 12, 16].map((item) => (
                          <Col key={item} xs={12} sm={12}>
                            <Radio
                              value={item}
                              style={{
                                whiteSpace: 'normal',
                                lineHeight: '1.5',
                              }}
                            >
                              {item} anos +
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Código BNCC"
                    rules={[
                      {
                        required: true,
                        message: 'Insira o código!',
                      },
                    ]}
                    name="bnccCode"
                  >
                    <Input
                      id="id-tour-create-practice-input-bnccCode"
                      size={isMobile ? 'middle' : 'large'}
                      placeholder="Insira o código"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Definição do Código"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, descreva o código inserido!',
                      },
                    ]}
                    name="bnccDefinition"
                  >
                    <Input.TextArea
                      id="id-tour-create-practice-input-bnccDefinition"
                      size={isMobile ? 'middle' : 'large'}
                      placeholder="Descreva o código inserido"
                    />
                  </Form.Item>

                  <SelectTagType
                    id="id-tour-create-question-input-tag-type"
                    onChange={() => {
                      form.setFieldValue('skill', undefined);
                      form.setFieldValue('competence1', undefined);
                    }}
                  />

                  <Row align="middle" gutter={[16, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label="Campo de Conhecimento:"
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, selecione um campo de conhecimento!',
                          },
                        ]}
                        name="competence1"
                      >
                        {/* @ts-ignore */}
                        <SelectCompetence
                          type={tagType}
                          onChange={(v, comp) => {
                            setCompetenceDetails(comp);
                          }}
                          disabled={tagType === undefined}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Habilidade"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, selecione uma habilidade!',
                          },
                        ]}
                        name="skill"
                      >
                        {/* @ts-ignore */}
                        <SelectSkill
                          parentId={competence}
                          disabled={
                            competence === undefined || tagType === undefined
                          }
                          onChange={(v, skill) => {
                            setSkillDetails(skill);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Direcionamento da Inspiração"
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, determine a o direcionamento da Inspiração!',
                      },
                    ]}
                    name="target"
                  >
                    <Radio.Group id="id-tour-create-practice-input-target">
                      <Row gutter={[8, 8]} wrap>
                        {['Individual', 'Coletivo', 'Universal'].map((item) => (
                          <Col key={item} xs={12} sm={8}>
                            <Radio
                              value={item}
                              style={{
                                whiteSpace: 'normal',
                                lineHeight: '1.5',
                              }}
                            >
                              {item}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroAmbienteAnalise'),
                      },
                    ]}
                    label={t('app.ambienteAnalise')}
                    name="analysisEnvironmentId"
                  >
                    <Radio.Group id="id-tour-question-creation-input-analysisEnvironment">
                      <Row gutter={[8, 8]} wrap>
                        {environments?.data?.map((env) => (
                          <Col key={env.id} xs={24} sm={12}>
                            <Radio
                              value={env.id}
                              style={{
                                whiteSpace: 'normal',
                                lineHeight: '1.5',
                              }}
                            >
                              {t(`app.${env.name}`)}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Grau de Dificuldade"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, defina o grau de dificuldade!',
                      },
                    ]}
                    name="dificulty"
                  >
                    <Radio.Group id="id-tour-create-practice-input-dificulty">
                      <Flex gap={isMobile ? '24px' : '40px'}>
                        {dificulties.map((item, index) => (
                          <Col key={item} span={8}>
                            <Radio
                              value={item}
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {item}
                            </Radio>
                          </Col>
                        ))}
                      </Flex>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Modalidade"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, determine a modalidade!',
                      },
                    ]}
                    name="practiceModalityId"
                  >
                    <Radio.Group id="id-tour-create-practice-input-practiceModalityId">
                      <Row gutter={[8, 8]} wrap>
                        {modalityTypes.map((item) => (
                          <Col key={item.label} xs={24} sm={8}>
                            <Radio
                              value={item.value}
                              style={{
                                whiteSpace: 'normal',
                                lineHeight: '1.5',
                              }}
                            >
                              {item.label}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Associe sua Inspiração pedagógica a uma disciplina"
                    name="subject"
                  >
                    <Select
                      options={subjects.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      placeholder="Selecione uma disciplina"
                      style={{ width: '100%' }}
                      size={isMobile ? 'middle' : 'large'}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Média de Duração (min)"
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, determine o tempo de duração da prática!',
                      },
                    ]}
                  >
                    <Select
                      options={[10, 20, 30, 40, 50, 60].map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      placeholder="Selecione a média de duração"
                      style={{ width: '100%' }}
                      size={isMobile ? 'middle' : 'large'}
                    />
                  </Form.Item>

                  <Form.Item name="minAge" hidden />
                  <Form.Item name="maxAge" hidden />
                  <Form.Item name="privacyId" hidden />
                  <Form.Item name="locale" hidden />

                  <Button
                    size="md"
                    style={{ justifyContent: 'center', alignSelf: 'center' }}
                    onClick={handleGenerateClick}
                    id="generate-button"
                    loading={isLoadingGenerate}
                  >
                    {t('app.gerar')}
                  </Button>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} md={15}>
              <Flex vertical style={{ height: '100%' }}>
                <Text
                  size="md"
                  bold
                  style={{
                    backgroundColor: hex2rgba(colorPrimary, 0.7),
                    borderRadius: '20px 20px 0px 0px',
                    padding: '8px',
                    width: '100%',
                  }}
                  color="white"
                  align="center"
                >
                  {t('app.inspiracaoGerada')}
                </Text>
                <Flex
                  vertical
                  style={{
                    borderRadius: '0px 0px 10px 10px',
                    padding: '16px 8px 16px 8px',
                    boxShadow:
                      '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    height: '100%',
                  }}
                  gap={8}
                >
                  {response !== undefined ? (
                    <>
                      <Text>
                        Inspiração gerada! Sinta-se à vontade para ajustar:
                      </Text>
                      <Text size="md" color={colorSecondary} bold>
                        Título
                      </Text>
                      <Form.Item
                        name="title"
                        label={''}
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, crie um título para a publicação!',
                          },
                          {
                            max: 30,
                            message:
                              'O título pode ter no máximo 30 caracteres!',
                          },
                        ]}
                      >
                        <Input
                          size={isMobile ? 'middle' : 'large'}
                          id="id-tour-create-practice-with-ai-input-title"
                        />
                      </Form.Item>

                      <Text size="md" color={colorSecondary} bold>
                        Pré-Requisitos
                      </Text>
                      <Form.Item
                        name="preRequisites"
                        label={''}
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, descreva os pré-requisitos!',
                          },
                        ]}
                      >
                        <Input.TextArea
                          style={{
                            border: '0.5px dashed #262626',
                            borderRadius: '8px',
                            minWidth: '100%',
                          }}
                          id="id-tour-create-question-with-ai-input-question"
                          rows={4}
                        />
                      </Form.Item>

                      <Text size="md" color={colorSecondary} bold>
                        Materiais
                      </Text>

                      <Form.Item
                        label=""
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, cite os materiais que serão necessários!',
                          },
                        ]}
                        name="resources"
                      >
                        <Input
                          id="id-tour-create-practice-input-resources"
                          size={isMobile ? 'middle' : 'large'}
                          placeholder="Insira os materiais necessários"
                        />
                      </Form.Item>

                      <Text size="md" color={colorSecondary} bold>
                        Contexto Prévio
                      </Text>
                      <Form.Item
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, descreva o contexto!',
                          },
                        ]}
                        name="previousContext"
                      >
                        <Input.TextArea
                          id="id-tour-create-practice-input-title"
                          size={isMobile ? 'middle' : 'large'}
                          rows={4}
                        />
                      </Form.Item>

                      <Text size="md" color={colorSecondary} bold>
                        Objetivos
                      </Text>

                      <Form.Item
                        label=""
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, descreva o objetivo da Inspiração pedagógica!',
                          },
                        ]}
                        name="goal"
                      >
                        <Input.TextArea
                          id="id-tour-create-practice-input-goal"
                          placeholder="Insira os objetivos"
                          rows={2}
                        />
                      </Form.Item>

                      <Text size="md" color={colorSecondary} bold>
                        Pontos de Observação
                      </Text>
                      <Form.Item
                        name="observationPoints"
                        label={''}
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, descreva os pontos de observação!',
                          },
                        ]}
                      >
                        <Input.TextArea
                          style={{
                            border: '0.5px dashed #262626',
                            borderRadius: '8px',
                            minWidth: '100%',
                          }}
                          id="id-tour-create-question-with-ai-input-question"
                          rows={4}
                        />
                      </Form.Item>
                      <Text size="md" color={colorSecondary} bold>
                        Conteúdo
                      </Text>
                      <Form.Item
                        name="content"
                        label={''}
                        rules={[
                          {
                            required: true,
                            message: t('app.erroInstrucoes'),
                          },
                        ]}
                      >
                        <Input.TextArea
                          style={{
                            border: '0.5px dashed #262626',
                            borderRadius: '8px',
                            minWidth: '100%',
                          }}
                          id="id-tour-create-question-with-ai-input-question"
                          rows={4}
                        />
                      </Form.Item>
                      <Text size="md" color={colorSecondary} bold>
                        Orientações para Inclusão
                      </Text>
                      <Form.Item
                        label={''}
                        rules={[
                          {
                            required: true,
                            message: t('app.erroGuiaDeInclusao'),
                          },
                        ]}
                        name="inclusionGuide"
                      >
                        <Input.TextArea
                          style={{
                            border: '0.5px dashed #262626',
                            borderRadius: '8px',
                            minWidth: '100%',
                          }}
                          id="id-tour-create-question-with-ai-input-question"
                          rows={4}
                        />
                      </Form.Item>
                      <Text size="md" color={colorSecondary} bold>
                        Trilha Sugerida
                      </Text>
                      <Form.Item
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, sugira uma trilha!',
                          },
                        ]}
                        name="suggestedTag"
                      >
                        <Input
                          id="id-tour-create-practice-input-suggestedTag"
                          size={isMobile ? 'middle' : 'large'}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      style={{ height: '100%' }}
                      gap={16}
                    >
                      {isLoadingGenerate ? (
                        <>
                          <LoadingDots />
                          <Text size="md" color={colorGreyBorderAndFont}>
                            {t('app.aguardeInspiracaoGerada')}
                          </Text>
                        </>
                      ) : (
                        <>
                          <img src={lumiWithPencil} width={252} />
                          <Text
                            size="md"
                            align="center"
                            color={colorGreyBorderAndFont}
                          >
                            {t('app.aguardeInspiracaoPedagogicaGerada')}
                          </Text>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              onClick={handleBack}
              id="id-tour-create-question-button-cancel"
            >
              {t('app.cancelar')}
            </Button>

            <Button
              htmlType="submit"
              size="md"
              id="id-tour-create-practice-button-save"
              loading={isLoadingSave}
            >
              {t('app.salvar')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default CreatePracticeWithAi;
