import InstitutionIcon from 'assets/images/icons/InstitutionIcon';
import useTotalSchools from 'core/features/indicators/hooks/useTotalSchools';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router-dom';
import StatCard from '../statCard';

function TotalSchools() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data } = useTotalSchools({ tenantId });
  return (
    <StatCard
      title={t('app.escola')}
      total={data?.total ?? 0}
      Icon={InstitutionIcon}
    />
  );
}

export default TotalSchools;
