import {
  Col,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  SliderSingleProps,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import { useEffect, useState } from 'react';
import {
  notificateSucess,
  openNotification,
  notificateError,
} from 'infra/helpers/notifications';
import { useSaveQuestion } from 'core/features/questions/hooks/useSaveQuestion';
import { useCurrentUser } from 'core/features/user/store';
import {
  CompetenceDetails,
  OptionsDetails,
  QuestionForm,
} from 'core/features/questions/types/QuestionsDetails';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import BackButton from 'components/buttons/back-button';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import CustomizedRequiredMark from 'components/required-mark';
import { t } from 'core/resources/strings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import SelectTagType from 'components/select-tag-type';
import useAnalysisTypes from 'core/features/questions/hooks/useAnalysisTypes';
import Button from 'components/buttons/button';
import cosmoWithPencil from 'assets/images/cosmo-with-pencil.png';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import { currentLocale } from 'core/resources/strings/polyglot';
import SelectSkill from 'ui/pages/app/practices/pages/CreatePractice/components/selectSkill';
import SelectCompetence from 'components/modals/modal-tag/select-competence';
import useChatGPT from 'core/features/chatgpt/hooks/useChatGpt';
import useQuestionTypes from 'core/features/questions/hooks/useQuestionTypes';
import LoadingDots from 'components/V2/loading-dots';

const ageMarks: SliderSingleProps['marks'] = {
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
};

const letters = ['A', 'B', 'C', 'D', 'E'];

const marks: {
  [key: number]: {
    label: string;
    color: string;
  };
} = {
  0: {
    label: t('app.muitoBaixa'),
    color: '#B94143B2',
  },
  25: {
    label: t('app.baixa'),
    color: '#ED810FB2',
  },
  50: {
    label: t('app.moderada'),
    color: '#FFBC00B2',
  },
  75: {
    label: t('app.boa'),
    color: '#96BE9AB2',
  },
  100: {
    label: t('app.muitoBoa'),
    color: '#2A581EB2',
  },
};

const analysisTypes = [
  {
    id: 0,
    description:
      'O tipo de análise Conhecimento, em um contexto mais teórico, refere-se ao entendimento dos princípios e conceitos relacionados à educação socioemocional. Nesse sentido, é o nível de conhecimento sobre os componentes (competências, habilidades e temas) da educação socioemocional, sem necessariamente esta compreensão refletir seu Comportamento.',
  },
  {
    id: 1,
    name: 'O tipo de análise “Comportamento” descreve uma escolha feita em um momento específico, guiada pela interação entre a reflexão e a resposta imediata às demandas socioemocionais do ambiente. Essa decisão pode ocorrer tanto de maneira impulsiva quanto reflexiva, mas é influenciada pelo contexto social e história de vida da pessoa, assim como a habilidade em aplicar suas competências socioemocionais ou não em determinada situação.',
  },
  {
    id: 2,
    name: 'A Percepção refere-se à avaliação subjetiva que um indivíduo faz dos comportamentos e habilidades e competências socioemocionais sobre ele, outra pessoa, de um grupo, ou de seu ambiente como um todo, baseando-se em observações limitadas e na interação superficial. Essa análise muitas vezes carece do contexto completo e aprofundado necessário para uma compreensão abrangente das capacidades socioemocionais. A despeito, a Percepção externa pode se configurar como uma forma de mapear o envolvimento do estudante na escola e na família e em como ele vem desenvolvendo suas relações sociais.',
  },
];

interface GptResponse {
  text: string;
  options: {
    value: number;
    text: string;
  }[];
}
function CreateQuestionWithAi() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const [check] = useCheckPermissions();
  const { user } = useCurrentUser();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveQuestion();
  const { mutate, isLoading: isLoadingGenerate } = useChatGPT();
  const allAnalysisTypes = useAnalysisTypes();
  const environments = useEnvironments();
  const questionTypes = useQuestionTypes();
  const [competenceDetails, setCompetenceDetails] = useState<
    CompetenceDetails | undefined
  >();

  const [skillDetails, setSkillDetails] = useState<
    CompetenceDetails | undefined
  >();

  const [response, setResponse] = useState<GptResponse | undefined>();

  const methodology = Form.useWatch('methodology', form);
  const tagType = Form.useWatch('tagType', form);
  const analysisTypeId = Form.useWatch('analysisTypeId', form);

  const competence = Form.useWatch('competence', form);
  const { colorGreyBorderAndFont } = useU4heroColors();
  const options = Form.useWatch('options', form);

  const history = useHistory();
  const isU4hero = check([Permissions.U4hero.Questions.Create]);

  if (!isU4hero) {
    history.push('/errors/no-permission');
  }

  const handleOk = () => {
    history.push('/question');
  };

  const onSubmit = (values: QuestionForm) => {
    delete values.ageRange;
    if (!form.isFieldsTouched()) {
      notificateError(t('app.mensagemErroSalvarSemAlteracoes'));
      return;
    }
    if (!isOptionsValid(values.options)) {
      notificateError(t('app.mensagemValidacaoAlternativas'));
      return;
    }

    // @ts-ignore
    delete values.context;
    // @ts-ignore
    delete values.optionsCount;

    handleSave(
      {
        ...values,
        tenantId: user?.tenantId,
        authorId: user?.id,
        regenerateAudio: values.methodology === 1 ? true : undefined,
      },
      {
        onError: () => {
          notificateError(t('app.erroAoCriarPergunta'));
        },
        onSuccess: () => {
          notificateSucess(t('app.perguntaCriadaComSucesso'));
          handleOk();
        },
      }
    );
  };

  const isOptionsValid = (options: OptionsDetails[]) => {
    let has100 = false;
    let has0 = false;

    for (let option of options) {
      if (option.value === 100) {
        has100 = true;
      }
      if (option.value === 0) {
        has0 = true;
      }
      // If both values are found, we can short-circuit and return true
      if (has100 && has0) {
        return true;
      }
    }

    return has100 && has0;
  };

  const handleBack = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose: handleOk, colorPrimary, isMobile });
    } else {
      handleOk();
    }
  };

  const handleGenerateQuestion = (values: any) => {
    const promptCustom = `
    Se comporte como um educador especialista em educação socioemocional e escreva 1 quiz, com até {N_Alternativa} alternativas, com o objetivo de realizar o seguinte tipo de análise {ANALISE} da habilidade socioemocional {HABILIDADE}, que pertence a competência socioemocional {COMPETÊNCIA} no seguinte contexto {CONTEXTO} dentro do seguinte ambiente {AMBIENTE}. Utilize uma linguagem e vocabulário adequada a faixa etária {IDADE} para que a pessoa consiga responder de forma autônoma. Obedeça rigorosamente as {REGRAS}.


{COMPETÊNCIA}
> ${competenceDetails?.name['pt-br']}: ${
      competenceDetails?.description['pt-br']
    }


{HABILIDADE}
> ${skillDetails?.name['pt-br']}: ${skillDetails?.description['pt-br']}


{N_Alternativa}
> ${values.optionsCount}


{AMBIENTE}
> ${t(
      `app.${
        environments.data?.find(
          (env) => env.id === values.analysisEnvironmentId
        )?.name
      }`
    )}


{ANALISE}
> ${t(
      `app.${
        allAnalysisTypes?.data?.find(
          (item) => item.id === values.analysisTypeId
        )?.name
      }`
    )}: ${
      analysisTypes.find((item) => item.id === values.analysisTypeId)
        ?.description
    }


{PERFIL}
>Profissão: Estudante.
>Escola: Pública (Municipal).
>Nacionaldiade: Brasileira.

{IDADE}
>Faixa-etária: ${values.ageRange[0]} a ${values.ageRange[1]} anos.


{CONTEXTO}
> ${values.context}


{REGRAS}
> O quiz deve colocar o estudante como protagonista de uma situação-problema;
> Cada quiz deve deve possuir o enunciado com no máximo 199 caracteres;
> o nível de dificuldade, complexidade gramatical deve respeitar a faixa etária definida de {IDADE}
> o enunciado deve ser feito a partir do contexto: {CONTEXTO}
> a primeira alternativa deve ser a resposta considerada ideal (muito boa). A alternativa considerada ideal deve ser explícita em mostrar que o estudante entende e sabe qual é o certo a se fazer dentro do situação que você o colocou no enunciado. Respeitando apenas o conceito informado da habilidade socioemocional;
> a segunda alternativa deve ser a pior resposta possível (muito baixa). A alternativa considerada pior resposta possível deve ser explícita em mostrar que o estudante NÃO sabe qual é o certo a se fazer dentro do situação que você o colocou no enunciado ou faz o contrário da resposta considerada ideal. Respeitando apenas o conceito informado da habilidade socioemocional;
> a terceira deve ser uma alternativa mediana (entre a resposta ideal e pior) (moderada). a alternativa considerada resposta mediana deve ser explícita em mostrar que o estudante tem dúvidas sobre qual é o certo a se fazer dentro do situação que você o colocou no enunciado, faz algo que não é considerado errado e nem certo ou não sabe o que fazer. Respeitando apenas o conceito informado da habilidade socioemocional;
> a quarta alternativa deve ser uma alternativa ruim para péssima (baixa). Deve ser ruim ao ponto de descartar após uma reflexão, mas não imediatamente        
> organize as alternativas na seguinte ordem: muito boa, boa, moderada, baixa e muito baixa, onde muito boa vale 100, boa vale 75, moderada vale 50, baixa vale 25 e muito baixa vale 0;
> o conteúdo das alternativas devem estar adequados ao contexto presente no enunciado.
> as alternativas devem ser escritas obedecendo o vocabulário da faixa etária definida de {IDADE}
> cada alternativa deve possuir no máximo com no máximo 65 caracteres;
> se tratando de um pergunta que avalia o conhecimento, para a única alternativa considerada correta preciso de um feedback que explique de forma direta e objetiva a razão desta alternativa ser a correta ou incorreta, toda opção deve ter uma breve explicação com até 150 caracteres.

    `;
    const promptLikert = `
    Se comporte como um educador especialista em educação socioemocional e elabore um item para um instrumento de avaliação com alternativas em escala likert, com o objetivo de realizar o seguinte tipo de análise {ANALISE} da habilidade socioemocional {HABILIDADE}, que pertence a competência socioemocional {COMPETÊNCIA} no seguinte contexto {CONTEXTO} dentro do seguinte ambiente {AMBIENTE}. Utilize uma linguagem e vocabulário adequada a faixa etária {IDADE} para que a pessoa consiga responder de forma autônoma. Obedeça rigorosamente as {ESTRUTURA}. 

{ESTRUTURA}
> O enunciado deve ter o formato de afirmação positiva.
> O enunciado deve colocar o respondente nos seguintes {CONTEXTOS}.
> Devem ser criadas {Nº DE ALTERNATIVAS} em formato de escala likert.
> Enunciado e alternativas devem ser acessíveis a idade do {PERFIL}, de forma que ele seja capaz de responder sozinho.
> Enunciado e alternativas devem respeitar rigorosamente as {REGRAS DE ESCRITA}

{COMPETENCIA}
> ${competenceDetails?.name['pt-br']}: ${
      competenceDetails?.description['pt-br']
    }

{HABILIDADE}
> ${skillDetails?.name['pt-br']}: ${skillDetails?.description['pt-br']}

{Nº DE ALTERNATIVAS} 
> ${values.optionsCount}

{PERFIL}
> Faixa etária: ${values.ageRange[0]} a ${values.ageRange[1]} anos.
> Profissão: estudante (escola pública)
> Nacionalidade: brasileiro.

{AMBIENTE}
> ${t(
      `app.${
        environments.data?.find(
          (env) => env.id === values.analysisEnvironmentId
        )?.name
      }`
    )}

{CONTEXTO} 
> ${values.context}

{ANÁLISE}
> ${t(
      `app.${
        allAnalysisTypes?.data?.find(
          (item) => item.id === values.analysisTypeId
        )?.name
      }`
    )}: ${
      analysisTypes.find((item) => item.id === values.analysisTypeId)
        ?.description
    }

{REGRAS DE ESCRITA}
> Elabore um enunciado em formato de afirmação positiva.
> Use linguagem simples e acessível para crianças de até 6 anos: reescreva o enunciado de forma clara e simples, utilizando palavras e frases que as crianças possam entender facilmente. Evite jargões ou vocabulário complexo.
> Use frases curtas e simples: Mantenha as frases o mais curtas e simples possível. Evite construções gramaticais complicadas e use uma linguagem direta e fácil de entender.
> Evite negativas: Tente evitar o uso de negativas nas perguntas, pois elas podem causar confusão. Em vez de perguntar "Você não gosta de...", pergunte "Você gosta de...".
> Seja específico e concreto: Utilize palavras concretas e específicas ao fazer perguntas ou descrever situações. Isso ajuda as crianças a compreenderem exatamente sobre o que estão sendo questionadas.
> Use verbos de ação: Utilize verbos de ação que descrevam claramente a ação ou comportamento que está sendo perguntado. Isso torna as perguntas mais claras e diretas.
> Mantenha um tom positivo e encorajador: Utilize um tom positivo e encorajador ao formular as perguntas. Isso ajuda a criar um ambiente favorável para que as crianças se sintam à vontade para responder honestamente.
> Esteja atento à sensibilidade cultural: Ao escolher palavras e exemplos, esteja atento à sensibilidade cultural das crianças e de suas famílias. Evite usar linguagem ou exemplos que possam ser ofensivos ou desconfortáveis para determinados grupos culturais.
> Seja consistente: Mantenha uma linguagem consistente ao longo de todo o questionário, para evitar confusão e garantir que as crianças compreendam todas as perguntas da mesma maneira.
> Escreva em formato de afirmativa, para facilitar a leitura e compreensão dos estudantes.
> Atribua um valor para cada alternativa, onde 0 é a alternativa pior e 100 é a alternativa melhor. Usando incrementos de 25 em 25.
> Não me dê nenhum outro detalhe, apenas o enunciado com suas alternativas.

    `;

    const response_format = {
      type: 'json_schema',
      json_schema: {
        name: 'question_schema',
        schema: {
          type: 'object',
          properties: {
            text: {
              description: 'O enunciado da pergunta',
              type: 'string',
            },
            options: {
              description: 'As opções da pergunta',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  value: {
                    description: 'O valor de compreensão da opção',
                    type: 'number',
                  },
                  text: {
                    description: 'O texto da opção',
                    type: 'string',
                  },
                  ...(values.analysisTypeId === 0
                    ? {
                        feedback: {
                          description: 'O feedback da opção',
                          type: 'string',
                        },
                      }
                    : {}),
                },
              },
            },
          },
          additionalProperties: false,
        },
      },
    };

    mutate(
      {
        prompt: values.methodology === 1 ? promptLikert : promptCustom,
        response_format,
      },
      {
        onSuccess: (v: any) => {
          setResponse(JSON.parse(v.data));
        },
        onError: (err: any) => {
          notificateError(err?.response?.data?.details[0]?.message);
        },
      }
    );
  };

  const handleGenerateClick = async () => {
    try {
      const values = await form.validateFields([
        'context',
        'analysisEnvironmentId',
        'tagType',
        'competence',
        'skill',
        'ageRange',
        'methodology',
        'optionsCount',
        'analysisTypeId',
        'typeId',
      ]);
      handleGenerateQuestion(values);
    } catch (error) {}
  };

  const handleAgeChange = (values: number[]) => {
    form.setFieldsValue({
      minAge: values[0],
      maxAge: values[1],
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      text: response?.text,
      options: response?.options,
    });
  }, [response]);

  return (
    <Layout.Container>
      <Form
        onFinish={onSubmit}
        layout="vertical"
        form={form}
        initialValues={{
          competence: undefined,
          skill: undefined,
          analysisTypeId: 1,
          methodology: undefined,
          tagType: undefined,
          likertType: undefined,
          analysisEnvironmentId: 1,
          minAge: 5,
          maxAge: 10,
          ageRange: [5, 10],
          text: response?.text,
          image: undefined,
          audio: undefined,
          options: response?.options,
          typeId: undefined,
          privacyId: isU4hero ? 2 : 0,
          locale: currentLocale,
        }}
        requiredMark={CustomizedRequiredMark}
      >
        <Flex gap="23px" style={{ marginBottom: '16px' }}>
          <BackButton onClick={handleBack} />
          <Title primary>{t('app.gerarPerguntaComIA')}</Title>
        </Flex>

        <Flex vertical gap={16} style={{ marginBottom: '24px' }}>
          <Row
            gutter={[
              { xs: 16, md: 48 },
              { xs: 0, md: 0 },
            ]}
            align="stretch"
          >
            <Col xs={24} md={12}>
              <Flex vertical>
                <Text
                  size="md"
                  bold
                  style={{
                    backgroundColor: hex2rgba(colorPrimary, 0.7),
                    borderRadius: '20px 20px 0px 0px',
                    padding: '8px',
                    width: '100%',
                  }}
                  color="white"
                  align="center"
                >
                  {t('app.geracaoDaPergunta')}
                </Text>
                <Flex
                  vertical
                  style={{
                    borderRadius: '0px 0px 10px 10px',
                    padding: '16px 8px 16px 8px',
                    boxShadow:
                      '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <Form.Item
                    name="context"
                    label={t('app.contexto')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroContexto'),
                      },
                    ]}
                  >
                    <Input.TextArea
                      id="id-tour-create-question-with-ai-input-context"
                      placeholder={t('app.descrevaOContexto')}
                      rows={3}
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroTipoPergunta'),
                      },
                    ]}
                    name="typeId"
                    label={t('app.tipoPergunta')}
                  >
                    <Radio.Group>
                      <Flex
                        wrap={!isMobile ? 'wrap' : 'nowrap'}
                        vertical={isMobile}
                      >
                        {questionTypes?.data?.map((type) => (
                          <Radio value={type.id}>{type.name}</Radio>
                        ))}
                      </Flex>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroAmbienteAnalise'),
                      },
                    ]}
                    label={t('app.ambienteAnalise')}
                    name="analysisEnvironmentId"
                  >
                    <Radio.Group id="id-tour-question-creation-input-analysisEnvironment">
                      <Flex
                        wrap={!isMobile ? 'wrap' : 'nowrap'}
                        vertical={isMobile}
                      >
                        {environments?.data?.map((env) => (
                          <Radio value={env.id} key={env.id}>
                            {t(`app.${env.name}`)}
                          </Radio>
                        ))}
                      </Flex>
                    </Radio.Group>
                  </Form.Item>

                  <SelectTagType
                    id="id-tour-create-question-input-tag-type"
                    onChange={() => {
                      form.setFieldValue('skill', undefined);
                      form.setFieldValue('competence', undefined);
                    }}
                  />

                  <Row align="middle" gutter={[16, 0]}>
                    <Col span={isMobile ? 24 : 12}>
                      <Form.Item
                        label="Campo de Conhecimento:"
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, selecione um campo de conhecimento!',
                          },
                        ]}
                        name={['competence', 'id']}
                      >
                        {/* @ts-ignore */}
                        <SelectCompetence
                          onChange={(v, comp) => {
                            setCompetenceDetails(comp);
                          }}
                          disabled={tagType === undefined}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={isMobile ? 24 : 12}>
                      <Form.Item
                        label="Habilidade"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor, selecione uma habilidade!',
                          },
                        ]}
                        name={['skill', 'id']}
                      >
                        {/* @ts-ignore */}
                        <SelectSkill
                          parentId={competence?.id}
                          disabled={
                            competence === undefined || tagType === undefined
                          }
                          onChange={(v, skill) => {
                            setSkillDetails(skill);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item name="minAge" hidden />
                  <Form.Item name="maxAge" hidden />
                  <Form.Item name="privacyId" hidden />
                  <Form.Item name="locale" hidden />
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroFaixaEtaria'),
                      },
                    ]}
                    label={t('app.faixaEtaria')}
                    name={'ageRange'}
                  >
                    <Slider
                      id="id-tour-question-creation-input-ageRange"
                      min={5}
                      max={18}
                      range
                      marks={
                        isMobile ? { 5: '5', 12: '12', 18: '18' } : ageMarks
                      }
                      onChange={handleAgeChange}
                    />
                  </Form.Item>

                  <Flex justify="space-between" vertical={isMobile}>
                    <Form.Item
                      name="methodology"
                      label={t('app.formatoDaResposta')}
                      rules={[
                        {
                          required: true,
                          message: t('app.mensagemErroFormatoResposta'),
                        },
                      ]}
                    >
                      <Radio.Group
                        id="id-tour-question-creation-input-analysisType"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          if (e.target.value === 1) {
                            form.setFieldValue('analysisTypeId', 1);
                          }
                        }}
                      >
                        <Radio value={1}>{t('app.likert')}</Radio>
                        <Radio value={0}>{t('app.customizadas')}</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      name="optionsCount"
                      label={t('app.qtdDeAlternativas')}
                      rules={[
                        {
                          required: true,
                          message: t('app.mensagemErroQtdAlternativas'),
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: 3,
                            label: '3',
                          },
                          {
                            value: 4,
                            label: '4',
                          },
                          {
                            value: 5,
                            label: '5',
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>

                  <Form.Item
                    label={t('app.tipoAnalise')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroTipoAnalise'),
                      },
                    ]}
                    name="analysisTypeId"
                  >
                    <Radio.Group id="id-tour-question-creation-input-analysisType">
                      <Flex
                        wrap={!isMobile ? 'wrap' : 'nowrap'}
                        vertical={isMobile}
                      >
                        {allAnalysisTypes?.data?.map((type) => (
                          <Radio
                            value={type.id}
                            key={type.id}
                            disabled={methodology === 1 && type.id === 0}
                          >
                            {t(`app.${type.name}`)}
                          </Radio>
                        ))}
                      </Flex>
                    </Radio.Group>
                  </Form.Item>
                  <Button
                    size="md"
                    style={{ justifyContent: 'center', alignSelf: 'center' }}
                    onClick={handleGenerateClick}
                    htmlType="button"
                    id="generate-button"
                    loading={isLoadingGenerate}
                  >
                    {t('app.gerar')}
                  </Button>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} md={12}>
              <Flex vertical style={{ height: '100%' }}>
                <Text
                  size="md"
                  bold
                  style={{
                    backgroundColor: hex2rgba(colorPrimary, 0.7),
                    borderRadius: '20px 20px 0px 0px',
                    padding: '8px',
                    width: '100%',
                  }}
                  color="white"
                  align="center"
                >
                  {t('app.perguntaGerada')}
                </Text>
                <Flex
                  vertical
                  style={{
                    borderRadius: '0px 0px 10px 10px',
                    padding: '16px 8px 16px 8px',
                    boxShadow:
                      '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    height: '100%',
                  }}
                  gap={8}
                >
                  {response !== undefined ? (
                    <>
                      <Text>
                        Pergunta gerada! Sinta-se à vontade para ajustar:
                      </Text>

                      <Form.Item
                        name="text"
                        label={''}
                        rules={[
                          {
                            required: true,
                            message: t('app.mensagemErroEnunciado'),
                          },
                        ]}
                      >
                        <Input.TextArea
                          style={{
                            border: '0.5px dashed #262626',
                            borderRadius: '8px',
                            minWidth: '100%',
                          }}
                          id="id-tour-create-question-with-ai-input-question"
                          rows={4}
                        />
                      </Form.Item>

                      <Form.List name="options">
                        {(fields) => (
                          <Flex vertical style={{ width: '100%' }} gap={16}>
                            {fields.map((field, index) => (
                              <Flex
                                key={field.key}
                                gap="8px"
                                align="start"
                                style={{ width: '100%' }}
                              >
                                <Text
                                  style={{
                                    background: hex2rgba(colorPrimary, 0.5),
                                    borderRadius: '8px',
                                    padding: '5px 12px',
                                    color: 'white',
                                  }}
                                  size="lg"
                                >
                                  {letters[index]}
                                </Text>
                                <Flex vertical style={{ width: '100%' }}>
                                  <Form.Item
                                    name={[index, 'text']}
                                    style={{
                                      width: '100%',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    <Input.TextArea
                                      rows={2}
                                      style={{
                                        border: '0.5px dashed #262626',
                                        borderRadius: '8px',
                                        minWidth: '100%',
                                      }}
                                    />
                                  </Form.Item>

                                  {analysisTypeId === 0 && (
                                    <Form.Item
                                      name={[index, 'feedback']}
                                      style={{
                                        width: '100%',
                                        marginBottom: '0px',
                                        marginTop: '8px',
                                      }}
                                    >
                                      <Input.TextArea
                                        rows={2}
                                        style={{
                                          border: '0.5px dashed #262626',
                                          borderRadius: '8px',
                                          minWidth: '100%',
                                        }}
                                      />
                                    </Form.Item>
                                  )}

                                  <Text
                                    color={
                                      marks[options?.[index]?.value as number]
                                        ?.color
                                    }
                                    style={{
                                      borderRadius: '0px 0px 8px 8px',
                                      border: `1px dashed ${
                                        marks[options?.[index]?.value as number]
                                          ?.color
                                      }`,
                                      padding: '4px 16px',
                                    }}
                                    align="center"
                                  >
                                    <strong>
                                      {t('app.grauDeCompreensao')}:{' '}
                                    </strong>
                                    {
                                      marks[options?.[index]?.value as number]
                                        ?.label
                                    }
                                  </Text>

                                  <Form.Item name={[index, 'value']} hidden />
                                </Flex>
                              </Flex>
                            ))}
                          </Flex>
                        )}
                      </Form.List>
                    </>
                  ) : (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      style={{ height: '100%' }}
                      gap={16}
                    >
                      {isLoadingGenerate ? (
                        <>
                          <LoadingDots />
                          <Text size="md" color={colorGreyBorderAndFont}>
                            {t('app.aguardePerguntaGerada')}
                          </Text>
                        </>
                      ) : (
                        <>
                          <img src={cosmoWithPencil} width={143} />
                          <Text size="md" color={colorGreyBorderAndFont}>
                            {t('app.aguardeSuaPerguntaSerExibida')}
                          </Text>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              onClick={handleBack}
              id="id-tour-create-question-button-cancel"
            >
              {t('app.cancelar')}
            </Button>

            <Button
              htmlType="submit"
              size="md"
              id="id-tour-create-question-button-save"
              loading={isLoadingSave}
            >
              {t('app.salvar')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default CreateQuestionWithAi;
