import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Progress } from 'antd';
import Text from 'components/typography/text';

export default function AutoAvaliationCard({
  background,
  borderColor,
  image,
  percentage,
  competence,
}: {
  background: string;
  borderColor: string;
  image: string;
  percentage: number;
  competence: string;
}) {
  return (
    <Flex
      style={{
        borderRadius: '10px',
        borderRight: `3px solid ${borderColor}`,
        borderBottom: `4px solid ${borderColor}`,
        background: background,
        width: '100%',
        height: '145px',
        position: 'relative',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Flex style={{ flex: 1 }}>
        <Flex
          style={{
            width: '145px',
            height: '100%',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
          }}
        >
          <img
            src={image}
            alt={competence}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Flex>

        <Flex
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '16px',
          }}
        >
          <Progress
            size={80}
            type="circle"
            percent={percentage}
            strokeColor="white"
            format={(percent) => (
              <span style={{ color: 'white', fontWeight: 700 }}>
                {percent}%
              </span>
            )}
          />
        </Flex>
      </Flex>

      <Flex
        style={{
          width: '100%',
          padding: '8px 16px',
          background: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <Text
          size="sm"
          style={{
            color: 'white',
            textTransform: 'uppercase',
            position: 'absolute',
            fontSize: '14px',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: borderColor,
            padding: '4px',
            borderRadius: '5px',
            minWidth: 'fit-content',
            whiteSpace: 'nowrap',
          }}
        >
          {competence}
        </Text>
      </Flex>
    </Flex>
  );
}
