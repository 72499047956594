import { useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  Typography,
  DatePicker as DatePickerAntd,
  Menu,
  Dropdown,
  Row,
  Col,
  Image,
  Spin,
  Checkbox,
} from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component/index';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import useEmotionalStats from 'core/features/graphs/hooks/useEmotionalGraphV2';
import { useParams } from 'react-router-dom';
import Table from 'components/V2/table';
import useEmotions from 'core/features/graphs/hooks/useEmotions';
import { FilterOutlined, RightOutlined } from '@ant-design/icons';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { t } from 'core/resources/strings';
import SadIcon from 'components/icons/SadIcon';
import dayjs from 'dayjs';
import ComparativeMode from 'components/comparativeMode';
import { ComparativeItem, emotions } from 'infra/helpers/types';
import emptyEmotionometer from 'assets/images/white-label/empty-emotionometer.png';
import { useCurrentUser } from 'core/features/user/store';
import { Doughnut } from 'react-chartjs-2';
import { currentLocale } from 'core/resources/strings/polyglot';

const { Text } = Typography;
const { RangePicker } = DatePickerAntd;

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function Emotionometer({
  serieId,
  allowCompare,
  className,
}: {
  serieId?: string;
  allowCompare?: boolean;
  className?: string;
}) {
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyCard } = useU4heroColors();
  const { id, classId, tenantId } = useParams<{
    id: string;
    tenantId: string;
    classId: string;
  }>();
  const [page, setPage] = useState(1);
  const [optionIds, setOptionIds] = useState<number[]>([]);
  const { user } = useCurrentUser();
  const [comparative, setComparative] = useState<ComparativeItem>({
    type: 'student',
    id: '',
    name: '',
  });

  const { data, isLoading: isLoadingStats } = useEmotionalStats({
    userId: id,
    classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    disableGreedy: true,
    optionIds,
    tenantId,
  });

  const { data: data2, isLoading: isLoadingStats2 } = useEmotionalStats({
    userId: comparative.type === 'student' ? comparative.id : undefined,
    classId: comparative.type === 'class' ? comparative.id : classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    disableGreedy: true,
    optionIds,
    tenantId,
    disable: comparative.id === '',
  });

  const { data: emotionsData, isLoading: isLoadingEmotions } = useEmotions({
    page: page,
    pageSize: 5,
    userId: id,
    classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    optionIds,
    tenantId,
  });

  const isLoading = isLoadingStats || isLoadingEmotions;

  const totalCount = data?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );
  const totalCount2 = data2?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );

  const chartOptions = {
    responsive: true,
    cutout: '60%',
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetIndex = context.datasetIndex;
            const value = context.raw;

            const label = context.dataset.labels[context.dataIndex];

            if (value === 0) return '';
            return `${label}: ${value.toFixed(1)}%`;
          },
        },
      },
      datalabels: {
        formatter: (value: number, ctx: any) => {
          if (value === 0) return '';
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: number) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + '%';
          return percentage;
        },
        color: '#fff',
      },
    },
  };

  const labels = emotions.map((c) => c.name);
  const innerLabels = emotions.flatMap((c) =>
    Array(5)
      .fill(c.name)
      .map((name, i) => `${c.intensities[i].text[currentLocale]}`)
  );

  const parsedData = {
    labels: labels,
    datasets: [
      {
        data: data?.data?.map((item) => item.percent),
        backgroundColor: emotions.map((c) => c.color),
        borderWidth: 0,
        weight: 1,
        labels: labels,
      },
      {
        data: data?.data?.flatMap((item) =>
          item.intensities.map((intensity) => intensity.percent)
        ),
        labels: innerLabels,
        backgroundColor: emotions.flatMap((c) =>
          Array(5)
            .fill(c.color)
            .map((color, i) => `${color}${50 + Math.round(i * 10)}`)
        ),
        borderWidth: 0,
        weight: 0.6,
      },
    ],
  };
  const parsedData2 = {
    labels: labels,
    datasets: [
      {
        data: data2?.data?.map((item) => item.percent),
        backgroundColor: emotions.map((c) => c.color),
        borderWidth: 0,
        weight: 1,
        labels: labels,
      },
      {
        data: data2?.data?.flatMap((item) =>
          item.intensities.map((intensity) => intensity.percent)
        ),
        labels: innerLabels,
        backgroundColor: emotions.flatMap((c) =>
          Array(5)
            .fill(c.color)
            .map((color, i) => `${color}${50 + Math.round(i * 10)}`)
        ),
        borderWidth: 0,
        weight: 0.6,
      },
    ],
  };
  const toggleOptions = (optionId: number) => {
    if (optionIds.includes(optionId)) {
      setOptionIds((prev) => prev.filter((key) => key !== optionId));
    } else {
      setOptionIds((prev) => [...prev, optionId]);
    }
  };
  return (
    <Flex vertical>
      <Flex
        align="center"
        justify="end"
        gap={24}
        style={{ marginBottom: isMobile ? '8px' : '18px' }}
      >
        <RangePicker
          style={{ height: '34px' }}
          format="DD/MM/YYYY"
          separator={<HiOutlineArrowNarrowRight />}
          suffixIcon={<AiOutlineCalendar color="black" />}
          value={date}
          onChange={(v) => setDate(v)}
        />
        {allowCompare && (
          <ComparativeMode
            classId={classId}
            serieId={serieId}
            value={comparative}
            tenantId={tenantId}
            onChange={(v) => setComparative(v)}
          />
        )}
      </Flex>
      {!isLoading ? (
        <GenericCard>
          <Row style={{ width: '100%' }} gutter={isMobile ? [0, 0] : [50, 0]}>
            <Col span={comparative.id !== '' ? 12 : 24}>
              <Flex justify="space-between">
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: isMobile ? '18px' : '24px',
                    fontWeight: 700,
                  }}
                >
                  {comparative.id !== ''
                    ? `${t('app.emocionometro')} - ${t(
                        'app.turma'
                      )}: ${className}`
                    : t('app.emocionometro')}
                </Text>
              </Flex>
              {isLoadingStats ? (
                <Spin size="large" />
              ) : (
                <div>
                  {totalCount === 0 ? (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      gap="16px"
                      style={{ height: '100%' }}
                    >
                      <SadIcon color="#7B7B7B" size={60} />
                      <p
                        style={{
                          fontSize: '14px',
                          color: '#7B7B7B',
                          textAlign: 'center',
                          fontWeight: 400,
                          maxWidth: '267px',
                        }}
                      >
                        {t('app.emocionometroNaoPreenchido')}
                      </p>
                    </Flex>
                  ) : (
                    <Flex
                      align="center"
                      justify="center"
                      gap={'40px'}
                      vertical={isMobile}
                    >
                      <div style={{ width: '220px', height: '220px' }}>
                        <Doughnut
                          data={parsedData}
                          options={chartOptions}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          id="id-tour-emotionometer-graph"
                        />
                      </div>
                      <Flex
                        wrap={'wrap'}
                        gap="16px"
                        justify="center"
                        vertical={!isMobile}
                      >
                        {emotions.map((item, index) => (
                          <Flex gap="8px" key={index} align="center">
                            <img
                              alt={item.name}
                              src={item.img}
                              width={isMobile ? '24px' : '30px'}
                            />
                            <p
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                                textAlign: 'right',
                              }}
                            >
                              {item.name} (
                              {item.intensities
                                .map((i) => i.text[currentLocale])
                                .join(', ')}
                              )
                            </p>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </div>
              )}
            </Col>

            <Col span={comparative.id === '' ? 0 : isMobile ? 24 : 12}>
              <Flex justify="space-between">
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: isMobile ? '18px' : '24px',
                    fontWeight: 700,
                  }}
                >
                  {t('app.emocionometro')} -{' '}
                  {comparative.type === 'student'
                    ? t('app.estudante') + ': '
                    : t('app.turma') + ': '}
                  {comparative.name}
                </Text>
              </Flex>
              {isLoadingStats ? (
                <Spin size="large" />
              ) : (
                <div>
                  {totalCount2 === 0 ? (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      gap="16px"
                      style={{ height: '100%' }}
                    >
                      {user?.whiteLabel ? (
                        <img src={emptyEmotionometer} width={'170px'} />
                      ) : (
                        <SadIcon color="#7B7B7B" size={60} />
                      )}
                      <p
                        style={{
                          fontSize: '14px',
                          color: '#7B7B7B',
                          textAlign: 'center',
                          fontWeight: 400,
                          maxWidth: '267px',
                        }}
                      >
                        {t('app.emocionometroNaoPreenchido')}
                      </p>
                    </Flex>
                  ) : (
                    <Flex align="center" justify="center" vertical>
                      <div style={{ width: '220px', height: '220px' }}>
                        <Doughnut
                          data={parsedData2}
                          options={chartOptions}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          id="id-tour-emotionometer-graph"
                        />
                      </div>
                      <Flex wrap={'wrap'} gap="16px" justify="center">
                        {emotions.map((item, index) => (
                          <Flex gap="8px" key={index} align="center">
                            <img
                              alt={item.name}
                              src={item.img}
                              width={isMobile ? '24px' : '30px'}
                            />
                            <p
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                                textAlign: 'right',
                              }}
                            >
                              {item.name}
                            </p>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </div>
              )}
            </Col>

            <Col
              span={comparative.id !== '' ? 0 : 24}
              style={{ width: '100%' }}
            >
              <Table
                className="small-gap-table"
                noMargin={true}
                itemName=""
                showTotal={false}
                onChangePage={setPage}
                total={emotionsData?.total ?? 0}
                page={page}
                pageSize={5}
                isLoading={isLoadingEmotions}
                shadow={false}
                title={() => (
                  <Flex justify="space-between">
                    <Text
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '18px' : '24px',
                        fontWeight: 700,
                        fontFamily: 'Lato',
                      }}
                    >
                      {t('app.historicoDetalhado')}
                    </Text>
                    <Dropdown
                      overlay={
                        <Menu
                          onClick={(e) => {
                            toggleOptions(Number(e.key));
                          }}
                        >
                          {emotions.map((item) => (
                            <Menu.Item key={item.id}>
                              <Checkbox
                                checked={optionIds?.includes(Number(item.id))}
                              >
                                {item.name}
                              </Checkbox>
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Flex
                        gap="8px"
                        align="center"
                        style={{
                          background: '#F5F5F5',
                          padding: '8px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                      >
                        <FilterOutlined
                          style={{
                            color: 'black',
                            fontSize: '20px',
                            marginRight: '8px',
                          }}
                        />
                        <RightOutlined
                          style={{ fontSize: '10px', color: 'black' }}
                        />
                      </Flex>
                    </Dropdown>
                  </Flex>
                )}
                columns={[
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {t('app.emocao')}
                      </p>
                    ),
                    dataIndex: 'emotion',
                    key: 'emotion',
                    render: (url: string, record: any) => (
                      <Flex gap={8} align="center">
                        <Image
                          src={record.image}
                          width={isMobile ? 24 : 34}
                          height={isMobile ? 24 : 34}
                          preview={false}
                        />
                        <Text>
                          {record.name} -{' '}
                          {
                            emotions
                              .find((e) => e.id === record.optionId)
                              ?.intensities.find(
                                (i) => i.id === record.intensity
                              )?.text[currentLocale]
                          }
                        </Text>
                      </Flex>
                    ),
                  },
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {t('app.registro')}
                      </p>
                    ),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (createdAt: string) => (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {dayjs(createdAt).format('DD/MM/YYYY HH[h]mm')}
                      </p>
                    ),
                  },
                ]}
                data={
                  emotionsData?.data.map((item) => ({
                    ...item,
                    name: emotions.find((e) => e.id === item.optionId)?.name,
                    image: emotions.find((e) => e.id === item.optionId)?.img,
                  })) ?? []
                }
              />{' '}
            </Col>
          </Row>
        </GenericCard>
      ) : (
        <GenericCard width="80vw" height="30vh">
          <Flex justify="center" align="center" style={{ width: '100%' }}>
            <Spin size={'large'} />
          </Flex>
        </GenericCard>
      )}
    </Flex>
  );
}

export default Emotionometer;
