import { Divider, Flex, Card, Row, Col } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import rightDecoration from 'assets/images/report/report-decoration-top-right.png';
import leftDecoration from 'assets/images/report/report-decoration-top-left.png';
import lightbulb from 'assets/images/report/lightbulb.png';
import chair from 'assets/images/report/chair.png';
import boy from 'assets/images/report/boy.png';
import girl from 'assets/images/report/girl.png';
import graduationHat from 'assets/images/report/graduation-hat.png';
import questionMark from 'assets/images/report/question-mark.png';
import bookAndPen from 'assets/images/report/book-and-pen.png';
import eyes from 'assets/images/report/eyes.png';
import heart from 'assets/images/report/heart.png';
import bomb from 'assets/images/report/bomb.png';
import yellowWarning from 'assets/images/report/yellow-warning.png';
import ReportPage from 'ui/pages/app/indicators/components/new-report/components/page';
import dayjs from 'dayjs';
import bad from 'assets/images/sad.png';
import good from 'assets/images/happy.png';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import verybad from 'assets/images/angry.png';
import behaviour from 'assets/images/report/behaviour-icon.png';
import knowledge from 'assets/images/report/knowledge-icon.png';
import perception from 'assets/images/report/perception-icon.png';

import { t } from 'core/resources/strings';
import React from 'react';
import { ActivityTypes } from 'core/resources/enums/activityTypes';
import SubTitle from 'components/typography/subTitle';
import { ReportResult } from 'core/features/report/types';
import { currentLocale } from 'core/resources/strings/polyglot';
import { avaliationIcons } from 'ui/pages/app/indicators/components/new-report/components/data-info';
import { useCurrentUser } from 'core/features/user/store';
import { emotions } from 'infra/helpers/types';

interface Competence {
  label?: string;
  value?: string;
  message?: string;
  id?: string;
}

interface Knowledge {
  label: string;
  image: string;
  color: string;
  analysisTypeId: number;
  competences: Competence[];
}

interface CompetenceColumnProps {
  competence: Competence;
  color: string;
  icon: number;
  validation: number;
  allValues: Array<{
    value: number;
    analysisTypeId: number;
    competenceId?: string;
  }>;
}

interface KnowledgeSectionProps {
  knowledge: Knowledge;
  allValues: Array<{
    value: number;
    analysisTypeId: number;
    competenceId?: string;
  }>;
}

const mapCompetenceResults = (
  results: ReportResult[],
  analysisTypeId: number
) => {
  return results.map((result) => {
    const competence = result.question.questionTags.find(
      (item) => item.tag.parentId === null
    )?.tag;

    const skill = result.question.questionTags.find(
      (item) => item.tag.parentId === competence?.id
    )?.tag;

    const value = result.option.totalValue / result.totalCount;
    const reportMessage = competence?.reportMessages?.ranges.find(
      (item) =>
        item.analysisTypeId === analysisTypeId &&
        item.min <= value &&
        item.max >= value
    );

    const replacedMessage = reportMessage?.message[currentLocale].replace(
      '[porcentagem]',
      `<strong>${value.toFixed(1)}%</strong>`
    );

    return {
      label: competence?.name[currentLocale],
      skill: skill?.name[currentLocale],
      value: value.toFixed(1),
      message: replacedMessage,
      id: competence?.id,
    };
  });
};

const CompetenceColumn = ({
  competence,
  color,
  icon,
  validation,
  allValues,
}: CompetenceColumnProps) => (
  <Col
    span={12}
    style={{
      borderBottom: `1px solid ${color}`,
      borderRight: icon % 2 === 0 ? `1px solid ${color}` : 'none',
      padding: '16px',
    }}
  >
    <Flex vertical gap={'8px'}>
      <Text size="md" bold color={color}>
        {competence.label} - {competence.value}%
      </Text>
      {avaliationIcons.map(
        (icon, index) =>
          icon.validation(
            { value: Number(competence.value), analysisTypeId: index },
            allValues
          ) && (
            <Flex
              key={index}
              gap="4px"
              align="center"
              justify="center"
              style={{
                borderRadius: '5px',
                background: '#E9E9E9',
                fontSize: '12px',
                width: 'fit-content',
                padding: '4px 8px',
              }}
            >
              <img src={icon.image} alt={icon.title} width={16} />
              {icon.title.replace(':', '')}
            </Flex>
          )
      )}
    </Flex>
  </Col>
);

const KnowledgeSection = ({ knowledge, allValues }: KnowledgeSectionProps) => (
  <Flex
    style={{
      width: '100%',
      borderRadius: '0px 0px 10px 10px',
      border: `2px solid ${knowledge.color}`,
      position: 'relative',
      padding: '12px',
      marginTop: '40px',
    }}
  >
    <Flex vertical style={{ width: '100%' }}>
      <Flex
        gap="12px"
        align="center"
        justify="center"
        style={{
          marginBottom: '-40px',
          position: 'relative',
          top: '-58px',
          width: '100%',
        }}
        vertical
      >
        <img src={knowledge.image} alt={knowledge.label} width={80} />
        <Text size="lg" color={knowledge.color} bold>
          {knowledge.label}
        </Text>
      </Flex>
      <Row>
        {knowledge.competences.map((competence, idx) => (
          <CompetenceColumn
            key={idx}
            competence={competence}
            color={knowledge.color}
            icon={idx}
            validation={knowledge.analysisTypeId}
            allValues={allValues}
          />
        ))}
      </Row>
    </Flex>
  </Flex>
);

export default function ReportDataAnalysis({
  typeId,
  countStudents,
  countClasses,
  countAnswers,
  countQuestions,
  countGirls,
  countBoys,
  countFlag1,
  countFlag2,
  countFlag3,
  countFlag4,
  predominantEmotion,
  results,
  name,
  modelType,
  isStudent,
  startPage = 1,
}: {
  typeId: ActivityTypes;
  countStudents: number;
  countClasses: number;
  countAnswers: number;
  countQuestions: number;
  countGirls: number;
  countBoys: number;
  countFlag1: number;
  countFlag2: number;
  countFlag3: number;
  countFlag4: number;
  predominantEmotion: {
    optionId: number;
    count: number;
  };
  results: {
    [key: number]: ReportResult[];
  };
  name: string;
  modelType?: 'complete' | 'simplified';
  isStudent?: boolean;
  startPage?: number;
}) {
  const { colorPrimary, colorSecondary, colorGreyBorderAndFont } =
    useU4heroColors();
  const { user } = useCurrentUser();

  const formattedDate = dayjs().format('DD [de] MMMM [de] YYYY');

  const items = [
    {
      label: t('app.turmas'),
      value: countClasses,
      image: chair,
    },
    {
      label:
        t('app.estudantes').charAt(0).toUpperCase() +
        t('app.estudantes').slice(1),
      value: countStudents,
      image: graduationHat,
    },
    {
      label: t('app.meninas'),
      value: countGirls,
      image: girl,
    },
    {
      label: t('app.meninos'),
      value: countBoys,
      image: boy,
    },
    {
      label: t('app.respostas'),
      value: countAnswers,
      image: bookAndPen,
    },
    {
      label: t('app.perguntas'),
      value: countQuestions,
      image: questionMark,
    },
  ];

  const flags = [
    {
      label: t('app.coracaoQuentinho'),
      img: heart,
      value: countFlag1,
    },
    {
      label: t('app.bomFicarDeOlho'),
      img: eyes,
      value: countFlag2,
    },
    {
      label: t('app.redobreAtencao'),
      img: yellowWarning,
      value: countFlag3,
    },
    {
      label: t('app.problemaSerio'),
      img: bomb,
      value: countFlag4,
    },
  ];

  const activityName =
    typeId === ActivityTypes.SelfAvaliation
      ? t('app.selfAvaliation')
      : typeId === ActivityTypes.Avaliation ||
        typeId === ActivityTypes.IndividualAvaliation
      ? t('app.avaliation')
      : t('app.activity');

  const knowledges = [
    {
      label: t('app.conhecimento'),
      image: knowledge,
      color: '#1565C0',
      analysisTypeId: 0,
      competences: mapCompetenceResults(results[0], 0),
    },
    {
      label: t('app.percepcao'),
      image: perception,
      color: '#478F46',
      analysisTypeId: 2,
      competences: mapCompetenceResults(results[2], 2),
    },
    {
      label: t('app.comportamento'),
      image: behaviour,
      color: '#ED810F',
      analysisTypeId: 1,
      competences: mapCompetenceResults(results[1], 1),
    },
  ];

  const emotion = emotions.find(
    (emotion) => emotion.id === predominantEmotion.optionId
  );

  const allValues = knowledges.flatMap((knowledge) =>
    knowledge.competences.map((competence) => ({
      value: Number(competence.value),
      analysisTypeId: knowledge.analysisTypeId,
      competenceId: competence.id,
    }))
  );

  const getClassification = (value: number) => {
    if (value >= 89.9) {
      return 'Excelente';
    } else if (value > 79.9 && value < 89.9) {
      return 'Bom';
    } else if (value > 59.9 && value < 79.9) {
      return 'Espaço para melhoria';
    } else {
      return 'Precisa de atenção';
    }
  };

  const getConclusionText = () => {
    let text = `${t('app.osDadosGeraisDa')} <strong>${name}</strong> ${t(
      'app.mostramQueOsEstudantes'
    )}:<br/><br/> `;

    const competenciesByLevel = knowledges.flatMap((knowledge) =>
      knowledge.competences.map((competence) => ({
        competenceName: competence.label,
        value: Number(competence.value),
        analysisType: knowledge.label,
        classification: getClassification(Number(competence.value)),
      }))
    );

    const groupCompetenciesByAnalysisType = (
      competencies: typeof competenciesByLevel
    ) => {
      return competencies.reduce((acc, comp) => {
        if (!acc[comp.analysisType]) {
          acc[comp.analysisType] = [];
        }
        acc[comp.analysisType].push(comp.competenceName!);
        return acc;
      }, {} as Record<string, string[]>);
    };

    const excellentCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Excelente'
    );
    if (excellentCompetencies.length > 0) {
      const groupedByType = groupCompetenciesByAnalysisType(
        excellentCompetencies
      );

      Object.entries(groupedByType).forEach(
        ([analysisType, competencies], typeIndex) => {
          text += `${t(
            'app.temUmExcelente'
          )} <strong>${analysisType.toLowerCase()}</strong> ${t(
            'app.sobre'
          )} <strong>${competencies.join(` ${t('app.e')} `)}</strong>`;
          if (typeIndex < Object.keys(groupedByType).length - 1) {
            text += ` ${t('app.e')} `;
          }
        }
      );
      text += '.<br/><br/>';
    }

    const goodCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Bom'
    );
    if (goodCompetencies.length > 0) {
      const groupedByType = groupCompetenciesByAnalysisType(goodCompetencies);

      text += `${t('app.bonsResultadosAvaliarmos')} `;
      Object.entries(groupedByType).forEach(
        ([analysisType, competencies], typeIndex) => {
          text += `${t(
            'app.o'
          )} <strong>${analysisType.toLowerCase()}</strong> ${t(
            'app.em'
          )} <strong>${competencies.join(` ${t('app.e')} `)}</strong>`;
          if (typeIndex < Object.keys(groupedByType).length - 1) {
            text += ` ${t('app.e')} `;
          }
        }
      );
      text += '.<br/><br/>';
    }

    const improvementCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Espaço para melhoria'
    );
    if (improvementCompetencies.length > 0) {
      const groupedByType = groupCompetenciesByAnalysisType(
        improvementCompetencies
      );

      text += t('app.possuemEspacoParaMelhoriaEmAlgumasCompetencias') + ':';
      Object.entries(groupedByType).forEach(([analysisType, competencies]) => {
        text += `<br/><strong>${analysisType}</strong> em <strong>${competencies.join(
          ` ${t('app.e')} `
        )}</strong>`;
      });
      text += '<br/><br/>';
    }

    const attentionCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Precisa de atenção'
    );
    if (attentionCompetencies.length > 0) {
      const groupedByType = groupCompetenciesByAnalysisType(
        attentionCompetencies
      );

      text += t('app.asCompetenciasQueApresentamMaiorDesafio') + ':';
      Object.entries(groupedByType).forEach(([analysisType, competencies]) => {
        text += `<br/><strong>${analysisType}</strong> ${t(
          'app.em'
        )} <strong>${competencies.join(` ${t('app.e')} `)}</strong>`;
      });
      text += '<br/><br/>';
    }

    if (
      improvementCompetencies.length > 0 ||
      attentionCompetencies.length > 0
    ) {
      const focusCompetencies = [
        ...improvementCompetencies,
        ...attentionCompetencies,
      ];
      const groupedByType = groupCompetenciesByAnalysisType(focusCompetencies);

      text += `${t(
        'app.essaAnaliseSugereQueAsProximasAtividadesETrilhasDeDesenvolvimentoDevemFocarEmFortalecer'
      )} `;

      const suggestions = Object.entries(groupedByType).map(
        ([analysisType, competencies]) =>
          `<strong>${analysisType.toLowerCase()}</strong> ${t(
            'app.relacionadoA'
          )} <strong>${competencies.join(` ${t('app.e')} `)}</strong>`
      );

      text += suggestions.join(', ');
      text += '.';
    }

    return text;
  };

  const getActivityConclusionText = () => {
    let text = `${t('app.osDadosGeraisDa')} <strong>${name}</strong> ${t(
      'app.mostramQueOsEstudantes'
    )}:<br/><br/> `;

    const competenciesByLevel = knowledges.flatMap((knowledge) =>
      knowledge.competences.map((competence) => ({
        competenceName: competence.label,
        skillName: competence.skill,
        value: Number(competence.value),
        analysisType: knowledge.label,
        classification: getClassification(Number(competence.value)),
      }))
    );

    const excellentCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Excelente'
    );
    if (excellentCompetencies.length > 0) {
      excellentCompetencies.forEach((comp, index) => {
        text += `${t(
          'app.temUmExcelente'
        )} ${comp.analysisType.toLowerCase()} ${t('app.sobre')} ${t(
          'app.aHabilidade'
        )} <strong>${comp.skillName}</strong>, ${t('app.relacionadoA')} ${t(
          'app.competencia'
        )} <strong>${comp.competenceName}</strong>`;
        if (index < excellentCompetencies.length - 1) {
          text += ` ${t('app.e')} `;
        }
      });
      text += '.<br/><br/>';
    }

    const goodCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Bom'
    );
    if (goodCompetencies.length > 0) {
      goodCompetencies.forEach((comp, index) => {
        text += `${t(
          'app.bomResultadoAoRealizarAAtividadeParaDesenvolver'
        )} ${comp.analysisType.toLowerCase()} ${t(
          'app.naHabilidade'
        )} <strong>${comp.skillName}</strong>, ${t('app.relacionadoA')} ${t(
          'app.competencia'
        )} <strong>${comp.competenceName}</strong>`;
        if (index < goodCompetencies.length - 1) {
          text += ` ${t('app.e')} `;
        }
      });
      text += '.<br/><br/>';
    }

    const improvementCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Espaço para melhoria'
    );
    if (improvementCompetencies.length > 0) {
      improvementCompetencies.forEach((comp, index) => {
        text += `${t(
          'app.possuemEspacoParaMelhoriaAoRealizarAAtividadeParaDesenvolver'
        )} <strong>${comp.analysisType.toLowerCase()}</strong> ${t(
          'app.daHabilidade'
        )} <strong>${comp.skillName}</strong>, ${t('app.relacionadoA')} ${t(
          'app.competencia'
        )} <strong>${comp.competenceName}</strong>`;
        if (index < improvementCompetencies.length - 1) {
          text += ` ${t('app.e')} `;
        }
      });
      text += '.<br/><br/>';
    }

    const attentionCompetencies = competenciesByLevel.filter(
      (c) => c.classification === 'Precisa de atenção'
    );
    if (attentionCompetencies.length > 0) {
      attentionCompetencies.forEach((comp, index) => {
        text += `${t(
          'app.aoRealizarAAtividadeFoiIdentificadoQue'
        )} <strong>${name}</strong> ${t(
          'app.nãoApresentouUmBomResultadoAoRealizarAAtividadeParaDesenvolver'
        )} <strong>${comp.analysisType.toLowerCase()}</strong> ${t(
          'app.daHabilidade'
        )} <strong>${comp.skillName}</strong>,  ${t('app.relacionadoA')} ${t(
          'app.competencia'
        )} <strong>${comp.competenceName}</strong>`;
        if (index < attentionCompetencies.length - 1) {
          text += ` ${t('app.e')} `;
        }
      });
      text += '.<br/><br/>';
    }

    return text;
  };

  const getSequentialPageNumber = () => {
    let currentPage = startPage;

    const getNextPage = () => {
      return currentPage++;
    };

    return getNextPage;
  };

  const getNextPageNumber = getSequentialPageNumber();

  return (
    <>
      <ReportPage pageNumber={getNextPageNumber()}>
        <img
          src={leftDecoration}
          alt="left-decoration"
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <img
          src={rightDecoration}
          alt="right-decoration"
          style={{
            position: 'absolute',
            top: 70,
            right: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <Flex vertical align="center" justify="center">
          <Title primary style={{ textTransform: 'uppercase' }}>
            {t('app.analiseDeDados')}
          </Title>
          <img src={lightbulb} alt="Lâmpada" width={68} />
        </Flex>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.osDadosApresentadosAqui')} {formattedDate}{' '}
          {t('app.seguindoAMetodologia')}
        </Text>
        <Title
          primary
          style={{
            marginBottom: '61px',
            textTransform: 'uppercase',
          }}
        >
          {t('app.amostra')}
        </Title>
        <Flex wrap="wrap" justify="center" align="center" gap={113}>
          {(isStudent ? items.slice(4) : items).map((item, index) => (
            <Flex
              key={index}
              style={{
                position: 'relative',
                borderRadius: 20,
                border: '3px solid #E9E9E9',
                width: '164px',
                height: '106px',
              }}
              align="center"
              justify="center"
              vertical
            >
              <img
                src={item.image}
                alt={item.label}
                width={76}
                style={{
                  position: 'absolute',
                  top: -45,
                  left: -20,
                }}
              />
              <Title color={colorSecondary}>{item.value}</Title>
              <Text>{item.label}</Text>
            </Flex>
          ))}
        </Flex>
      </ReportPage>

      <ReportPage pageNumber={getNextPageNumber()}>
        <Flex vertical align="center" justify="center">
          <Title primary>{t('app.emocoesERegistros')}</Title>
          <Text size="md" color={colorGreyBorderAndFont}>
            {formattedDate}
          </Text>
        </Flex>
        <Title primary style={{ alignSelf: 'flex-start' }}>
          {t('app.estadoEmocional')}
        </Title>
        <Flex
          style={{
            borderRadius: 10,
            background: 'rgba(241, 241, 241, 0.60)',
            padding: '24px',
            width: '100%',
          }}
          align="center"
          gap="16px"
        >
          <img src={emotion?.img} alt={emotion?.name} width={152} />
          <Text size="lg">
            <strong>{t('app.emocaoPredominante')}: </strong> {emotion?.name}
          </Text>{' '}
        </Flex>

        <Title primary style={{ alignSelf: 'flex-start' }}>
          {t('app.registrosNoMeuCantinho')}
        </Title>

        <Flex
          style={{
            borderRadius: 10,
            background: 'rgba(241, 241, 241, 0.60)',
            padding: '16px 24px',
            width: '100%',
          }}
          align="center"
        >
          {flags.map((flag, index) => (
            <React.Fragment key={index}>
              <Flex
                align="center"
                justify="space-between"
                vertical
                style={{
                  width: '100%',
                }}
              >
                <Text
                  size="md"
                  align="center"
                  style={{
                    lineHeight: '40px',
                    maxWidth: '130px',
                  }}
                >
                  {flag.label}
                </Text>
                <img src={flag.img} alt={flag.label} width={68} />
                <Title
                  style={{
                    lineHeight: '40px',
                  }}
                >
                  {flag.value}
                </Title>
              </Flex>
              {index < flags.length - 1 && (
                <Divider
                  type="vertical"
                  style={{
                    height: '164px',
                    color: 'black',
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Flex>
      </ReportPage>
      {knowledges[0].competences.length > 0 && (
        <ReportPage pageNumber={getNextPageNumber()}>
          <Flex vertical align="center" justify="center">
            <Title primary>{t('app.resumo') + ' - ' + activityName}</Title>
            <Text size="md" color={colorGreyBorderAndFont}>
              {formattedDate}
            </Text>
          </Flex>
          <Title primary style={{ alignSelf: 'flex-start' }}>
            {t('app.principaisDescobertas')}
          </Title>
          <Flex gap="8px" align="center" style={{ alignSelf: 'flex-start' }}>
            <img src={knowledge} alt="Conhecimento" width={80} />
            <Text size="lg" color="#1565C0" bold>
              {t('app.conhecimento')}
            </Text>
          </Flex>
          <Text size="md">
            {knowledges.slice(0, 1).map((knowledge, index) => (
              <React.Fragment key={index}>
                {knowledge.competences.map((competence, index) => (
                  <>
                    <strong key={index} style={{ color: knowledge.color }}>
                      {competence.label} :{' '}
                    </strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: competence.message ?? '',
                      }}
                    />
                    <br />
                    <br />
                  </>
                ))}
              </React.Fragment>
            ))}
          </Text>
        </ReportPage>
      )}
      {knowledges[1].competences.length > 0 && (
        <ReportPage pageNumber={getNextPageNumber()}>
          <Flex gap="8px" align="center" style={{ alignSelf: 'flex-start' }}>
            <img src={perception} alt="Percepção" width={80} />
            <Text size="lg" color="#478F46" bold>
              {t('app.percepcao')}
            </Text>
          </Flex>
          <Text size="md" align="justify">
            {knowledges.slice(1, 2).map((knowledge, index) => (
              <React.Fragment key={index}>
                {knowledge.competences.map((competence, index) => (
                  <>
                    <strong key={index} style={{ color: knowledge.color }}>
                      {competence.label} :{' '}
                    </strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: competence.message ?? '',
                      }}
                    />
                    <br />
                    <br />
                  </>
                ))}
              </React.Fragment>
            ))}
          </Text>
        </ReportPage>
      )}
      {knowledges[2].competences.length > 0 && (
        <ReportPage pageNumber={getNextPageNumber()}>
          <Flex gap="8px" align="center" style={{ alignSelf: 'flex-start' }}>
            <img src={behaviour} alt="Comportamento" width={80} />
            <Text size="lg" color="#ED810F" bold>
              {t('app.comportamento')}
            </Text>
          </Flex>
          <Text size="md" align="justify">
            {knowledges.slice(2).map((knowledge, index) => (
              <React.Fragment key={index}>
                {knowledge.competences.map((competence, index) => (
                  <>
                    <strong key={index} style={{ color: knowledge.color }}>
                      {competence.label} :{' '}
                    </strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: competence.message ?? '',
                      }}
                    />
                    <br />
                    <br />
                  </>
                ))}
              </React.Fragment>
            ))}
          </Text>
        </ReportPage>
      )}
      <ReportPage pageNumber={getNextPageNumber()}>
        <SubTitle
          primary
          style={{
            alignSelf: 'flex-start',
          }}
        >
          {t('app.conclusao')}
        </SubTitle>
        <Text
          size="md"
          align="justify"
          dangerouslySetInnerHTML={{
            __html:
              typeId === ActivityTypes.Normal ||
              typeId == ActivityTypes.Individual
                ? getActivityConclusionText()
                : getConclusionText(),
          }}
        />
      </ReportPage>

      <ReportPage pageNumber={getNextPageNumber()}>
        <Flex vertical align="center" justify="center">
          <Title primary>{t('app.dados') + ' - ' + activityName}</Title>
          <Text size="md" color={colorGreyBorderAndFont}>
            {formattedDate}
          </Text>
        </Flex>

        <Flex vertical gap="24px" style={{ width: '100%' }}>
          {knowledges
            .filter((k) => k.competences.length > 0)
            .slice(0, 2)
            .map((knowledge, index) => (
              <KnowledgeSection
                key={index}
                knowledge={knowledge}
                allValues={allValues}
              />
            ))}
        </Flex>
      </ReportPage>
      {knowledges.filter((k) => k.competences.length > 0).length > 2 && (
        <ReportPage pageNumber={getNextPageNumber()}>
          <Flex vertical gap="24px" style={{ width: '100%' }}>
            {knowledges
              .filter((k) => k.competences.length > 0)
              .slice(2)
              .map((knowledge, index) => (
                <KnowledgeSection
                  key={index}
                  knowledge={knowledge}
                  allValues={allValues}
                />
              ))}
          </Flex>
        </ReportPage>
      )}
    </>
  );
}
