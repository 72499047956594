import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { notificateError } from 'infra/helpers/notifications';
import axios from 'infra/http';
import './upload.css';

const UploadWithPreview: React.FC<{
  value: string;
  onChange: (value: string) => void;
  folderKey?: string;
  size?: 'large' | 'small';
}> = ({ value, onChange, folderKey = 'u4hero-files', size = 'large' }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(value);
  const [file, setFile] = useState<UploadFile>();

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const uploadAndAddImage = async (file: any) => {
    const imageTypes = ['image/png', 'image/jpeg'];
    if (!imageTypes.includes(file.type)) {
      notificateError('Formato de arquivo inválido');
    }

    if (imageTypes.includes(file.type) && file.size > 3e6) {
      notificateError('Tamanho de arquivo inválido');
      return null;
    }
    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data }: { data: { result: string } } = await axios.post(
        `/v1/file/generic-upload?folderKey=${folderKey}&bucketName=u4hero-files&keepOriginalName=true`,
        formData
      );
      onChange(data.result);
    } finally {
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Carregar</div>
    </button>
  );

  useEffect(() => {
    if (value) {
      setPreviewImage(value);
      setFile({
        url: value,
        uid: '1',
        name: 'image',
      });
    }
  }, [value]);

  return (
    <>
      <Upload
        listType="picture-card"
        customRequest={(action: any) => {
          uploadAndAddImage(action.file);
        }}
        onPreview={handlePreview}
        fileList={file ? [file] : []}
        multiple={false}
        maxCount={1}
        onRemove={(file) => {
          onChange('');
          setFile(undefined);
          setPreviewImage('');
        }}
        className={`upload-container ${size}`}
      >
        {!value && uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            movable: false,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible,
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default UploadWithPreview;
