import { Checkbox, Col, Collapse, Form, Row } from 'antd';
import Text from 'components/typography/text';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import { t } from 'core/resources/strings';

function PreMadeOptions() {
  const [check] = useCheckPermissions();
  const options = [
    {
      label: t('app.totalDeEstudantes'),
      name: 'totalStudents',
      visible: true,
    },
    {
      label: t('app.totalDeTurmas'),
      name: 'totalClasses',
      visible: true,
    },
    {
      label: t('app.totalDeProfessores'),
      name: 'totalEducators',
      visible: check([
        Permissions.Indicators.ViewAll,
        Permissions.Org.Indicators.View,
      ]),
    },
    {
      label: t('app.totalDeColaboradores'),
      name: 'totalCollaborators',
      visible: check([Permissions.Indicators.ViewAll]),
    },
    {
      label: t('app.totalDeEscolas'),
      name: 'totalSchools',
      visible: check([Permissions.Org.Indicators.View]),
    },
    // {
    //   label: 'Mapa de Calor - Abandono Escolar',
    //   name: 'schoolDropout',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    // {
    //   label: 'Mapa de Calor - Saúde Mental',
    //   name: 'mentalHealth',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    // {
    //   label: 'Mapa de Calor - Segurança Alimentar',
    //   name: 'foodSafety',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    // {
    //   label: 'Mapa de Calor - Segurança Doméstica',
    //   name: 'homeSafety',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    // {
    //   label: 'Mapa de Calor - Segurança entre Pares',
    //   name: 'peerSafety',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    // {
    //   label: 'Mapa de Calor - Senso de Pertencimento',
    //   name: 'senseOfBelonging',
    //   visible: check([Permissions.Org.Indicators.View]),
    // },
    {
      label: t('app.frequenciaDosEstudantes'),
      name: 'studentFrequency',
      visible: check([
        Permissions.Indicators.ViewAll,
        Permissions.Indicators.View,
      ]),
    },
    {
      label: t('app.frequenciaDosEducadores'),
      name: 'educatorFrequency',
      visible: check([Permissions.Indicators.ViewAll]),
    },
    {
      label: t('app.emocionometroDosUltimos7Dias'),
      name: 'emotionometer',
      visible: check([
        Permissions.Indicators.ViewAll,
        Permissions.Indicators.View,
      ]),
    },
    {
      label: t('app.intensidadeDasEmocoes7Dias'),
      name: 'emotionometerIntensity',
      visible: check([
        Permissions.Indicators.ViewAll,
        Permissions.Indicators.View,
      ]),
    },
    {
      label: t('app.visaoGeralDeProgresso'),
      name: 'progressOverview',
      visible: true,
    },
  ];

  return (
    <Collapse style={{ width: '100%' }} expandIconPosition="end">
      <Collapse.Panel
        style={{
          background: '#F5F5F5',
          borderRadius: '10px',
          width: '100%',
        }}
        header={<Text>{t('app.opcoesPreCriadas')}</Text>}
        key={'1'}
        forceRender
      >
        <Row style={{ padding: '16px' }}>
          {options
            .filter((item) => item.visible)
            .map((item, index) => (
              <Col key={index} sm={24} lg={12}>
                <Form.Item
                  name={['options', item.name]}
                  valuePropName="checked"
                >
                  <Checkbox>{item.label}</Checkbox>
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
}

export default PreMadeOptions;
