import { Flex, Progress } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import ReportPage from 'ui/pages/app/indicators/components/new-report/components/page';
import { forwardRef } from 'react';
import { StudentProgress } from 'core/features/students/typings';
import SubTitle from 'components/typography/subTitle';
import React from 'react';
import { t } from 'core/resources/strings';

const STUDENTS_PER_PAGE = 20;

const ActivityProgress = forwardRef(
  (
    {
      progress,
      className,
      students,
      activityName,
    }: {
      progress: number;
      className: string;
      students: StudentProgress[];
      activityName: string;
    },
    ref
  ) => {
    const { colorPrimary } = useU4heroColors();

    const totalStudents = students.length;
    const girlCount = students.filter((s) => s.gender === 'F').length;
    const boyCount = students.filter((s) => s.gender === 'M').length;

    const notStartedStudents = students.filter((s) => Number(s.progress) === 0);
    const notStartedGirls = notStartedStudents.filter(
      (s) => s.gender === 'F'
    ).length;
    const notStartedBoys = notStartedStudents.filter(
      (s) => s.gender === 'M'
    ).length;

    const inProgressStudents = students.filter(
      (s) => Number(s.progress) > 0 && Number(s.progress) < 100
    );
    const inProgressGirls = inProgressStudents.filter(
      (s) => s.gender === 'F'
    ).length;
    const inProgressBoys = inProgressStudents.filter(
      (s) => s.gender === 'M'
    ).length;

    const completedStudents = students.filter(
      (s) => Number(s.progress) === 100
    );
    const completedGirls = completedStudents.filter(
      (s) => s.gender === 'F'
    ).length;
    const completedBoys = completedStudents.filter(
      (s) => s.gender === 'M'
    ).length;

    const today = new Date().toLocaleDateString('pt-BR');
    const pages = Math.ceil(students.length / STUDENTS_PER_PAGE);

    return (
      <div ref={ref as React.RefObject<HTMLDivElement>}>
        {Array.from({ length: pages }).map((_, pageIndex) => (
          <ReportPage key={pageIndex} pageNumber={pageIndex + 1}>
            <Flex vertical>
              {pageIndex === 0 && (
                <Title
                  primary
                  style={{
                    marginBottom: '12px',
                  }}
                >
                  {t('app.progressoDeTurmaEEstudantes')}
                </Title>
              )}
              <Flex
                style={{
                  borderRadius: '10px 10px 3px 3px',
                  background: 'rgba(119, 67, 185, 0.10)',
                  boxShadow: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.10)',
                  width: '100%',
                }}
                gap={80}
                align="center"
                justify="center"
              >
                <Progress
                  type="circle"
                  size={60}
                  strokeWidth={10}
                  strokeColor={colorPrimary}
                  status={'normal'}
                  percent={progress}
                />
                <SubTitle primary>{className}</SubTitle>
              </Flex>
              <Flex vertical>
                {students
                  .slice(
                    pageIndex * STUDENTS_PER_PAGE,
                    (pageIndex + 1) * STUDENTS_PER_PAGE
                  )
                  .map((student) => (
                    <Flex
                      key={student.id}
                      style={{
                        borderRight: `2px solid ${colorPrimary}`,
                        borderBottom: `2px solid ${colorPrimary}`,
                        borderLeft: `2px solid ${colorPrimary}`,
                        padding: '8px 16px',
                        background: 'white',
                      }}
                      justify="space-between"
                    >
                      <Text size="md">{student.name}</Text>
                      <Progress
                        percent={Math.round(
                          (Number(student.progress) || 0) * 100
                        )}
                        percentPosition={{ align: 'center', type: 'inner' }}
                        size={[200, 14]}
                        strokeColor={colorPrimary}
                        style={{ width: 'fit-content' }}
                      />
                    </Flex>
                  ))}
              </Flex>
              {pageIndex === pages - 1 && (
                <Text style={{ marginTop: '20px', textAlign: 'justify' }}>
                  Na data de hoje, {today}, a {activityName.toLowerCase()}{' '}
                  apresenta um progresso geral de {progress}%, envolvendo um
                  total de {totalStudents} estudantes, dos quais {girlCount} são
                  meninas e {boyCount} são meninos. Atualmente,{' '}
                  {notStartedStudents.length} estudantes ainda não iniciaram a
                  atividade, sendo {notStartedGirls} meninas e {notStartedBoys}{' '}
                  meninos. Além disso, {inProgressStudents.length} estudantes
                  estão com a atividade em andamento, com {inProgressGirls}{' '}
                  meninas e {inProgressBoys} meninos. Por fim,{' '}
                  {completedStudents.length} estudantes já concluíram a
                  atividade, incluindo {completedGirls} meninas e{' '}
                  {completedBoys} meninos.
                </Text>
              )}
            </Flex>
          </ReportPage>
        ))}
      </div>
    );
  }
);

export default ActivityProgress;
