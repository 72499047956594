import { useQuery } from 'react-query';
import axios from 'infra/http';

function useTotalSchools(params?:{
  tenantId?:string;
}) {
  const fetchData = async (): Promise<{
    total: number;
  }> => {
    const { data } = await axios.get<number>(`/v2/indicators/total-schools`,{
      params
    });

    return { total: data };
  };

  return useQuery([`/v2/indicators/total-schools`], () => fetchData());
}

export default useTotalSchools;
