import { Dropdown, Flex, Menu } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import Dot from 'components/dot';
import { FaEllipsis } from 'react-icons/fa6';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import { EventDetails } from 'core/features/events/typings';
import useToggleEvent from 'core/features/events/hooks/useToggleEvent';
import { PiHourglassMedium } from 'react-icons/pi';
import dayjs from 'dayjs';
import { AiOutlineCloseCircle } from 'react-icons/ai';

function EventCard({
  item,
  onEdit,
  onView,
}: {
  item: EventDetails;
  onEdit: (v: string) => void;
  onView: (v: string) => void;
}) {
  const { colorPrimary } = useU4heroColors();

  const { mutate: handleToggle } = useToggleEvent();
  const queryClient = useQueryClient();

  return (
    <GenericCard padding="0px 16px 16px 16px" height="100%">
      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Flex
          style={{
            background: colorPrimary,
            padding: '9px 33px',
            borderRadius: '0px 0px 10px 10px',
            width: 'fit-content',
            alignSelf: 'center',
          }}
        >
          <Text color="white" bold>
            {item.name}
          </Text>
        </Flex>
        <Flex
          style={{
            borderRadius: '20px',
            background: '#F5F5F5',
            boxShadow:
              ' 0px -0.5px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            position: 'relative',
          }}
          vertical
        >
          <img src={item.splashArt} alt={item.name} />
        </Flex>
        <Flex justify="space-between">
          <Flex gap={8} align="center">
            <PiHourglassMedium size={20} color="black" />
            <Text>{dayjs(item.startDate).format('DD/MM/YYYY')} </Text>
          </Flex>
          <Flex gap={8} align="center">
            <AiOutlineCloseCircle size={20} color="black" />
            <Text>{dayjs(item.endDate).format('DD/MM/YYYY')} </Text>
          </Flex>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" gap={4}>
            <Dot size={8} color={item.active ? '#336732' : '#BB191E'} />
            <Text>{item.active ? 'Ativo' : 'Desativado'}</Text>
          </Flex>
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  if (e.key === 'edit') {
                    onEdit(item.id);
                  }
                  if (e.key === 'view') {
                    onView(item.id);
                  }
                  if (e.key === 'toggle') {
                    handleToggle(
                      {
                        id: item.id,
                        isActive: item.active,
                      },
                      {
                        onSuccess: () => {
                          notificateSucess(
                            `O evento foi ${
                              item.active ? 'desativado' : 'ativado'
                            } com sucesso!`
                          );

                          queryClient.invalidateQueries('/v2/events');
                        },

                        onError: () => {
                          notificateError(
                            `Houve um problema ao ${
                              item.active ? 'desativar' : 'ativar'
                            } este evento!`
                          );
                        },
                      }
                    );
                  }
                }}
              >
                <Menu.Item key={'edit'} id="id-tour-table-row-action-edit">
                  Editar
                </Menu.Item>
                <Menu.Item key={'view'} id="id-tour-table-row-action-view">
                  Visualizar Evento
                </Menu.Item>

                <Menu.Item key={'toggle'} id="id-tour-table-row-action-delete">
                  {item.active ? 'Desativar' : 'Ativar'}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <FaEllipsis size={24} color="black" style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default EventCard;
