import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Pagination, Row, Spin } from 'antd';
import Button from 'components/buttons/button';
import { Graph } from 'core/features/indicators-profile/typings';
import { notificateSucess, showConfirm } from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import ModalAddIndicators from '../../components/modal-add-indicators';
import CustomGraph from '../components/customGraph';
import EmotionalGraph from '../components/emotionalGraph';
import StudentFrequencyGraph from '../components/studentFrequencyGraph';
import TotalClasses from '../components/totalClasses';
import TotalStudents from '../components/totalStudents';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'core/resources/strings';
import EmotionalIntensityGraph from 'ui/pages/app/indicators/tabs/components/emotionalItensityGraph';
import ProgressOverview from 'ui/pages/app/indicators/tabs/components/progressOverview';

function EducatorView() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const { colorPrimary } = useU4heroColors();
  const [graphIndex, setGraphIndex] = useState<number>();

  const onRemoveIndicator = ({
    label,
    value,
  }: {
    label: string;
    value: any;
  }) => {
    showConfirm({
      message: `${t('app.temCertezaQueDesejaExcluirOIndicador')} " ${label}"?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setGraphs(graphs.filter((item) => item.id !== value));
        notificateSucess(`${t('app.indicadorExcluidoComSucesso')}`);
      }
    });
  };

  return (
    <Flex vertical gap={16}>
      {isOpen ? (
        <ModalAddIndicators
          mode="educator"
          graphs={graphs}
          setGraphs={setGraphs}
          graphIndex={graphIndex}
          onClose={() => {
            setIsOpen(false);
            setGraphIndex(undefined);
          }}
        />
      ) : null}
      <Row
        gutter={[
          { xs: 0, md: 40 },
          { xs: 16, md: 22 },
        ]}
      >
        <Col sm={24} md={12} style={{ width: '100%' }}>
          <Flex vertical gap={isMobile ? 16 : 32}>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <TotalStudents />
              </Col>
              <Col span={12}>
                <TotalClasses />
              </Col>
            </Row>
            <StudentFrequencyGraph />
          </Flex>
        </Col>

        <Col sm={24} md={12} style={{ width: '100%' }}>
          <EmotionalGraph />
        </Col>
      </Row>
      <Row
        align="stretch"
        gutter={[
          { xs: 40, md: 40 },
          { xs: 16, md: 20 },
        ]}
      >
        <Col xs={24} md={12}>
          <EmotionalIntensityGraph />
        </Col>
        {graphs.map((graph, index) => (
          <Col key={index} span={isMobile ? 24 : 12}>
            <CustomGraph
              graph={graph}
              onDuplicate={() => {
                setIsOpen(true);
                const newGraphId = uuidv4();
                const newIndex = graphs.length;
                setGraphs([...graphs, { ...graph, id: newGraphId }]);
                setGraphIndex(newIndex);
                notificateSucess(t('app.graficoDuplicadoComSucesso'));
              }}
              onEdit={() => {
                setIsOpen(true);
                setGraphIndex(index);
              }}
              onRemove={() => {
                onRemoveIndicator({
                  label: graph.name,
                  value: graph.id,
                });
              }}
            />
          </Col>
        ))}
        <Col span={24}>
          <Flex justify="center">
            <Button size="md" onClick={() => setIsOpen(true)}>
              {t('app.maisGraficos')}
            </Button>
          </Flex>
        </Col>

        <Col span={24}>
          <ProgressOverview />
        </Col>
      </Row>
    </Flex>
  );
}

export default EducatorView;
