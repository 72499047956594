import { useQuery } from 'react-query';
import axios from 'infra/http';
import { Report } from 'core/features/report/types';

export interface Params {
  activityId?: string;
  classId?: string;
  userId?: string;
  tenantId?: string;
}

function useGenerateReports(params: Params) {
  const fetchData = async (
    params: Params
  ): Promise<Report> => {
    const { data } = await axios.get<Report>(`/v1/report/new`, {
      params,
    });

    return data;
  };

  return useQuery([`/v1/report/new`, params], () => fetchData(params), {
    keepPreviousData: true,
  });
}

export default useGenerateReports;
