import {translate} from '../polyglot';

export default translate('en', {
  app: {
    idadeMinima: 'Minimum age',
    idadeMaxima: 'Maximum age',
    genero: 'Genre',
    tempoMedio: 'Average time',
    objetivoLudico: 'Playful purpose',
    objetivoPedagogico: 'Pedagogical objective',
    competencias: 'Skills',
    questoes: 'Questions',
    conteudos: 'Contents',
    conteudo: 'Content',
    remover: 'Remove',
    emocionometro: 'Emotionometer',
    indicadores_gerais: 'Gerais indicators',
    indicadores_avancados: 'Advanced indicators',
    rankJogadores: 'Player rank',
    detalhes: 'Details',
    modelo: 'Model',
    cancelar: 'Cancel',
    importar: 'Import',
    importacao: 'Import',
    ver_mais: 'View more',
    historico: 'History',
    recomendacao: 'Recommendation',
    adicionar: 'To add',
    maximo: 'Maximum',
    minimo: 'Minimum',
    escola: 'School',
    escolas: 'Schools',
    alunos: 'Students',
    aluno: 'Student',
    serie: 'Grade',
    series: 'Grades',
    turma: 'Class',
    turmas: 'Classes',
    imagem: 'Image',
    filtro: 'Filter',
    filtrar: 'Filter',
    imprimir: 'Print',
    enviarEmail: 'Send',
    comentarios: 'Comments',
    comentar: 'Comment',
    colaboradores: 'Collaborators',
    colaborador: 'Collaborator',
    grafico: 'Graphic',
    grafico_geral: 'General Graphics',
    sair: 'Sign out',
    configuracoes: 'Settings',
    nome: 'Name',
    opcoes: 'Options',
    editar: 'Edit',
    desativar: 'Deactivate',
    ativar: 'Activate',
    ativo: 'Active',
    inativo: 'Inactive',
    aviso: 'Warning',
    sucesso: 'Success',
    erro: 'Error',
    portugues: 'Portuguese',
    ingles: 'English',
    espanhol: 'Spanish',
    criar: 'Create',
    entrar: 'Log in',
    facaBusca: 'Search',
    voltar: 'Back',
    salvar: 'Save',
    documento: 'Document',
    telefone: 'Phone',
    site: 'Site',
    emailAdmin: "Director's email",
    nomeAdmin: "Director's name",
    pais: 'Country',
    endereco: 'Address',
    adicionarTurma: 'Add class',
    cidade: 'City',
    bairro: 'District',
    estado: 'State',
    rua: 'Street',
    complemento: 'Complement',
    numero: 'Number',
    cep: 'Postal Code',
    matutino: 'Morning',
    afternoon: 'Afternoon',
    vespertino: 'Evening',
    noturno: 'Nocturnal',
    periodo: 'Period',
    excluir: 'Delete',
    perfil: 'Profile',
    usuario: 'User',
    senha: 'Password',
    acessoTurmas: 'Access to classes',
    idade: 'Age',
    anos: 'Years',
    dataNascimento: 'Date of birth',
    nomePai: 'Father Name',
    nomeMae: 'Mother Name',
    limpar: 'Clear',
    aplicar: 'Apply',
    relatorio: 'Report',
    relatorios: 'Reports',
    relatorioCompleto: 'Full report',
    sexo: 'Sex',
    feminino: 'Feminine',
    masculino: 'Male',
    esqueciSenha: 'I forgot my password',
    voltarParaLogin: 'Back to login',
    recuperarSenha: 'Recover Password',
    jogos: 'Games',
    jogo: 'Game',
    aventura: 'Adventure',
    exploracao: 'Explorer',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Chapter',
    capitulos: 'Chapters',
    tipo: 'Type',
    perguntas: 'Questions',
    licenca: 'License',
    descricao: 'Description',
    hipotese: 'Hypothesis',
    orientacao: 'Orientation',
    resposta: 'Answer',
    pergunta: 'Question',
    tag: 'Tag',
    tag_pai: 'Parent Tag',
    jogoEmocoes: 'Emotions game',
    graficoEmocoes: 'Graph of emotions',

    autoGestao: 'Self-managenment',
    autoconsciencia: 'Self-conscience',
    habilidadesRelacionamento: 'Relationship skills',
    conscienciaSocial: 'Social conscience',
    tomadaDecisaoResponsavel: 'Responsible Decision',
    graficoSocioemocional: 'Socioemotional graph',
    socioemocional: 'Socioemotional',

    nivemCompreensao: 'Level of understanding',
    otimaCompreensao: 'Great understanding',
    boaCompreensao: 'Good understanding',
    razoavelCompreensao: 'Reasonable understanding',
    poucaCompreensao: 'Little understanding',
    graficoBullying: 'Graphic bullying',

    memoriaTrabalho: 'Working memory',
    flexibilidadeCognitiva: 'Cognitive flexibility',
    controleInibitorio: 'Inhibitory control',
    legenda: 'Legend',
    baixoControleInibitorio: 'Low inhibitory control',
    medioControleInibitorio: 'Medium inhibitory control',
    altoControleInibitorio: 'High inhibitory control',

    entendimentoTurma: 'Class understanding',
    entendimentoSerie: 'Understanding the series',
    entendimentoAluno: 'Student understanding',

    graficoFuncoesExecutivas: 'Executive Functions Graph',
    mediaCliques10Segundos: 'Average clicks every 10 seconds',

    acimaDe20: 'Above 20',
    de15a20: 'from 15 to 20',
    ate10: 'To 10',

    graficoHumor: 'Mood chart',
    nivelCompreensao: 'Level of understanding',

    'Estou feliz.': 'Happy.',
    'Estou com medo.': 'Afraid.',
    'Estou triste.': 'Sad.',
    'Estou com raiva.': 'Angry.',
    'Estou com nojo.': 'Disgusted.',

    configuracao: 'Settings',
    editar_perfil: 'Edit profile',
    inicial: 'Home',
    encerramento: 'Closing',
    investigacao: 'Investigation',

    Admin: 'Admin',
    Teacher: 'Teacher',

    morning: 'Morning',
    evening: 'Evening',
    nocturnal: 'Nocturnal',
    organizacao: 'Organization',
    schoolEvasion: 'School Evasion',
    reprovation: 'Reprobation',
    absenteeism: 'Absenteeism',
    conflicts: 'Conflicts and School Violence',
    performance: 'Academic Performance',
    participation: 'Participation in Extracurricular Activities',
    familySupport: 'Family Support',
    socioeconomicLevel: 'Socioeconomic Level',
    acessibility: 'Accessibility and Inclusion',
    mentalHealth: 'Mental Health',
    accessToTech: 'Access to Technology',
    schoolEvasionInfo:
      'This indicator evaluates the likelihood of a student dropping out of school based on essential socioemotional skills and competencies. The skills and themes analyzed in this indicator include Social Awareness, Relationship, Safety, Self-Awareness, Self-Management, Responsible Decision Making, and Anti-Bullying.',
    reprobationInfo:
      'Evaluates the potential risk of a student being retained in the same year or level of education due to a lack of critical socioemotional skills. The skills and themes analyzed include Social Awareness, Relationship, Safety, Self-Awareness, Self-Management, Responsible Decision Making, and Anti-Bullying. This indicator reflects the likelihood of a student facing academic and emotional difficulties that may lead to reprobation.',
    performanceInfo:'Indicator associated with a student\'s performance in an educational environment, based on their ability to meet the standards and expectations established for learning and school success. The skills and themes analyzed include Social Awareness, Relationship, Safety, Self-Awareness, Self-Management, Responsible Decision Making, and the ability to combat bullying.',
    absenteeismInfo:
      'Measures the risk of frequent unjustified absences. The skills and themes analyzed in this indicator are Self-Management, Self-Awareness, Responsible Decision Making, Safety, and Anti-Bullying.',
    conflictsInfo:
      'Evaluates the risk of conflicts and violence in the school environment based on reflections and discoveries by students and educators themselves. It reflects the likelihood of harmful incidents affecting the well-being and safety of students and educators. The themes analyzed in this indicator are Safety and Anti-Bullying.',
    participationInfo:
      'Indicator that evaluates student involvement in activities outside of school hours. These activities, including school clubs and interest groups, promote learning beyond the classroom and the development of socioemotional skills. The indicator is composed of skills such as Self-Awareness, Relationship, Self-Management, Responsible Decision Making, and Social Awareness, and the theme Safety.',
    familySupportInfo:
      'Family Support is an indicator that evaluates the involvement of the family in the student\'s educational process. Family support is essential for the socioemotional development of the student and for creating a safe and protected environment. The skills evaluated in this indicator include Relationship, Social Awareness, and the theme Safety.',
    socioeconomicLevelInfo:
      'Socioeconomic Level is an indicator that evaluates the socioeconomic level of students in the school, family, and social context. This indicator is constructed based on the level of education and family income of students. The skill evaluated in this indicator is Social Awareness, and the theme Safety.',
    acessibilityInfo:
      'Inclusion and Accessibility is an indicator that evaluates the inclusion and service of students with accessibility demands in a school environment. This indicator is composed of skills such as Social Awareness and Relationship, and the theme Safety.',
    mentalHealthInfo:
      'Mental Health is an indicator that evaluates the emotional and psychological well-being of students and educators in the school environment. The skills and themes analyzed include Self-Management, Self-Awareness, Responsible Decision Making, Safety, and Anti-Bullying.',
    accessToTechInfo:
      'The "Access to Technology" indicator reflects the availability and use of technological tools by students. A low indicator may signal difficulties in accessing technology, impacting the teaching-learning process. The skills analyzed include Responsible Decision Making, Relationship, and Self-Management.',
    Games: 'Games',
    students: 'Students',
    users: 'Users',
    customQuestion: 'Custom Questions',
    tenant: 'Institution',
    report: 'Report',
    collaborators: 'Collaborators',
    collaborator: 'Collaborators',
    class: 'Classes',
    profile: 'Profile',
    dashboard: 'Home',
    activity: 'Activity',
    indicators: 'Indicators',
    settings: 'Settings',
    create: 'Create',
    edit: 'Edit Own',
    view: 'View Own',
    delete: 'Delete',
    activate: 'Activate/Deactivate',
    viewall: 'View All',
    editall: 'Edit All',
    changePassword: 'Change Password',
    adapt: 'Adapt',
    import: 'Import',
    practices: 'Pedagogical Inspirations',
    Schools: 'Schools',
    school: 'School',
    metrics: 'Metrics',
    games: 'Games',
    contacts: 'Contacts',
    competence: 'Competencies',
    helpers: 'Aids',
    questions: 'Questions',
    locations: 'Translations',
    home: 'Home',
    org: 'Organization',
    safeSpace: 'My Corner',
    selfAvaliation: 'Initial Evaluation',
    avaliation: 'Evaluation',
    studentDetail: 'Student Details',
    symptoms: 'Symptoms',
    macro: 'Macro View',
    student: 'Student View',
    geographic: 'Geographic View',
    viewName: 'View Name',
    Comprehension: 'Knowledge',
    'Spontaneous Decision': 'Behavior',
    'Third Party': 'Perception',
    serious: 'Serious',
    moderate: 'Moderate',
    light: 'Light',
    no_manifest: 'No Manifest',
    selfDeprecation: 'Self-Deprecation',
    somaticConcerns: 'Somatic Concerns',
    changeInSelfImage: 'Change in Self-Image',
    pessimism: 'Pessimism',
    suicidalThoughtsOrDesires: 'Suicidal Thoughts or Desires',
    lossOfPleasure: 'Loss of Pleasure',
    guilt: 'Guilt',
    sadness: 'Sadness',
    difficultyWorking: 'Difficulty Working',
    difficultyConcentrating: 'Difficulty Concentrating',
    irritability: 'Irritability',
    pastFailures: 'Past Failures',
    feelingOfUselessness: 'Feeling of Uselessness',
    agitation: 'Agitation',
    indecision: 'Indecision',
    weightLoss: 'Weight Loss',
    tirednessOrFatigue: 'Tiredness or Fatigue',
    lossOfSexualInterest: 'Loss of Sexual Interest',
    changeInSleepPattern: 'Change in Sleep Pattern',
    cry: 'Cry',
    selfCriticism: 'Self-Criticism',
    changeInAppetite: 'Change in Appetite',
    feelingOfPunishment: 'Feeling of Punishment',
    lossOfInterest: 'Loss of Interest',
    lossOfenergy: 'Loss of Energy',
    'In person': 'In Person',
    Remote: 'Remote',
    Hybrid: 'Hybrid',
    Multiple: 'Multiple Contexts',
    Familiar: 'Familiar',
    School: 'School',
    Digital: 'Digital',
    Social: 'Social',
    posts: 'Posts',
    emotion: 'Emotion',
    icon: 'Icon',
    background: 'Background',
    frame: 'Frame',
    activities: 'Activities',
    b2c: 'B2C',
    groups: 'Groups',
    ola: 'Hello',
    estudantesAtivos: 'Active Students This Week',
    confiraPainelEstudante: 'Check the Student Panel',
    conhecaHistoria: 'Get to Know the Story of',
    leiaAgora: 'Read Now',
    conferir: 'Check',
    fiquePorDentro: 'Stay Inside the U4Hero News',
    saibaMais: 'Learn More',
    rotoriLore:
      'In a magical forest called Sparkling Forest, there was an extraordinary creature known as Rotori. It looked like a bear, but its special characteristics made it unique throughout the valley. It had soft, light brown fur with small white spots, its eyes were like two sparkling pearls, but it had no mouth.\
      What made Rotori truly special was its ability to control the climate, bringing sun on cloudy days, making it snow in the middle of summer, or causing a gentle breeze whenever it wanted. It lived in the Magical Forest, where all creatures lived in harmony. Known for its kind heart and its friendship with everyone.\
     In Senáquia, there were numerous magical beings, each with their own personality and appearance. However, there was a wicked creature called Tagon, who always tried to disrupt the balance of the Magical Forest, Tagon was constantly plotting plans to create confusion and chaos, affecting the peace of the forest.\
     One day, when a terrible storm threatened to destroy the entire Kingdom of Senáquia, the creatures of the forest turned to Rotori for help. With determination, it faced the storm and used its powers to calm the furious winds and the turbulent waters. It prevented Tagon from causing more damage to the balance of the kingdom.\
     After its victory over Tagon, Rotori was hailed as the guardian of the Sparkling Forest, and continued to bring joy and balance to the kingdom, ensuring that all seasons of the year were magical and happy.\
      The legend of Rotori inspired children to take care of nature and to believe in the magic that can be found everywhere, if you know where to look.',
    naeBoLore:
      'In Senáquia, there were diverse magical creatures of all shapes, even ones resembling vegetables, like Na and his brother Bo. They were a species of turnip, but born as conjoined twins who lived stuck together.\
      They lived in a cozy terrain in the Root Valley, where the houses were underground and the inhabitants were vegetables and legumes. Whenever someone needed a good laugh or a friend to play with, there was Na and Bo with their pranks and their ability to hide among the vegetation to cheer up.',
    coepiLore:
      'A very special and mischievous creature called Coepi, with the appearance of an owl, but with rabbit ears peeking out from among its soft and white feathers. It was what made it unique among the other animal creatures.\
      It loved to play pranks and cause mischief throughout the Root Valley. It was known for its contagious laughter and its playful spirit. Coepi lived in the Sparkling Forest, a place full of tall trees, babbling brooks, and colorful flowers.\
      The inhabitants of Senáquia lived worried about what Coepi\'s next trap would be. For with its ability to walk silently like a rabbit, it always went unnoticed by the forest guardian and surprised everyone with its tricks.',
    ibaLore:
      'Where all creatures were inspired by nature, there was a very special one called Ibá. This magical being resembled a tree trunk, but had a friendly expression on its wooden face. It was known for its wisdom and its ability to connect with nature in a unique way.\
      Ibá lived in the heart of the Great Forest, a place where the trees were tall and majestic, and the streams sang among the stones. It was the son of the forest and was loved by all the creatures that lived there. Ibá used to sit at the foot of the ancient tree, where it heard many wise stories that it told.\
       Its powers were essential for the growth of flowers and trees, it kept everything running in its rhythm and brought harmony to the Sparkling Forest.',
    tagonLore:
      'Where all creatures were known for their happiness and kindness, there was a creature that stood out for its bad mood. Its name was Tagon, and it resembled a tangled mess of dark hairs, its eyebrows with a scowling expression and its voice was grumbling.\
      Tagon lived in the depths of the Grumblor Swamp, a place where the trees had no leaves, and silence prevailed. It preferred solitude and rarely interacted with other creatures of the forest. Its little house was hidden among the shadows of the trees, and Tagon spent its days dozing and grumbling about the constant noise of the outside world.\
      However, it nurtured much envy for the harmony and happiness of the Sparkling Forest where most creatures lived, a place that was always cheerful and full of life. Many times it was seen trampling flowers and destroying the forest.',
    terryLore:
      'Tery, born in the Root Valley, had the shape of an eggplant, but had legs and a leaf on top of its head, besides small shiny eyes. Its personality resembled that of a puppy.\
      It was known for always running in the Sunlit Field. It was a loyal companion and was always willing to play and explore the vast landscapes of the kingdom. However, there was one place it never dared to go, the Grumblor Swamp, that place gave it the chills and besides, the problematic Tagon lived there, who hated being bothered.',
    previaPontosAtenção: 'Preview Points of Attention',
    areasMelhoria: 'Areas of Improvement',
    pontosFortes: 'Strong Points',
    maisDetalhes: 'More Details',
    emocionometroNaoPreenchido:
      'The emotionometer was not filled out at all, there is no information to be displayed!',
    pontosAtenção: 'Points of Attention',
    geral: 'General',
    explicacaoGeral:
      'In the general analysis, two cards will be presented emphasizing areas that will highlight the positive points and notable achievements.',
    naoHaInformacoesSuficientes:
      'There is not enough information to be displayed! We recommend that you dedicate yourself to developing skills with your students to visualize the information.',
    explicacaoTurma:
      'The purpose of this presentation is to analyze the skills in the classes, focusing on the one that demands attention and the one that stands out positively.',
    comparativo: 'Comparative',
    explicacaoComparativo:
      'In this analysis, we identify the student with the least developed skill and compare it to the class average in the same skill.',
    atuar: 'Act',
    explorar: 'Explore',
    conhecimento: 'Knowledge',
    comportamento: 'Behavior',
    percepcao: 'Perception',
    conhecimentoDescricao:
      'Results obtained from analyses that investigate the level of understanding of concepts related to socio-emotional skills.',
    comportamentoDescricao:
      'Results obtained from analyses that investigate the practical application of socio-emotional skills.',
    percepcaoDescricao:
      'Results obtained from analyses that investigate the ability to recognize socio-emotional skills exhibited by others.',
    semDados: 'No Data',
    totalDeRespostas: 'Total Responses',
    gerenciarEstudantes: 'Manage Students',
    gerenciarTurmas: 'Manage Classes',
    gerenciarColaboradores: 'Manage Collaborators',
    gerenciarPerfis: 'Manage Profiles',
    dadosMinhaOrganizacao: 'Data from my organization',
    dadosMinhaEscola: 'Data from my school',
    minhaEscola: 'My School',
    instituicao: 'Institution',
    criarTurma: 'Create Class',
    turmaSérie: 'Class/Grade',
    sérieTurma: 'Grade/Class',
    nomeSérie: 'Name/Grade',
    chave: 'Key',
    quantidadeDeEstudantes: 'Number of Students',
    qtdEstudantes: 'Number of Students',
    anoLetivo: 'Academic Year',
    painelDaTurma: 'Class Panel',
    acoes: 'Actions',
    excluirTurmaConfirmacao:
      'Are you sure you want to delete the class record?',
    turmaExcluidaComSucesso: 'The class was successfully deleted!',
    erroAoExcluirTurma:
      'There was a problem deleting the class. Check your connection and try again.',
    excluirTurmasConfirmacao:
      'Are you sure you want to delete the class records?',
    turmasExcluidasComSucesso: 'The classes were successfully deleted!',
    erroAoExcluirTurmas:
      'There was a problem deleting the classes. Check your connection and try again.',
    deletar: 'Delete',
    rematricula: 'Re-enrollment',
    visualizar: 'View',
    verPainel: 'View Panel',
    turno: 'Shift',
    Estudantes: 'Students',
    professores: 'Teachers',
    desenvolvimentoDeCompetencias:
      'Development of Skills and Abilities',
    analiseDoClimaEscolar: 'Analysis of the School Climate',
    tipoDeComparação: 'Type of Comparison',
    modoComparativo: 'Comparative Mode',
    selecioneATurma: 'Select the Class',
    selecioneOEstudante: 'Select the Student',
    areaDeFoco: 'Focus Area',
    habilidades: 'Skills',
    selecionarTodos: 'Select All',
    recomendacoesPedagogicas: 'Pedagogical Recommendations',
    feliz: 'Happy',
    triste: 'Sad',
    medo: 'Fear',
    nojo: 'Disgust',
    raiva: 'Anger',
    totalDeAtividades: 'Total Activities',
    concluidas: 'Completed',
    emProgresso: 'In Progress',
    naoIniciadas: 'Not Started',
    rotaDeAprendizado: 'Learning Path',
    limparFiltro: 'Clear Filter',
    trilha: 'Trail',
    avaliacaoInicial: 'Initial Assessment',
    inicio: 'Start',
    termino: 'End',
    jogoEscolhido: 'Chosen Game',
    progresso: 'Progress',
    concluidoEm: 'Completed On',
    registrosEmMeuCantinho: 'Records in “My Corner”',
    meuCantinho: 'My Corner',
    mostrarMais: 'Show More',
    totalDeRegistros: 'Total Records',
    naoClassificado: 'Not Classified',
    coracaoQuentinho: 'Warm Heart',
    bomFicarDeOlho: 'Good to Keep an Eye On',
    redobreAtencao: 'Redouble Attention',
    problemaSerio: 'Serious Problem',
    finalizar: 'Finalize',
    dadosGerais: 'General Data',
    equipe: 'Team',
    pedagogica: 'Pedagogical',
    equipePedagogica: 'Pedagogical Team',
    editada: 'Edited',
    criada: 'Created',
    comSucesso: 'with success!',
    houveUmProblemaAo: 'There was a problem with',
    aTurma: 'the class',
    verifiqueSuaConexaoETenteNovamente:
      'Check your connection and try again.',
    modoDemonstrativo: 'Demonstrative Mode',
    mensagemDeErroAnoLetivo: 'Please inform the school year!',
    mensagemDeErroSerie: 'Please inform the series!',
    mensagemDeErroTurno: 'Please inform the shift!',
    mensagemDeErroNomeDaTurma: 'Please write the class name!',
    mensagemDeErroDataDeExpiracao: 'Please inform the expiration date!',
    dataDeExpiracao: 'Expiration Date',
    desejaCriarUmaAvaliacaoInicial: 'Do you want to create an initial assessment?',
    mensagemDeErroAvaliacaoInicial:
      'Please choose if you want to create an initial assessment or not!',
    integral: 'Integral',
    sim: 'Yes',
    nao: 'No',
    aviso30DiasTurma:
      'Attention: After 30 days of creating the class, it will not be possible to edit the fields "School Year", "Series", and "Shift".',
    baixarQrCode: 'Download QR Code',
    instrucoesQrCode:
      'It\'s time to test our platform in practice in educator mode. Follow these steps:',
    instrucao1: 'Scan the QR code with your cell phone camera.',
    instrucao2:
      'Register by entering your name or nickname and click on "ENTER" to access the main page.',
    instrucao3: 'Now it\'s just a matter of enjoying and exploring our platform.',
    visualizarQrCode: 'View QR Code',
    nomePerfil: 'Name/Profile',
    situacao: 'Situation',
    fechar: 'Close',
    perfilDe: 'Profile of',
    educador: 'Educator',
    todosOsColaboradores: 'All Collaborators',
    selecionados: 'Selected',
    ateAgoraNenhumColaboradorEscolhido:
      'So far, no collaborator has been chosen. Please select the collaborators to view them here.',
    naoExistemMaisColaboradoresParaExibicao:
      'There are no more collaborators to display. All collaborators have already been chosen.',
    estudantesDaTurma: 'Students of the Class',
    nomeChave: 'Name/Key',
    emAndamento: 'In Progress',
    naoIniciada: 'Not Started',
    concluido: 'Completed',
    expirada: 'Expired',
    exEstudante: 'Ex-Student',
    todosOsEstudantes: 'All Students',
    naoExistemMaisEstudantesParaExibicao:
      'There are no more students to display. All students have already been chosen.',
    ateAgoraNenhumEstudanteEscolhido:
      'So far, no student has been chosen. Please select the students to view them here.',
    aAvaliacaoInicialÉCompostaPor: 'The initial assessment is composed of',
    sendoElas: 'being them',
    daCompetencia: 'of the competence',
    rematriculaEfetuadaComSucesso: 'Rematriculation successfully completed!',
    mensagemDeErroRematricula:
      'There was a problem with the rematriculation. Check your connection and try again.',
    listaDeChavesDeAcessosDosEstudantes:
      'List of Access Keys of the Students',
    versaoColorida: 'Colorful Version',
    versaoEscalasDeCinza: 'Gray Scale Version',
    modoBilhete: 'Ticket Mode',
    baixarTabela: 'Download Table',
    baixarChaveDosEstudantes: 'Download Student Key',
    tecnologiaSocioemocional: 'U4Hero! Socioemotional Technology',
    nomeDaEscola: 'School Name',
    mensagemBoasVindasAluno:
      'Hello, welcome to U4Hero! You are just 2 steps away from starting your fun and learning journey:',
    passo1Bilhete:
      'Download our app from the stores (Play Store, Windows Store, or App Store) or access directly through the browser',
    passo2Bilhete: 'Enter your access key and click on "REGISTER".',
    prepareSeParaUmaExperienciaIncrível:
      'Prepare yourself for an incredible experience of development and growth with the',
    qualquerDuvidaMandeUmEmailPara: 'Any questions, send an email to',
    chavesDeAcesso: 'Access Keys',
    nomeDoEstudante: 'Student Name',
    serieTurma: 'Grade/Class',
    ultimoAcesso: 'Last Access',
    ultimaResposta: 'Last Response',
    painelDoEstudante: 'Student Panel',
    temCertezaQueDeseja: 'Are you sure you want to',
    restaurar: 'Restore',
    oCadastroDoEstudante: 'the student registration ?',
    cadastro: 'Registration',
    excluido: 'excluded',
    restaurado: 'restored',
    opsAconteceuAlgumProblemaAoTentar:
      'Oops, some problem occurred while trying to',
    oCadastro: 'the registration',
    tenteNovamente: 'try again!',
    gerarNovaChave: 'Generate New Key',
    ultimoAcessoApartirDe: 'Last Access From',
    estudantesDesativados: 'Deactivated Students',
    estudantesComChaveAtiva: 'Students with Active Key',
    estudantesSemTurma: 'Students without a Class',
    naoIniciado0: 'Not Started (0%)',
    emAndamento179: 'In Progress (1% - 79%)',
    concluido100: 'Completed (100%)',
    cadastroAtualizado: 'Registration Updated',
    estudanteCadastrado: 'Student Registered',
    mensagemErroCadastro:
      'Oops, there was a problem trying to complete the registration, try again!',
    mensagemSairSemSalvar:
      'You haven\'t saved the information you entered, do you want to leave anyway?',
    informacoesNaoSalvas: 'Unsaved Information!',
    desejaRealmenteRealizarEstaOperacao:
      'Do you really want to perform this operation?',
    temCerteza: 'Are you sure?',
    operacaoRealizadaComSucesso: 'Operation Successful!',
    problemaAoRealizarOperacao: 'There was a problem performing this operation!',
    informacoesDoEstudante: 'Student Information',
    matricula: 'Registration',
    mensagemErroMatricula: 'Please enter the registration number!',
    nomeCompleto: 'Full Name',
    mensagemErroNomeCompleto:
      'Please enter the student\'s full name!',
    dataDeNascimento: 'Date of Birth',
    mensagemErroDataDeNascimento: 'Please enter the date of birth!',
    mensagemErroSexo: 'Please enter the student\'s gender!',
    informacoesDoResponsavel: 'Responsible Information',
    mensagemErroTelefone: 'The phone number must have at least 10 digits!',
    nomeDoResponsavel2: 'Responsible Name (2)',
    nomeDoResponsavel1: 'Responsible Name (1)',
    informacoesAcademicas: 'Academic Information',
    mensagemErroImportacao:
      'Oops, there was a problem trying to import, try again!',
    importacaoRealizadaComSucesso: 'Import Successful!',
    mensagemVerificarImportacao:
      'You haven\'t imported the file you entered. do you want to leave anyway?',
    mensagemImportacao:
      'If you want to register multiple students at once, we offer the option to import data through a table. You can check the model below, the fields of name, date of birth, document, and gender are mandatory.',
    baixarModelo: 'Download Model',
    cliqueAquiOuArrasteAplanilha: 'Click here or drag the spreadsheet',
    bomComportamento: 'Good Behavior',
    mauComportamento: 'Bad Behavior',
    todos: 'All',
    observacao: 'Observation',
    selecioneOsComportamentos: 'Select the behaviors',
    tipoDeObservacao: 'Type of Observation',
    observacoesAdicionaisOpcional: 'Additional Observations (optional)',
    observacaoAdicional: 'Additional Observation',
    historicoDeObservacoes: 'History of Observations',
    comportamentosSelecionados: 'Selected Behaviors',
    mostrarMenos: 'Show Less',
    lerMais: 'Read More',
    bomComportamento1: 'Helped to organize the classroom.',
    bomComportamento2: 'Showed leadership attitudes.',
    bomComportamento3: 'Showed environmental responsibility attitudes.',
    bomComportamento4: 'Collaborated well in group activities.',
    bomComportamento5: 'Collaborated in maintaining classroom order.',
    bomComportamento6: 'Completed tasks with quality.',
    bomComportamento7: 'Completed tasks quickly.',
    bomComportamento8: 'Contributed to conflict resolution.',
    bomComportamento9: 'Contributed to a positive environment.',
    bomComportamento10: 'Showed empathy towards colleagues.',
    bomComportamento11: 'Expressed enthusiasm for learning.',
    bomComportamento12: 'Was proactive in seeking help.',
    bomComportamento13: 'Was proactive in problem-solving.',
    bomComportamento14: 'Showed interest in the subject.',
    bomComportamento15: 'Showed respect towards colleagues.',
    bomComportamento16: 'Participated actively in classroom discussions.',
    bomComportamento17: 'Participated in extracurricular activities.',
    bomComportamento18: 'Participated in school events.',
    bomComportamento19: 'Had good relationships with colleagues.',
    bomComportamento20: 'Showed interest in learning other subjects.',
    mauComportamento1: 'Aggressed a colleague.',
    mauComportamento2: 'Showed disrespectful behavior.',
    mauComportamento3: 'Showed lack of interest in the subject.',
    mauComportamento4: 'Forgot materials.',
    mauComportamento5: 'Was distracted during class.',
    mauComportamento6: 'Stood throughout the class.',
    mauComportamento7: 'Showed lack of empathy towards colleagues.',
    mauComportamento8: 'Showed lack of organization.',
    mauComportamento9: 'Showed anxiety during tests or evaluations.',
    mauComportamento10: 'Did not accept criticism.',
    mauComportamento11: 'Did not complete homework tasks.',
    mauComportamento12: 'Did not stop talking the whole class.',
    mauComportamento13: 'Did not want to work in a group.',
    mauComportamento14: 'Did not respect the classroom rules.',
    mauComportamento15: 'Cheated during the evaluation.',
    mauComportamento16: 'Had difficulty accepting the authority of colleagues.',
    mauComportamento17: 'Had difficulty accepting divergent opinions.',
    mauComportamento18: 'Used inappropriate language.',
    mauComportamento19: 'Used the cell phone during class.',
    mauComportamento20: 'Wore inappropriate clothes for school.',
    outroEspecifique: 'Other (Specify below).',
    bomFicarDeOlho1: 'Showed lack of self-confidence.',
    bomFicarDeOlho2: 'Slept during class.',
    bomFicarDeOlho3: 'Was isolated from colleagues.',
    bomFicarDeOlho4: 'Was the target of bullying.',
    bomFicarDeOlho5: 'Was a victim of discrimination.',
    bomFicarDeOlho6: 'Had difficulty controlling impulses.',
    bomFicarDeOlho7: 'Had difficulty expressing emotions.',
    bomFicarDeOlho8: 'Had difficulty following instructions.',
    historicoDeAcessos: 'Access History',
    matriculado: 'Enrolled',
    eMailDeAcessoEnviadoComSucesso: 'Access email sent successfully!',
    erroAoEnviarEmail: 'There was a problem sending the email. Check your connection and try again.',
    colobadorJaExiste:'Collaborator not registered! A record with the same email already exists.',
    erroAoSalvarAlteracoes: 'Could not save changes. Check your connection and try again.',
    erroAoCadastrarColaborador: 'There was a problem registering the collaborator. Check your connection and try again.',
    alteracaoConcluidaComSucesso: 'The change was successfully completed!',
    colaboradorCriadoComSucesso: 'Collaborator created successfully!',
    mensagemErroNomeColaborador: 'Please, inform the name of the collaborator!',
    insiraONomeCompleto: 'Enter the full name',
    insiraONomeDoColaborador: 'Enter the name of the collaborator',
    email: 'Email',
    mensagemAlteracaoEmail: 'If you need to change your email, please contact our support to continue the change process. We are here to help!',
    mensagemErroEmail: 'Please, inform the email!',
    exemploEmail: 'example@example.com',
    mensagemErroCpf: 'Please, inform the cpf number!',
    cpf: 'CPF',
    mensagemErroPerfil: 'Please, select the profile!',
    selecioneOPerfilCorrespondente: 'Select the corresponding profile',
    alteracaoDeSenha: 'Password Change',
    novaSenha: 'New Password',
    insiraANovaSenha: 'Enter the new password',
    reenviarEmailDeAcesso: 'Resend access email',
    importacaoDeColaboradoresConcluidaComSucesso: 'Collaborator import completed successfully!',
    erroAoImportarColaboradores: 'There was a problem importing the collaborators. Check your connection and try again.',
    mensagemImportacaoColaboradores: 'If you want to register multiple collaborators at once, we offer the option to import data through a table. For this, the spreadsheet must follow the example of the provided model. If a collaborator does not have the "profile" field filled, it will automatically be considered as "educator".',
    status: 'Status',
    oCadastroDoColaborador: 'the collaborator registration?',
    oCadastroDoColaboradorFoi: 'The collaborator registration was',
    desativado: 'disabled',
    ativado: 'enabled',
    voceNaoTemPermissaoPara: 'You do not have permission to',
    doColaborador: 'of the collaborator',
    formatoDeImagemInvalido: 'Invalid image format',
    tamanhoDaImagemInvalido: 'Invalid image size',
    erroAoFazerUpload: 'Error uploading!',
    formatosAceitos: 'Accepted formats',
    tamanhoMaximoDoArquivo: 'Maximum file size',
    carregarFoto: 'UPLOAD PHOTO',
    editadoComSucesso: 'successfully edited.',
    cadastradoComSucesso: 'successfully registered.',
    mensagemErroNomePerfil: 'Please, enter the profile name!',
    atribuaUmNomeParaEssePerfil: 'Assign a name for this profile',
    gerenciamentoDePermissoes: 'Permission Management',
    nomeDoPerfil: 'Profile Name',
    acessoCompleto: 'Full Access',
    dataDeCriacao: 'Creation Date',
    temCertezaQueDesejaExcluirOPerfilDe: 'Are you sure you want to delete the profile of',
    perfilExcluidoComSucesso: 'Profile successfully deleted!',
    erroAoExcluirPerfil: 'There was a problem deleting the profile. Check your connection and try again.',
    perfilAtualizadoComSucesso: 'Profile successfully updated!',
    perfilDaInstituicao: 'Institution Profile',
    salvarAlteracoes: 'Save Changes',
    informacoesDaInstituicao: 'Institution Information',
    razaoSocial: 'Company Name',
    porFavorInformeSeuNomeCompleto: 'Please, inform your full name!',
    porFavorInformeONumeroDoCnpj: 'Please, inform the CNPJ number!',
    mensagemErroTelefoneInstituicao: 'Please, inform the institution phone!',
    nomeFantasia: 'Trade Name',
    trocarDirecao: 'Change Direction',
    direcaoEContato: 'Direction and Contact',
    nomeDoDiretor: 'Director Name',
    mensagemErroCep: 'The ZIP code must have 8 digits',
    campoObrigatorio: 'Mandatory field!',
    logradouro: 'Street',
    mensagemErroLogradouro: 'Please, inform the school street!',
    ruaAvenidaTravessa: 'Street, Avenue, Cross...',
    mensagemErroBairro: 'Please, inform the district!',
    nomeDoBairro: 'District Name',
    aptoCasa: 'Apt, house...',
    mensagemErroEstado: 'Please, inform the state!',
    mensagemErroCidade: 'Please, inform the city!',
    progressoDoPerfil: 'Profile Progress',
    menosDetalhes: 'Less Details',
    pesquisar: 'Search',
    filtradoPor: 'Filtered by',
    consulteAListaDeColaboradoresCadastrados: 'Check the list of registered collaborators and select the school director',
    visaoGeral: 'Overview',
    catalogo: 'Catalog',
    informacoesDoJogo: 'Game Information',
    atividades: 'Activities',
    geografia: 'Geography',
    historia: 'History',
    matematica: 'Mathematics',
    duracao: 'Duration',
    temaExtraAbordado: 'Extra Topic Covered',
    faixaEtaria: 'Age Range',
    anosMais: 'years +',
    desfavoritar: 'Unfavorite',
    favoritar: 'Favorite',
    testarJogo: 'Test Game',
    objetivo: 'Objective',
    autor: 'Author',
    esteJogoFoiDesenvolvidoPor: 'This open source game was developed by',
    agradecemosAoAutorPorSeuTrabalho: 'We thank the author for their work!',
    desenvolvidoPelaEquipe: 'Developed by the U4Hero team, this game is an exclusive creation.',
    carregandoJogo: 'Loading game...',
    mensagemPreviaConcluida:' We hope you enjoyed this sample! When you\'re ready, click "Conclude" to end the preview.',
    concluir: 'Conclude',
    previaDoJogo: 'Game Preview',
    estrategia: 'Strategy',
    puzzle: 'Puzzle',
    simulacao: 'Simulation',
    classificacao: 'Classification',
    jogosJaUtilizados: 'Games already used',
    jogosNaoUtilizados: 'Games not used',
    duracaoDoJogo: 'Game Duration',
    ate: 'Until',
    temasExtras: 'Extra Themes',
    limiteDePerguntas: 'Question Limit',
    favoritos: 'Favorites',
    acompanheOsIndicadores: 'Follow the Indicators and News of the Games in Real Time',
    jogosSendoUtilizados: 'Games being used',
    taxaDeParticipacao: 'Participation Rate',
    emBreve: 'Coming soon',
    ultimosAcessos: 'Last Accesses',
    novidades: 'News',
    mensagemCarroselJogos:'Discover a new universe of learning through games, where your students can develop socio-emotional skills in an engaging and fun way.',
    feedbacks: 'Feedbacks',
    centralDePerguntas: 'Question Center',
    enunciado: 'Statement',
    habilidadesCamposDeConhecimento: 'Skills/Knowledge Fields',
    escopo: 'Scope',
    tooltipPrivada: 'Private questions can only be used by the question author and the school administrator.',
    tooltipPublica: 'Public questions can be used by all school members.',
    tooltipGlobal: 'Global questions can be used by all U4Hero schools.',
    aPergunta: 'the question',
    aPerguntaFoi:'The question was',
    voceNaoTemPermissaoParaAtivarEstaPergunta: 'You do not have permission to change the status of this question!',
    feedback: 'Feedback',
    muitoRuim: 'Very Bad',
    ruim: 'Bad',
    regular: 'Regular',
    bom: 'Good',
    muitoBom: 'Very Good',
    boa: 'Good',
    categoria: 'Category',
    data: 'Date',
    icone: 'Icon',
    avaliacao: 'Evaluation',
    aPartirDe: 'From',
    naoHa: 'There is no',
    privada: 'Private',
    publica: 'Public',
    global: 'Global',
    competenciaHabilidade: 'Competence/Skill',
    ambienteAnalise: 'Analysis Environment',
    tipoAnalise: 'Analysis Type',
    tipoPergunta: 'Question Type',
    muitoBaixa: 'Very Low',
    baixa: 'Low',
    moderado: 'Moderate',
    muitoBoa: 'Very Good',
    discordoCompletamente: 'Strongly Disagree',
    discordo: 'Disagree',
    neutro: 'Neutral',
    concordo: 'Agree',
    concordoCompletamente: 'Strongly Agree',
    detalhesDaPergunta: 'Question Details',
    mensagemErroTipoAnalise: 'Please select what the question will be evaluating!',
    mensagemErroAmbienteAnalise: 'Please select the analysis environment!',
    mensagemErroFaixaEtaria: 'Please determine the age range, between 5 and 18 years!',
    aplicavelEm: 'Applicable in',
    saibaMaisSobreAsFaixasEtarias: 'Learn more about the age ranges',
    mensagemErroEnunciado: 'Please create a statement!',
    digiteAafirmaçãoDaEscalaLikertAqui: 'Enter the Likert scale statement here',
    placeholderLikert: 'Enter a statement for respondents to indicate their degree of agreement or disagreement.',
    placeholderEnunciado: 'Create the question statement',
    mensagemErroTipoAfirmaçãoLikert: 'Please define the type of Likert statement.',
    estaAfirmaçãoÉ: 'This statement is',
    positiva: 'Positive',
    negativa: 'Negative',
    imagemPNGouJPGate3Mb: 'PNG or JPG image up to 3Mb.',
    alternativa: 'Alternative',
    excluirA: 'exclude the',
    alternativaExcluidaComSucesso: 'Alternative successfully excluded!',
    mensagemErroAlternativa: 'Please create an alternative for the question!',
    mensagemErroCompreensao: 'Please evaluate the comprehension level of the alternative!',
    grauDeCompreensao: 'Comprehension Level',
    gerar:'Generate',
    sugestoes:'Suggestions',
    sugestao:'Suggestion',
    alternativas:'Alternatives',
    mensagemErroSalvarSemAlteracoes: 'Make some changes before saving!',
    mensagemValidacaoAlternativas: 'To ensure the quality of the question, it is necessary to include at least two alternatives with different comprehension levels, such as "very low" and "very good". This helps to evaluate more accurately',
    erroAoCriarPergunta: 'There was a problem creating the question. Check your connection and try again.',
    perguntaCriadaComSucesso: 'Question created successfully!',
    formatoDaResposta: 'Response Format',
    mensagemErroFormatoDaResposta: 'Please select the response format!',
    escalaLikert: 'Likert Scale',
    alternativasPersonalizadas: 'Customized Alternatives',
    areaDeAnalise: 'Analysis Area',
    definaAreaDeAnalise: 'Define the analysis area!',
    competencia: 'Competence',
    focoDeAnaliseDoClimaEscolar: 'Focus of School Climate Analysis',
    escolhaDoTipoDeTreinamento: 'Choice of Training Type',
    mensagemErroCompetencia: 'Please select a competence!',
    mensagemErroHabilidade: 'Please select a skill!',
    mensagemErroClimaEscolar:'Please define the focus of school climate analysis!',
    mensagemErroTreinamento: 'Please define the type of training!',
    mensagemErroAreaDefoco:'Please define the focus area',
    mensagemDuasCompetencias:'You have already selected two competencies, it is not necessary to select a skill!',
    informacoesAdicionais: 'Additional Information',
    mensagemErroTipoPergunta: 'Please select what type of question!',
    mensagemErroEscopo: 'Please define the scope of the question',
    treinamento: 'Training',
    climaEscolar: 'School Climate',
    pesquisa: 'Research',
    mensagemErroAreaDeAnalise: 'Please define the desired analysis area',
    selecioneAreaDeAnaliseDesejada: 'Select the desired analysis area:',
    atividade: 'Activity',
    situacional: 'Situational',
    moderada: 'Moderate',
    invalido: 'Invalid',
    modoVisualizacao: 'View Mode',
    dublagem: 'Dubbing',
    desativou: 'Disabled',
    ativou: 'Enabled',
    movimentacao: 'Movement',
    explicacaoFaixaEtaria:'When creating a question, it is crucial to select the appropriate age range to ensure the content is suitable for the students\' age and development.',
    explicacaoFaixaEtaria2:'Moreover, it is important to observe the specific rules to direct the question to the correct class.',
    à:'to',
    atividadeDaTurma:'Class Activity', 
    atividadeIndividual:'Individual Activity',
    avaliacaoDaTurma:'Class Evaluation',
    avaliacaoIndividual:'Individual Evaluation',
    responsavel:'Responsible',
    qtd:'Amnt.',
    tempoRestante:'Time Remaining',
    voceNaoEoResponsavelPelaAtividade:'You are not the responsible for the activity!',
    voceNaoEoResponsavelPelaAutomacao:'You are not the responsible for the automation!',
    excluirAtividadeConfirmacao:'Are you sure you want to exclude the activity?',
    atividadeExcluidaComSucesso:'The activity was successfully excluded!',
    erroAoExcluirAtividade:'There was a problem excluding the activity. Check your connection and try again.',
    dataDeInicio:'Start Date',
    dataDeTermino:'End Date',
    pesquisarPeloResponsavel:'Search by Responsible',
    frequencia:'Frequency',
    excluirAutomacaoConfirmacao:'Are you sure you want to exclude the automation?',
    automacaoExcluidaComSucesso:'The automation was successfully excluded!',
    erroAoExcluirAutomação:'There was a problem excluding the automation. Check your connection and try again.',
    automacao:'Automation',
    prazo:'Deadline',
    definaOPrazodaAtividade:'Define the activity deadline:',
    anterior:'Previous',
    proximo:'Next',
    registrosDoMeuCantinho:'Records of My Corner',
    naoHaNenhumRegistroNoMeuCantinho:'There are no records in My Corner, there is no information to be displayed!',
    vejaVersaoCompleta:'See full version',
    selecioneAsTurmasASerAplicada:'Select the class(es) the activity will be applied to',
    selecioneOsEstudantesQueATividadeSeraAplicada:'Select the student(s) the activity will be applied to',
    selecioneUmJogoParaATividade: 'Select a game for the activity',
    minutos: 'minutes',
    utilizado: 'Used',
    naoUtilizado: 'Not Used',
    vez: 'time',
    competenciasEHabilidades: 'Competencies and Skills',
    selecioneOQueSeraAbordado: 'Select what will be addressed',
    selecione:'Select',
    as:'the',
    perguntasQueSeraoInseridasNaAtividade:'Questions that will be inserted into the activity',
    selecioneOTipoDePerguntaDesejada:'Select the desired question type:',
    bancoDePerguntas:'Question Bank',
    bancoDePerguntasVazio:'There are no more questions available for display. Please create more questions to expand the options in the database.',
    selecioneOTipoDePerguntaParaVisualizarAsOpcoesDisponiveis:'Select the question type to view the available options!',
    analise:'Analysis',
    ambiente:'Environment',
    ateAgoraNenhumaPerguntaFoiEscolhida:'So far, no question has been chosen. Please select questions from the database to view them here.',
    visualizacaoDasAlternativas:'Visualization of Alternatives',
    anonimo:'Anonymous',
    automatica:'Automatic',
    registro:'Record',
    lidas:'Read',
    naoLidas:'Unread',
    naoIniciado: 'Not Started',
    informacoesSalvasComSucesso: 'Information saved successfully!',
    acessoNegado: 'Access Denied!',
    mensagemAcessoNegadoMeuCantinho:'You have received a new message in the “My Corner”. However, you are no longer linked to this class and cannot access the message.',
    voltarParaPaginaInicial:'BACK TO HOME PAGE',
    cantinhoDoEstudante:'Student Corner',
    registroMeuCantinho: 'My Corner Record',
    statusDoAcolhimento: 'Reception Status',
    vocenãoTemPermissãoParaEditarEsteAcolhimento: 'You do not have permission to edit this reception!',
    classificacaoDoEducador: 'Educator Classification',
    classificacaoAutomatica: 'Automatic Classification',
    classificacaoSocioemocional: 'Socio-Emotional Classification',
    classificacaoDoMeuCantinho: 'My Corner Classification',
    justificativaDaClassificacao: 'Classification Justification',
    expressoesCoracaoQuentinho: 'Warm Heart Expressions',
    expressoesBomFicarDeOlho: 'Good to Keep an Eye Expressions',
    expressoesRedobreAAtencao: 'Redouble Attention Expressions',
    expressoesProblemaSerio: 'Serious Problem Expressions',
    mensagemErroComentario: 'Oops, there was a problem posting your comment. Check your connection and try again!',
    comentarioPostadoComSucesso: 'Comment posted successfully!',
    comentarioNaoPodeSerVazio: 'The comment cannot be empty!',
    nenhumComentarioAteAgora: 'No comments yet, be the first to comment!',
    insiraSeuComentario: 'Insert your comment',
    preenchaAMensagemAntesDeEnviar: 'Fill in the message before sending!',
    mensagemErroEnviarMensagem: 'Oops, there was a problem sending your message. Check your connection and try again!',
    mensagemEnviadaComSucesso: 'Message sent successfully!',
    chatAssincrono: 'Asynchronous Chat',
    enviarMensagemAcolhimento: 'Send a welcome message to this student!',
    insiraSuaMensagem: 'Insert your message',
    meuPainel: 'My Panel',
    visaoEducador: 'Educator View',
    visaoGestor: 'Manager View',
    visaoOrganizacao: 'Organization View',
    painelDeIndicadores: 'Indicator Panel',
    temCertezaQueDesejaExcluirOIndicador: 'Are you sure you want to exclude the indicator',
    indicadorExcluidoComSucesso: 'The indicator was successfully excluded!',
    mensagemErroSalvarIndicador: 'There was a problem saving the indicator. Check your connection and try again.',
    totalDeEstudantes: 'Total Students',
    totalDeTurmas: 'Total Classes',
    totalDeProfessores: 'Total Teachers',
    totalDeColaboradores: 'Total Collaborators',
    entendaSobreAsCompetenciasEHabilidades: 'Understand about Competencies and Skills',
    emocionometroDosUltimos7Dias: 'Emotionometer of the Last 7 Days',
    graficoDuplicadoComSucesso: 'Graph duplicated successfully! You are now editing a copy. Make the necessary changes and save to create a new graph.',
    maisGraficos: '+ Graphs',
    personalizeSeuPainelDeIndicadores: 'Personalize your Indicator Panel',
    exploreAPersonalizacaoDoSeuPainelDeIndicadores:'Explore the personalization of your indicator panel! Click the button below to start building your panel and choose the indicators you want to visualize. Give life to your data and have relevant insights about the socio-emotional development of students.',
    iniciarPersonalizacao: 'Start Personalization',
    temporal: 'Temporal',
    ano: 'Year',
    copiar: 'Copy',
    frequenciaDosEstudantes: 'Student Frequency',
    sugestoesParaVoce: 'Suggestions for you',
    visaoDaEscolaAtividades: 'School View - Activities',
    atividadesNaEscola: 'Activities in School',
    indicadoresAtualizados: 'Updated Indicators',
    indicadoresIncluidos: 'Included Indicators',
    incluir: 'include',
    osIndicadores: 'the indicators',
    adicionarIndicadores: 'Add Indicators',
    frequenciaDosEducadores: 'Teacher Frequency',
    opcoesPreCriadas: 'Pre-Created Options',
    personalizacaoDosGraficos: 'Graph Personalization',
    anoDeAnalise: 'Analysis Year',
    mensagemErroAnoDeAnalise: 'Please define the year to be considered!',
    definaOsIndicadores: 'Please define the indicators!',
    indicadores: 'Indicators',
    escolhaOsIndicadores: 'Choose the indicators',
    estiloDoGrafico: 'Graph Style',
    mensagemErroEstiloDoGrafico: 'Please choose the graph type!',
    nomeDoGrafico: 'Graph Name',
    mensagemErroNomeDoGrafico: 'Please create a name for this graph!',
    preenchaOsCamposParaContinuarCriandoOGráfico: 'Fill in the fields to continue creating the graph',
    crieUmNomeParaSeuGráfico: 'Create a name for your graph',
    categoriaASerAAnalizada: 'Category to be analyzed',
    mensagemErroCategoriaASerAAnalizada: 'Please define the category to be analyzed!',
    avaliacoesIniciais: 'Initial Evaluations',
    avaliacoes: 'Evaluations',
    definicaoDoPeriodoDeTempo: 'Definition of the Time Period:',
    mensagemErroDefinicaoDoPeriodoDeTempo: 'Please define the time period!',
    anual: 'Annual',
    bimestral: 'Bi-Monthly',
    trimestral: 'Tri-Monthly',
    quadrimestral: 'Quadri-Monthly',
    semestral: 'Semi-Annual',
    comparativo1: 'Comparative 1',
    comparativo2: 'Comparative 2',
    mensagemErroComparativo1: 'Please select comparative 1!',
    mensagemErroComparativo2: 'Please select comparative 2!',
    bimestre: 'Bi-Month',
    trimestre: 'Tri-Month',
    quadrimestre: 'Quadri-Month',
    semestre: 'Semi-Annual',
    modoDeComparacao: 'Comparison Mode',
    mensagemErroModoDeComparacao: 'Please define the comparison mode!',
    periodoDeTempo: 'Time Period',
    estudanteVersusTurma: 'Student versus Class',
    modoDeVisualizacaoDoGrafico: 'Graph Visualization Mode:',
    mensagemErroModoDeVisualizacaoDoGrafico: 'Please define the graph visualization mode!',
    selecioneASerie: 'Select the series',
    mensagemErroTurma: 'Please select the class!',
    mensagemErroSelecioneEstudante: 'Please select the student!',
    graficoDeLinha: 'Line Graph',
    graficoDeArea: 'Area Graph',
    graficoDeBarra: 'Bar Graph',
    graficoDeRadar: 'Radar Graph',
    graficoDePizza: 'Pie Graph',
    graficoDeDonut: 'Donut Graph',
    graficoDeColuna: 'Column Graph',
    graficoDeColunaEmpilhada: 'Stacked Column Graph',
    dadosASeremExibidosReferenteA: 'Data to be displayed, referring to:',
    mensagemErroDadosASeremExibidos: 'Please define the data to be displayed!',
    mensagemErroPeriodo: 'Please define the period!',
    mes: 'Month',
    nEstudantes: 'Nº Students',
    selecioneOPeriodoRelatorio: 'Select the period you want to generate the report.',
    dataFinal: 'End date',
    selecioneORelatorioQueDesejaGerar: 'Select the report you want to generate.',
    relatorioEmocaoGeradoComSucesso: 'Emotion report generated successfully!',
    emocao: 'Emotion',
    dataPublicacao: 'Publication date',
    estudanteS: 'student(s)',
    estudante: 'student',
    turmaS: 'class(es)',
    comentarioPublicado: 'Comment published!',
    oComentarioNaoPodeSerVazio: 'The comment cannot be empty.',
    escrevaSeuComentario: 'Write your comment.',
    naoGostei: 'Did not like',
    gostei: 'Liked',
    respostaDoEstudante: 'Student response',
    respostas: 'Answers',
    obrigadoPeloSeuFeedback: 'Thank you for your feedback!',
    opsHouveAlgumErroTenteNovamente: 'Oops, there was an error, try again!',
    inspiracoesPedagogicas: 'Pedagogical Inspirations',
    gerenciarInspracoes: 'MANAGE INSPIRATIONS',
    inspiracoesNoTotal: 'Total Inspirations',
    novasInspracoes: 'New Inspirations',
    verTudo: 'See all',
    min: 'min',
    novo: 'New',
    dificuldade: 'Difficulty',
    sugestoesDeInspiracoesPedagogicas: 'Suggestions for Pedagogical Inspirations',
    pesquisarPorNomeDaInspiracao: 'Search by inspiration name',
    recursos: 'Resources',
    disciplinaRelacionada: 'Related Discipline',
    arte: 'Art',
    ciencias: 'Science',
    educacaoFisica: 'Physical Education',
    linguaInglesa: 'English Language',
    linguaPortuguesa: 'Portuguese Language',
    modalidade: 'Modality',
    publicado: 'Published',
    rascunho: 'Draft',
    inspiracoes: 'Inspirations',
    inspiracao: 'Inspiration',
    titulo: 'Title',
    ultimaAtualizacao: 'Last update',
    temCertezaQueDesejaExcluirAInspiracaoPedagogica: 'Are you sure you want to delete the pedagogical inspiration?',
    inspiracaoPedagogicaExcluidaComSucesso: 'The pedagogical inspiration was successfully deleted!',
    erroAoExcluirInspiracaoPedagogica: 'There was a problem deleting the pedagogical inspiration. Check your connection and try again.',
    temCertezaQueDesejaExcluirAsInspiracoesPedagogicas: 'Are you sure you want to delete the pedagogical inspirations?',
    inspiracoesPedagogicasExcluidasComSucesso: 'The pedagogical inspirations were successfully deleted!',
    erroAoExcluirInspiracoesPedagogicas: 'There was a problem deleting the pedagogical inspirations. Check your connection and try again.',
    avaliacaoGeral: 'General Evaluation',
    temCertezaQueDesejaDesfazerAsAlteracoes: 'Are you sure you want to undo the changes?',
    alteracoesDesfeitasComSucesso: 'Changes successfully undone!',
    erroAoDesfazerAlteracoes: 'Could not undo the changes. Check your connection and try again.',
    personalizeEssaPraticaDeAcordoComSeuContexto: 'Personalize this practice according to your context!',
    adaptarInspiracao: 'Adapt Inspiration',
    essaPraticaPedagogicaFoiAlterada: 'This pedagogical practice has been changed,',
    cliqueAqui: 'click here',
    seDesejaDesfazerAsAlteracoesEDesfazerParaAPraticaOriginal: 'if you want to undo the changes and return to the original practice!',
    erroAoSalvarFeedback: 'Oops, there was a problem saving your feedback, try again!',
    comoVoceAvaliaEssaPraticaPedagogica: 'How do you rate this pedagogical practice?',
    alteracaoRealizadaComSucesso: 'Change successfully made!',
    erroRecursoInspiracao: 'Please describe the purpose of the pedagogical inspiration!',
    placeholderRecursos:'List the materials that will be necessary to carry out the pedagogical inspiration, if there are none, write "none"....',
    erroConteudoInspiracao: 'Please write the step by step of the inspiration!',
    formatoArquivoInvalido: 'Invalid file format',
    tamanhoArquivoInvalido: 'Invalid file size',
    cliqueOuArrasteUmaImagem: 'Click or drag an image',
    paraAdicionarAoFinalDoDocumento: 'to add to the end of the document',
    ouArquivo: 'or file',
    notificacoes: 'Notifications',
    ajuda: 'Help',
    metricas: 'Metrics',
    instituições: 'Institutions',
    publicacoes: 'Publications',
    loja: 'Store',
    sejaBemVindoÀU4Hero: 'Welcome to U4Hero!',
    introducaoU4hero: 'We are the first socio-emotional game platform in Brazil, helping educators understand how emotions impact students\' school and social performance. We offer fun digital games and personalized learning paths. Come and get to know our platform!',
    vamosLa: 'Let\'s go!',
    jaConheco: 'I already know!',
    descubraMaisTarde: 'Discover later!',
    descubraMaisTexto:'You can explore again, or at another time, the resources of our platform. To access it is simple: just click on your profile picture in the menu and select "Explore Resources", or through the',
    centralDeAjuda: 'Help Center',
    obrigadoPorFazerParteDaNossaComunidade: 'Thank you for being part of our community and we hope that your journey with us will be successful!',
    pular: 'Skip',
    continuar: 'Continue',
    terminar: 'Finish',
    mensagemTourConcluido:'You have explored all the resources of our platform! We are happy to have you with us. Now that you know all the features, make the most of your professional journey',
    detalhamentoDeEstudantesPorEmoções: 'Student Detail by Emotions',
    autenticacaoDeDoisFatoresFoi: 'Two-factor authentication',
    ativada: 'activated',
    desativada: 'deactivated',
    codigoAutenticacaoInvalido: 'The authentication code is invalid or expired!',
    a: 'a',
    daAutenticacaoDeDoisFatoresFalhouPorfavorInserirOCodigoEnviadoPor:'of two-factor authentication failed. Please enter the code sent by email to complete the deactivation process.',
    ativacao: 'activation',
    desativacao: 'deactivation',
    teEnviamosUmNovoCodigoChequeSeuEmail: 'We sent you a new code, check your email!',
    erroAoEnviarCodigo: 'There was a problem sending the code. Try again.',
    segurancaReforcada: 'Enhanced Security!',
    desativacaoDaAutenticacao: 'Deactivation of Authentication!',
    mensagem2FaAtivado: 'You activated two-factor authentication. An email with the security code has been sent to you',
    mensagem2FaDesativado: 'You deactivated two-factor authentication. Check your email to enter the security code and complete the process.',
    digiteAquiOCodigoQueTeEnviamosPeloEmail: 'Enter the code we sent you by email.',
    verificar: 'Verify',
    recebimentoDoSeuCodigoPodeLevarAteUmMinuto: 'Receiving your code may take up to a minute. If you haven\'t received it,',
    cliqueAquiParaSolicitarUmNovoCodigo: 'click here to request a new code',
    acompanhamentoDaAtividade: 'Activity Tracking',
    totalDePerguntas: 'Total Questions',
    dias: 'days',
    perguntasEducador: 'Educator Questions',
    perguntasPlataforma: 'Platform Questions',
    progressoDaTurma: 'Class Progress',
    media: 'Average',
    naoHaNotificacoes: 'No notifications',
    marcarTodasComoLidas: 'Mark all as read',
    maisRecentes: 'Most recent',
    mostrarApenasNaoLidas: 'Show only unread',
    guiaRapido: 'Quick Guide',
    irParaCentralDeAjuda: 'Go to Help Center',
    inicieOTourExplicativoDessaTela: 'Start the explanatory tour of this screen',
    desejaIniciarOTour: 'Do you want to start the tour',
    meuPerfil: 'My Profile',
    mensagemErroSalvarAlteracoes:'There was a problem saving the changes. Please, check your connection and try again.',
    informacoesPessoais: 'Personal Information',
    contato: 'Contact',
    seguranca: 'Security',
    autenticacaoDeDoisFatores: 'Two-factor Authentication',
    alterarSenha: 'Change Password',
    mensagemAlteracaoSenha: 'Your password has been changed successfully. Please, log in again to continue accessing your account with the new password.',
    mensagemErroSenhaAtual: 'The current password entered does not match the correct password.',
    mensagemErroAlteracaoSenha :'There was a problem changing the password. Please, check your connection and try again.',
    senhaAtual: 'Current Password',
    mensagemErroSenhaAtualObrigatorio: 'Please, enter your current password!',
    mensagemErroNovaSenhaObrigatorio: 'Please, enter your new password!',
    mensagemErroSenhaMinimoCaracteres: 'Password must have at least 12 characters',
    mensagemErroSenhaCaracteresEspeciais: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character',
    confirmarSenha: 'Confirm Password',
    mensagemErroSenhaConfirmacao: 'Please, confirm your password!',
    mensagemErroSenhasDiferentes: 'Oops, the passwords are not the same!',
    confirmarAlteracao: 'Confirm Change',
    blogDeNovidades: 'News Blog',
    criarEstudante: 'Create Student',
    criarAtividade: 'Create Activity',
    camposDeConhecimentosEHabilidades: 'Fields of Knowledge and Skills',
    oQueAchouDasNovidades: 'What did you think of the news?',
    paraNosAjudarAMelhorarPorFavorExpliqueOMotivoDaSuaAvaliacao: 'To help us improve, please explain the reason for your evaluation:',
    expliqueOMotivoDaSuaAvaliacaoComOMaximoDeDetalhesPossivel: 'Explain the reason for your evaluation with as much detail as possible',
    leiaMais: 'Read more',
    sejaOProtagonistaDaSuaHistoriaConecteSeComU4hero: 'Be the protagonist of your story, connect with U4hero!',
    mensagemErroSenha: 'Please enter your password!',
    esqueceuSuaSenha: 'Forgot your password? Click here!',
    mensagemErroEmailSenha: 'Oops! It seems the email and/or password entered are incorrect.',
    cadastroRealizadoComSucesso: 'Your registration was successful!',
    erroAoSalvarAcessoRapido: 'Error saving quick access',
    acessoRapido: 'Quick Access',
    nomeOuApelido: 'Name or Nickname',
    mensagemErroNomeOuApelido: 'Please enter your name or nickname!',
    emailNaoExistente: 'Email does not exist',
    emailEnviado: 'Email sent!',
    mensagemErroEmailSenhaInstrucoes: 'We sent an email with instructions for you to create a new password!',
    esqueceuSuaSenhaPergunta: 'Forgot your password?',
    mensagemRecuperarSenhaInstrucoes: 'Don\'t worry! Please enter your registered email on U4hero and we will send instructions to create a new password.',
    linkExpirado: 'Link Expired',
    mensagemLinkExpirado: 'The time to activate your registration has expired. If you still want to complete it, please contact the administrator of your school to generate a new activation link.',
    mensagemLinkExpiradoCadastro: 'The link to finalize your registration has expired, it seems you completed the registration previously. Please contact us if you need help accessing your account.',
    entendi: 'Understood',
    mensagemCadastroQuasePronto: 'You\'re almost ready to start your journey with U4hero!',
    mensagemErroNome: 'Please enter your name!',
    placeholderNome: 'Enter your name here',
    senhaRedefinida: 'Password Redefined!',
    mensagemSenhaRedefinida: 'Great! Your password was redefined successfully. Now you can access your account with your new password.',
    redefinaSuaSenha: 'Redefine your password!',
    mensagemRedefinirSenhaInstrucoes: 'Please create a new password following the guidelines provided below',
    permissaoPara: 'Permission for',
    recursoExcluidoComSucesso: 'Resource successfully removed!',
    erroAoExcluirRecurso: 'There was a problem removing the resource. Please, check your connection and try again.',
    recurso: 'Resource',
    temCertezaQueDesejaExcluirORecurso: 'Are you sure you want to remove the resource',
    placeholderNomeRecurso: 'Enter the resource name',
    placeholderDescricaoRecurso: 'Create a brief description of the resource',
    mensagemErroDescricao: 'Please create a description',
    alteracoesSalvas: 'Changes saved',
    instituicoes: 'Institutions',
    ultimo7Dias: 'Last 7 days',
    ultimo15Dias: 'Last 15 days',
    ultimo30Dias: 'Last 30 days',
    ultimoAno: 'Last year',
    todoPeriodo: 'All period',
    reClass: 'Redo automatic classification',
    materiais: 'Materials',
    erroMateriais: 'Please describe the materials needed to perform the pedagogical practice!',
    instrucoes: 'Instructions',
    guiaDeInclusao: 'Inclusion Guide',
    erroGuiaDeInclusao: 'Please make an inclusion guide!',
    erroInstrucoes: 'Please write the step-by-step of the inspiration!',
    habilidade: 'Skill',
    pontosDeObservacao: 'Points of Observation',
    orientacoesParaInclusao: 'Guidelines for Inclusion',
    anexos: 'Attachments',
    codigoBNCC: 'BNCC Code',
    componenteCurricular: 'Curricular Component',
    trilhaSugerida: 'Suggested Path',
    previstoParaDurar: 'Expected to Last',
    primeira: 'First',
    segunda: 'Second',
    terceira: 'Third',
    quarta: 'Fourth',
    quinta: 'Fifth',
    Work:'Work',
    scenario:'Scenario',
    object:'Object',
    character:'Character',
    sucessReClass: 'Message sent for reclassification successfully!',
    recursoCadastrado: 'Resource registered',
    stories: 'Stories',
    preRequisitos: 'Prerequisites',
    contextoPrevio: 'Previous Context',
    enviar: 'Send',
    gerarPerguntaComIA: 'Generate Question with AI',
    descrevaOContexto: 'Describe the context',
    contexto: 'Context',
    geracaoDaPergunta: 'Question Generation',
    likert: 'Likert',
    customizadas: 'Customized',
    perguntaGerada: 'Generated Question',
    aguardeSuaPerguntaSerExibida: 'Wait, your question will be displayed here after generation.',
    mensagemErroContexto: 'Please describe the context in detail!',
    qtdDeAlternativas: 'Number of alternatives',
    mensagemErroQtdAlternativas: 'Please define the number of alternatives!',
    mensagemErroFormatoResposta: 'Please define the response format!',
    aguardePerguntaGerada: 'Wait, the question is being generated...',
    idiomaDaHistoria: 'Story Language', 
    mensagemErroIdiomaHistoria: 'Please define the story language!',
    vocePodeSelecionarNoMaximoUmaTurma:
      'You can select at most one class',
    emProcessamento: 'In Processing',
    classificacoes: 'Classifications',
    suaClassificacaoEImportante: 'Your Classification is Important',
    autoconhecimento: 'Self-knowledge',
    detalhesDasExpressesSocioemocionais: 'Details of Socioemotional Expressions',
    safeSpaceFlag0ShortDescription: 'do not address emotions, feelings, venting or any other aspect related to socioemotional education.',
    safeSpaceFlag1ShortDescription: 'bring a positive context, characterized by comments that express kindness, joy or optimism.',
    safeSpaceFlag2ShortDescription: 'contain elements that indicate concern, sadness or other signs that the person may be facing emotional challenges.',
    safeSpaceFlag3ShortDescription: 'may contain signs of negative intent or be emotionally charged, indicating the need for special and careful attention.',
    safeSpaceFlag4ShortDescription: 'address extremely serious topics, such as violence, threats, suicidal ideation or deep sadness.',
    safeSpaceFlag5ShortDescription: 'report difficulties or problems related to the use of applications, platforms, games or other technical aspects.',
    safeSpaceFlag0Description: 'These are messages that have neutral content, such as poetry, music or artistic expressions that are decontextualized. "No classification" is the term used to describe messages that do not address emotions, feelings, venting or any other aspect related to socioemotional education.',
    safeSpaceFlag1Description: 'These are messages that bring a positive context, characterized by comments that express kindness, joy or optimism, resulting in a pleasant emotional impact. Messages that fit in the "Warm Heart" category should convey warm and comforting feelings.',
    safeSpaceFlag2Description: 'These are messages that contain elements that indicate concern, sadness or other signs that the person may be facing emotional challenges. Even if the message has spelling errors or seems "positive" or "light" at first glance, it should be classified as "Good to keep an eye on" if there are signs that something is not right.',
    safeSpaceFlag3Description: 'These are messages that reflect intense feelings, such as depression, anger or demotivation. These messages may contain signs of negative intent or be emotionally charged, indicating the need for special and careful attention.',
    safeSpaceFlag4Description: 'These are messages that address extremely serious topics, such as violence, threats, suicidal ideation or deep sadness. These messages indicate a high potential risk and require immediate intervention.',
    safeSpaceFlag5Description: 'These are messages that report difficulties or problems related to the use of applications, platforms, games or other technical aspects. Examples of messages that fall into this category include: "How to play", "I cannot enter the game", "Error in the application/game".',
    emProcessamentoDescricao: 'This record is under analysis. Soon, it will receive an appropriate classification according to the review.',
    refazerClassificacaoAutomatica: 'Redo Automatic Classification',
    verDetalhesDasExpressoes: 'View Expression Details',
    acolher: 'Welcome',
    suporte: 'Support',
    naoHaNenhumRegistroNoMomento: 'There are no records at the moment',
    selecioneAClassificacao: 'Select the Classification',
    visaoRelatorio:'Report View',
    simplificado:'Simplified',
    completo:'Complete',
    periodoCompletoDe:'Complete Period of',
    classificadaPorIA:'Classified by AI',
    justificativa:'Justification',
    refazerClassificacaoPorIA:'Redo AI classification',
    IA:'AI',
    relatorioDeAvaliacao: 'Assessment Report',
    relatorioDeAtividade: 'Activity Report',
    relatorioAvaliacaoInicial: 'Initial Assessment Report',
    modeloSimplificado: 'Simplified Model',
    modeloCompleto: 'Complete Model',
    metodologiaU4hero: 'U4Hero Methodology',
    oQueSaoCompetenciasSocioemocionais: 'What are Socioemotional Competencies?',
    quaisAreasSaoImpactadasPeloSubdesenvolvimentoDasCompetenciasEmocionais: 'Which areas are impacted by the underdevelopment of Emotional Competencies?',
    indicadoresDeClimaEscolar: 'School Climate Indicators',
    tiposDeAnalise: 'Types of Analysis',
    guiaParaInterpretarOsResultadosDesteRelatorio: 'Guide to interpret the results of this report',
    resumo: 'Summary',
    dados: 'Data',
    iconesParaAvaliacao: 'Assessment Icons',
    analiseDeDados: 'Data Analysis',
    amostra: 'Sample',
    emocoesERegistros: 'Emotions and Records',
    referencias: 'References',
    referenciasBibliograficas: 'Bibliographic References',
    introducao: 'Introduction',
    esteRelatorioApresentaOsResultadosObtidosPelosEstudantesDa: 'This report presents the results obtained by students from',
    introducaoMensagemAvaliacaoInicial: 'in the Initial Assessment, which aims to map the current level of socioemotional competencies in the three analyses investigated by the U4Hero methodology, before the start of the program, as these results will serve as a basis for the next stages of work, guiding future educational actions.',
    introducaoMensagemAvaliacao: 'whose objective is to identify and monitor the development of socioemotional competencies in general throughout the program. The three analyses investigated by the U4Hero methodology (Knowledge, Behavior and Perception) serve as a basis for more targeted interventions adjusted to students\' needs, guiding future activities more assertively.',
    naAtividadeVoltadaParaDesenvolvimentoDaCompetencia: 'In the activity aimed at developing competency',
    oFocoEsteveEm: 'The focus was on',
    utilizandoUmaAbordagemBaseadaEmSituacoesProblemas: ', using a problem-situation based approach.',
    comportamentoIntroducaoMensagem: 'The analysis focused on students\' behavior when facing practical challenges, aiming to strengthen their ability to make assertive decisions.',
    conhecimentoIntroducaoMensagem: 'The analysis focused on knowledge, aiming to improve their theoretical and practical understanding of how to effectively face and resolve conflicts.',
    percepcaoIntroducaoMensagem: 'The analysis focused on students\' perception, seeking to understand how they interpret, evaluate and perceive everyday situations.',
    esteRelatorioFoiDivididoEmTresPartes: 'This report was divided into three parts. The first consists of information that helps understand the U4Hero methodology, where we clearly explain some basic concepts that need to be understood by the reader, such as "socioemotional competencies", "school climate indicators" and "types of analysis". We recommend reading this part for those who are not familiar with these concepts.',
    naSegundaParte: 'In the second part, we present a quick guide to understand the information in this report, such as what the legends represent. Finally, in the third part, we present the overall result of the School and each class.',
    metodologiaExplicacao1: 'The U4Hero methodology is an approach focused on the integral development of students, with special focus on socioemotional competencies. This methodology combines comprehensive assessments, activities, dynamics and pedagogical intervention proposals that allow for personalized intervention, evaluating students\' perception and behavior regarding these competencies, providing a holistic view of their development. U4Hero uses this triple analysis to identify both strengths and areas that need improvement, allowing personalized interventions aimed at promoting students\' emotional, social and academic growth. By integrating intentional pedagogical practices with continuous monitoring, the U4Hero methodology contributes to the formation of more resilient, autonomous individuals prepared to face contemporary life challenges.',
    metodologiaExplicacao2: 'The implementation of the U4Hero methodology has some main stages, which are the Initial Assessment, when we can identify which socioemotional competencies have the largest knowledge gap and need to be worked on, Activities, which have the objective',
    metodologiaExplicacao3: 'of strengthening already acquired competencies, and Pedagogical Inspirations, which are recommended didactic practices that help in developing the competencies that need the most attention.',
    metodologiaExplicacao4: 'Another point worked on in our methodology is understanding how the school climate is. For this, we also map some indicators that are directly related to building a safe and adequate school environment for both cognitive and socioemotional development of students. From the results of these indicators, we can have a comprehensive view of which external variables may be hindering the consolidation of students\' socioemotional and cognitive competencies, and consequently work on these indicators to improve them.',
    competenciasExplicacao1: 'Socioemotional competencies are a set of skills that involve the ability to manage emotions, establish and maintain healthy relationships, make responsible decisions, exercise self-management and deal with challenges in a balanced way. They manifest through thoughts, feelings and behaviors, contributing significantly to the integral development of individuals, directly impacting academic success, social life, mental and physical health, and future employability.',
    competenciasExplicacao2: 'These competencies include, for example, self-awareness, which allows students to recognize and understand their emotions; self-management, which involves the ability to regulate these emotions and behaviors; social awareness, which encompasses empathy and respect for others\' perspectives; relationship skills, which facilitate cooperation and conflict resolution; and responsible decision-making, which implies ethical and thoughtful choices.',
    competenciasExplicacao3: 'The development of these competencies is continuous and occurs throughout life, both in formal environments, such as school, and in informal everyday situations. They are influenced by biological and environmental factors, and their strengthening makes students capable of acting autonomously and as protagonists in their lives. When working on these competencies intentionally in the school environment, they become predictors of various positive outcomes in adult life, including well-being, school performance, employability and healthy interpersonal relationships.',
    competenciasExplicacao4: 'Therefore, the development of socioemotional competencies is inseparable from cognitive and academic development, and should be a priority in integral education. These competencies not only boost learning but also prepare students to live ethically, sustainably and fully in an increasingly complex and dynamic world.',
    areasImpactadasExplicacao1: 'Students\' socioemotional development plays a fundamental role in forming individuals capable of facing contemporary life challenges. When these competencies, which include skills such as self-management and social awareness, are not properly developed, the consequences affect not only school performance but also mental health, interpersonal relationships and prospects for success in adult life. Thus, it is necessary to understand how the lack of these competencies can negatively impact various areas of students\' lives, underlining the importance of promoting solid socioemotional development from an early age.',
    desempenhoEscolar: 'School Performance',
    areasImpactadasExplicacao2: 'The absence of developed socioemotional competencies, such as self-management and self-awareness, directly reflects on students\' academic capacity. They tend to present difficulties in fundamental subjects, such as Portuguese and mathematics, and their grades are often below the expected average. Additionally, the lack of these competencies can lead to increased absenteeism and, in more serious cases, school dropout, compromising the entire educational process. A learning environment devoid of a positive school climate',
    areasImpactadasExplicacao3: 'where relationship competencies are not adequately developed, becomes less welcoming and less conducive to effective learning.',
    saudeMentalEFisica: 'Mental and Physical Health',
    relacoesInterpessoais: 'Interpersonal Relationships',
    areasImpactadasExplicacao4: 'Students with low development in competencies such as emotional resilience face greater risks of developing mental health problems, such as anxiety and depression. The lack of self-management and solid self-awareness can trigger self-destructive behaviors and result in unhealthy life habits, contributing to problems such as obesity and substance abuse. These students also show lower adherence to medical treatments, which further aggravates physical and mental health problems.',
    areasImpactadasExplicacao5: 'Competencies related to social awareness and interpersonal relationships are fundamental for building healthy bonds. When these competencies are underdeveloped, there is an increase in the incidence of conflicts and aggressive behaviors, both in the school and family environment. The lack of social skills impairs students\' ability to establish and maintain meaningful relationships, negatively impacting their social and emotional life.',
    empregabilidadeEVidaProfissional: 'Employability and Professional Life',
    areasImpactadasExplicacao6: 'In the professional context, the consequences of underdevelopment of socioemotional competencies are equally concerning. Students who do not adequately develop these skills face greater difficulties in selection processes and have fewer chances of securing and maintaining stable jobs. Additionally, the lack of competencies such as self-management and self-awareness limits professional growth, resulting in fewer promotion opportunities and, consequently, lower salaries.',
    vidaSocialEConomica: 'Social and Economic Life',
    areasImpactadasExplicacao7: 'Finally, it is important to highlight that the underdevelopment of socioemotional competencies is associated with an increase in social inequality. Students who do not develop these skills face greater difficulties in breaking cycles of poverty and marginalization. There is also a concerning correlation between the lack of these competencies and increased rates of crime and incarceration, perpetuating problems that can extend for generations. Students with underdeveloped socioemotional competencies have a lower probability of achieving home ownership in adult life, thus perpetuating social and economic instability.',
    indicadoresClimaEscolar: 'School Climate Indicators',
    indicadoresClimaEscolarExplicacao1: 'School climate indicators help us evaluate the quality of the school environment and its impact on students\' well-being and performance. These indicators allow a broad understanding of how the school environment influences learning, socioemotional development and interactions between school community members.',
    indicadoresClimaEscolarExplicacao2: 'Educational safety is one of the main indicators, encompassing both physical and emotional safety of students. This involves the absence of violence and bullying, as well as promoting a welcoming and respectful environment where students feel protected and comfortable to learn. The quality of interpersonal relationships and coexistence is another crucial aspect, measuring how students interact with each other, with teachers and other school staff. Healthy relationships, based on respect, empathy and cooperation, are fundamental for a positive school climate, as well as promoting inclusion and diversity, ensuring that all students feel valued, regardless of their socioeconomic, ethnic or cultural backgrounds.',
    indicadoresClimaEscolarExplicacao3: 'Student engagement and sense of belonging are also important indicators. Students who feel part of the school',
    indicadoresClimaEscolarExplicacao4: 'community tend to be more motivated, actively participating in school activities and showing better academic performance. The quality of the learning environment is equally relevant, assessing whether school facilities and available resources are adequate to facilitate learning.',
    indicadoresClimaEscolarExplicacao5: 'These indicators help create and maintain a school environment that not only favors academic learning but also the integral development and well-being of students. Regular assessment of these indicators allows the school to identify areas that need improvement and implement strategies to create a healthier and more productive environment for everyone.',
    tiposDeAnalises: 'Types of Analysis',
    tiposDeAnalisesExplicacao1: 'With the goal of understanding the student holistically, our Initial Assessment and Activities have three types of analysis: knowledge, perception and behavior. These analyses offer a comprehensive and detailed view of the current level and how students are developing their socioemotional competencies.',
    tiposDeAnalisesExplicacao2: 'This analysis evaluates the level of theoretical understanding that students have about socioemotional competencies. It examines',
    tiposDeAnalisesExplicacao3: 'how much students know about concepts such as self-awareness, empathy, self-management and responsible decision-making.',
    tiposDeAnalisesExplicacao4: 'In perception, the focus is on verifying how students perceive themselves in relation to their socioemotional competencies. This analysis explores how they evaluate themselves in relation to their own capabilities, that is, how students see themselves in terms of socioemotional competencies.',
    tiposDeAnalisesExplicacao5: 'This analysis observes how students put socioemotional competencies into practice in daily life. It focuses on observable behaviors and concrete actions that demonstrate the use of these skills, such as the ability to deal with frustrations, cooperate in groups or resolve conflicts.',
    tiposDeAnalisesExplicacao6: 'The combination of these types of analysis (knowledge, perception and behavior) helps educators identify not only what students know about socioemotional competencies, but also how they perceive them and how they apply them in real situations. This offers a deeper and more complete understanding of students, allowing more precise interventions and educational strategies.',
    guiaExplicacao: 'This guide aims to help the reader clearly and efficiently interpret the results presented in this report. Below, we detail each of the parts that make up the analysis, explaining the essential elements for a complete understanding of the data and their implications.',
    resumoExplicacao: 'The "Summary" is the first part presented in our results and aims to provide an overview of the main findings, already with an initial interpretation of the data. This section is structured so that the reader has a quick and accurate understanding of students\' overall performance in the different categories evaluated.',
    principaisDescobertas: 'Main Findings',
    principaisDescobertasExplicacao: 'Here, we highlight the areas of highest and lowest performance, classifying the results according to excellence, good performance, room for improvement or need for attention. This classification is based on the percentages obtained in each competency, allowing an immediate visualization of strengths and areas that',
    precisamDeMaisFoco: 'need more focus.',
    categoriasDeAnalise: 'Analysis Categories',
    categoriasDeAnaliseExplicacao: 'We divide the results into each type of analysis we perform – Knowledge, Perception and Behavior. For each of them, we present the percentages achieved in specific competencies, such as Self-awareness, Social Awareness and Responsible Decision. These categories are analyzed to determine students\' degree of development in each competency.',
    interpretacaoDosResultados: 'Interpretation of Results',
    interpretacaoDosResultadosExplicacao: 'Each percentage is accompanied by a qualitative interpretation that helps contextualize the performance. For example, a result of 94.7% in Self-awareness is classified as excellent, reflecting students\' deep understanding of themselves, while a percentage of 55% in Behavior associated with Self-awareness indicates an immediate need for intervention.',
    conclusao: 'Conclusion',
    conclusaoExplicacao: 'At the end of the Summary, we offer a synthetic analysis that summarizes the main findings and suggests directions for future pedagogical interventions. This is where we indicate which areas need more attention in future development tracks.',
    maiorPercentualEntreOsTiposDeAnalise: 'Highest percentage among all types of analysis',
    indicacaoDoMelhorResultadoGeral: 'Indicates the best overall result obtained.',
    excelenteResultado: 'Excellent result',
    bomResultado: 'Good result',
    espacoParaMelhoria: 'Room for improvement',
    precisaDeAtencao: 'Needs attention',
    descricaoExcelenteResultado: 'Refers to results classified as excellent.',
    menorPercentualEntreOsTiposDeAnalise: 'Lowest percentage among all types of analysis',
    descricaoMenorPercentualEntreOsTiposDeAnalise: 'Highlights the lowest result achieved, requiring more attention.',
    descricaoBomResultado: 'Indicates that performance was good, but there is still room for improvement.',
    descricaoEspacoParaMelhoria: 'Signals that the competency is at an acceptable level, but needs advancement.',
    descricaoPrecisaDeAtencao: 'Alerts to critical areas that require immediate interventions.',
    maiorResultadoNesteTipoDeAnalise: 'Highest result in this type of analysis',
    descricaoMaiorResultadoNesteTipoDeAnalise: 'Indicates the best performance within a specific category.',
    precisaSerTrabalhadaNaProximaTrilha: 'Needs to be worked on in the next track',
    descricaoPrecisaSerTrabalhadaNaProximaTrilha: 'Points to areas that should be focus in the next development stages.',
    dadosDescricao: 'The "Data" section complements the Summary, presenting specific percentages obtained in each competency, accompanied by icons that facilitate visual understanding of the results.',
    iconesDeAvaliacao: 'Assessment Icons',
    iconesDeAvaliacaoDescricao: 'We use a series of icons to quickly represent performance in each area:',
    meninas: 'Girls',
    meninos: 'Boys',
    osDadosApresentadosAqui: 'The data presented here were extracted from the indicator panel graphs on the day',
    seguindoAMetodologia: ', following the U4Hero methodology, reveal insights about the development of the 3 basic socioemotional competencies: Self-awareness, Social Awareness, Responsible Decision, as well as indicators related to the Anti-bullying school climate.',
    estadoEmocional: 'Emotional State',
    emocaoPredominante: 'Predominant emotion',
    registrosNoMeuCantinho: 'Records in "My Corner"',
    apresentacaoDosResultados: 'Presentation of Results',
    apresentacaoDosResultadosDescricao: 'The data is presented in a clear and objective table, where each competency is listed with its respective percentage, accompanied by assessment icons that facilitate quick and visual interpretation.',
    centralDeConteudos: 'Content Center',
    osDadosGeraisDa: 'The general data from',
    mostramQueOsEstudantes: 'show that students',
    temUmExcelente: 'Have an excellent',
    sobre: 'about',
    e: 'and',
    bonsResultadosAvaliarmos: 'Good results when we evaluate',
    em: 'in',
    o: 'the',
    possuemEspacoParaMelhoriaEmAlgumasCompetencias: 'Have room for improvement in some competencies',
    essaAnaliseSugereQueAsProximasAtividadesETrilhasDeDesenvolvimentoDevemFocarEmFortalecer: 'This analysis suggests that the next activities and development tracks should focus on strengthening',
    relacionadoA: 'related to',
    aHabilidade: 'the ability',
    bomResultadoAoRealizarAAtividadeParaDesenvolver: 'Good result when performing the activity to develop',
    naHabilidade: 'in the ability',
    daHabilidade: 'of the ability',
    possuemEspacoParaMelhoriaAoRealizarAAtividadeParaDesenvolver: 'Have room for improvement when performing the activity to develop',
    aoRealizarAAtividadeFoiIdentificadoQue: 'When performing the activity it was identified that',
    nãoApresentouUmBomResultadoAoRealizarAAtividadeParaDesenvolver: 'did not show a good result when performing the activity to develop',
    estudantes: 'students',
    historicoDeRespostas:'History of Answers',
    relatorioSimplificado:'Simplified Report',
    camposDeConhecimentoExplicacao: `The "knowledge fields" encompass various essential areas for the development of students, such as competencies, school climate, trainings and surveys, which are referred to as analysis areas. These fields help promote the integral development of students, identifying opportunities for improvement and strengthening their skills.`,
    modoDeTeste: 'Test mode',
    modoDeTesteExplicacao: 'You are using test data. Access your account in the production environment to access real data.',
    producao: 'Production',
    clima: 'Climate',
    novaChave: 'New Key',  
    serieIndefinida: 'Undefined grade',
    semTurma: 'No class',
    ativacaoDeChave: 'Key Activation',
    visualizarPainel: 'View Dashboard',
    gestor: 'Manager',
    preparandoOCatalogoDeJogosParaVoce: 'Preparing the game catalog for you... Wait a moment!',
    hipoteses: 'Hypotheses',
    asCompetenciasQueApresentamMaiorDesafio: 'The competencies that present the greatest challenge, needing additional focus are',
    façaAlgumaAlteracaoAntesDeSalvar: 'Make some changes before saving!',
    gerarInspiracaoComIA: 'Generate inspiration with AI',
    geracaoDaInspiracao: 'Inspiration Generation',
    inspiracaoGerada: 'Inspiration Generated',
    aguardeInspiracaoGerada: 'Wait, the inspiration is being generated...',
    aguardeInspiracaoPedagogicaGerada: 'Wait, the pedagogical inspiration will be displayed here after generation.',
    resultadoDaAutoAvaliacao: 'Autoevaluation Result',
    mensagemNaoRealizouAutoAvaliacao: 'The student has not yet performed the autoevaluation, so their result is not yet available.',
    autoAvaliacao: 'Autoevaluation',
    autoAvaliation: 'Autoevaluation',
    historicoDetalhado: 'Detailed History',
    emocoesPrincipais: 'Main Emotions',
    intensidadeDasEmocoes: 'Intensity of Emotions',
    mensagemErroVisualizarEmocao:"Please define what you want to visualize!",
    totalDeEscolas:'Total of Schools',
    Feliz: 'Happy',
    Triste: 'Sad',
    Raiva: 'Angry',
    Medo: 'Fearful',
    Nojo:'Disgusted',
    intensidadeDasEmocoes7Dias: 'Intensity of Emotions - 7 days',
  },
});
