import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Modal, Row, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useQuestion from 'core/features/questions/hooks/useQuestion';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';

function ModalQuestionOptions({
  id,
  onClose,
}: {
  id?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorHover } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useQuestion(id);

  const options = ['A', 'B', 'C', 'D', 'E'];
  const marks: {
    [key: number]: {
      label: string;
      color: string;
    };
  } = {
    0: {
      label: t('app.muitoBaixa'),
      color: '#B94143B2',
    },
    25: {
      label: t('app.baixa'),
      color: '#ED810FB2',
    },
    50: {
      label: t('app.moderada'),
      color: '#FFBC00B2',
    },
    75: {
      label: t('app.boa'),
      color: '#96BE9AB2',
    },
    100: {
      label: t('app.muitoBoa'),
      color: '#2A581EB2',
    },
  };
  return (
    <Modal
      title={
        <SubTitle primary>{t('app.visualizacaoDasAlternativas')}</SubTitle>
      }
      footer={[
        <Flex align="center" justify="center">
          <Button
            id="id-tour-modal-question-options-close-button"
            size="md"
            onClick={onClose}
          >
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : data?.optionsWithImage ? 800 : 544}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      {isLoading ? <Spin size="large" /> : null}
      <Flex vertical gap={16}>
        <Flex
          style={{
            padding: '16px',
            background: hex2rgba(colorPrimary, 0.6),
            boxShadow:
              '0px 3px 4px 0px rgba(119, 67, 185, 0.25), 0px -0.5px 4px 0px rgba(119, 67, 185, 0.25)',
            borderRadius: '20px',
          }}
          gap="8px"
          vertical={data?.optionsWithImage}
        >
          <Text color="white" align="justify">
            <strong>{t('app.pergunta')}: </strong>
            {data?.text}
          </Text>
          {data?.image && (
            <img
              src={data?.image}
              style={{
                maxWidth: isMobile ? '100%' : '510px',
                alignSelf: 'center',
              }}
            />
          )}
        </Flex>

        <Row
          gutter={[
            {
              xs: 16,
              md: 32,
            },
            {
              xs: 16,
              md: 24,
            },
          ]}
          align="stretch"
        >
          {data?.options.map((item, index) => (
            <Col xs={24} md={data.optionsWithImage ? 12 : 24} key={item.id}>
              <Flex
                style={{
                  // padding: '8px 16px',
                  background: '#FFF',
                  border: `1px solid ${colorHover}`,
                  borderRadius: '20px',
                  height: '100%',
                  position: 'relative',
                }}
                vertical
                gap={8}
              >
                <Text
                  style={{
                    padding: '8px 16px',
                  }}
                >
                  <strong>{options[index]} - </strong>
                  {item.text}
                </Text>
                {item.image && (
                  <img
                    src={item.image}
                    style={{
                      maxWidth: '223px',
                      alignSelf: 'center',
                    }}
                  />
                )}

                <Text
                  color="white"
                  style={{
                    background: marks[item.value as number].color,
                    borderRadius: '0px 0px 20px 20px',
                    padding: '4px 16px',
                  }}
                >
                  <strong>{t('app.grauDeCompreensao')}: </strong>
                  {marks[item.value as number] !== undefined
                    ? marks[item.value as number].label
                    : `${item.value}`}
                </Text>
              </Flex>
            </Col>
          ))}
        </Row>
      </Flex>
    </Modal>
  );
}

export default ModalQuestionOptions;
