import { Col, Flex, Pagination, Row, Spin } from 'antd';
import { FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import dayjs from 'dayjs';
import { currentLocale } from 'core/resources/strings/polyglot';
import { t } from 'core/resources/strings';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import SelectActivityType from 'ui/pages/app/indicators/components/select-activity-type';
import ReportFilter from 'ui/pages/app/indicators/components/reportFilter';
import AppliedFilters from 'components/applied-filters';
import notStarted from 'assets/images/attention-orange.png';
import inProgress from 'assets/images/in-progress.png';
import finished from 'assets/images/finished.png';
import total from 'assets/images/total.png';
import useActivitiesReport from 'core/features/activity/hooks/useActivitiesReport';
import useActivitiesStats from 'core/features/activity/hooks/useActivitiesStats';
import TaskCard from 'ui/pages/app/indicators/tabs/components/taskCard';
import ModalActivityProgress from 'ui/pages/app/indicators/tabs/components/modalActivityProgress';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'core/features/user/store';
import { useMediaQuery } from '@chakra-ui/media-query';
import CustomCarousel from 'components/custom-carousel';
import React from 'react';

interface StatsCardProps {
  label: string;
  image: string;
  value?: number;
}

function StatsCard({ label, image, value }: StatsCardProps) {
  return (
    <Flex
      style={{
        borderRadius: 20,
        background: 'rgba(244, 244, 244, 0.80)',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
        padding: '8px 16px',
        width: '100%',
        minHeight: '96px',
      }}
      align="center"
      gap={16}
    >
      <img src={image} width={80} alt={label} />
      <Flex vertical>
        <Title primary>{value}</Title>
        <Text>{label}</Text>
      </Flex>
    </Flex>
  );
}

function ProgressOverview() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const currentYear = new Date().getFullYear();
  const { user } = useCurrentUser();
  const [type, setType] = useState<number | undefined>(3);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [activityId, setActivityId] = useState<string>();
  const [isOpenActivityProgress, setIsOpenActivityProgress] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filters, setFilters] = useState<FilterType>({
    years: [
      {
        label: currentYear.toString(),
        value: currentYear.toString(),
      },
    ],
    period: [
      {
        label: 'Anual',
        value: 0,
      },
    ],
  });
  const getDateRange = (periodValue: number) => {
    if (!filters.years?.[0]?.value)
      return { startDate: undefined, endDate: undefined };

    if (periodValue === 0 || periodValue === 10) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }

    if (periodValue >= 1 && periodValue <= 6) {
      const startMonth = (periodValue - 1) * 2;
      return {
        startDate: dayjs(
          `${filters.years?.[0]?.value}-${(startMonth + 1)
            .toString()
            .padStart(2, '0')}-01`
        )
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(
          `${filters.years?.[0]?.value}-${(startMonth + 2)
            .toString()
            .padStart(2, '0')}-01`
        )
          .endOf('month')
          .format('YYYY/MM/DD'),
      };
    }

    if (periodValue === 7) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-06-30`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }
    if (periodValue === 8) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-07-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }

    return {
      startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
        .startOf('day')
        .format('YYYY/MM/DD'),
      endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
        .endOf('day')
        .format('YYYY/MM/DD'),
    };
  };

  const dateRange = getDateRange(filters.period[0]?.value);

  const { data: stats } = useActivitiesStats({
    tenantId: tenantId ?? user?.tenantId,
    typeIds:
      type === 1 ? [1] : type === 2 ? [2, 4] : type === 3 ? [0, 3] : undefined,
    publishDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const items = [
    {
      label: 'Total',
      image: total,
      value: stats?.total,
    },
    {
      label: 'Em andamento',
      image: inProgress,
      value: stats?.inProgress,
    },
    {
      label: 'Concluído',
      image: finished,
      value: stats?.completed,
    },
    {
      label: 'Não iniciado',
      image: notStarted,
      value: stats?.notStarted,
    },
  ];

  const handleFilterChange = (newFilters: FilterType) => {
    if (
      newFilters.years?.[0]?.value !== filters.years?.[0]?.value &&
      newFilters.years?.[0]?.value !== '' &&
      newFilters.years?.[0]?.value !== undefined
    ) {
      setFilters({
        ...newFilters,
        period: [
          {
            label: 'Anual',
            value: 0,
          },
        ],
      });
    } else {
      setFilters(newFilters);
    }
  };

  const { data, isLoading } = useActivitiesReport({
    page: page,
    limit: pageSize,
    typeIds:
      type === 1 ? [1] : type === 2 ? [2, 4] : type === 3 ? [0, 3] : undefined,
    publishDate: dateRange.startDate,
    endDate: dateRange.endDate,
    tenantId,
  });

  return (
    <>
      {isOpenActivityProgress ? (
        <ModalActivityProgress
          activityId={activityId!}
          onClose={() => {
            setIsOpenActivityProgress(false);
            setActivityId(undefined);
          }}
        />
      ) : null}
      <SubTitle primary>{t('app.visaoGeralDeProgresso')}</SubTitle>

      <Flex
        gap={16}
        align="center"
        justify="flex-end"
        style={{
          marginTop: '4px',
          marginBottom: '24px',
        }}
      >
        <Flex align="center" gap={8}>
          <Text size="md">Tipo:</Text>
          <SelectActivityType
            value={type}
            defaultValue={3}
            onChange={(v) => setType(v)}
          />
        </Flex>
        <Flex gap={16} justify="flex-end" align="center">
          <ReportFilter
            filters={filters}
            onChangeFilters={handleFilterChange}
          />
          <AppliedFilters
            appliedFilters={filters}
            onChangeFilters={handleFilterChange}
          />
        </Flex>
      </Flex>

      {isMobile ? (
        <div style={{ marginBottom: '32px' }}>
          <CustomCarousel
            slidesToShow={1}
            slidesToScroll={1}
            showTotal={false}
            infinite={true}
            initialSlide={1}
            id="id-tour-modal-activity-stats"
          >
            {items.map((item, index) => (
              <StatsCard key={index} {...item} />
            ))}
          </CustomCarousel>
        </div>
      ) : (
        <Flex gap={24} justify="space-between" style={{ marginBottom: '32px' }}>
          {items.map((item, index) => (
            <StatsCard key={index} {...item} />
          ))}
        </Flex>
      )}

      {isLoading && (
        <Flex
          justify="center"
          align="center"
          style={{
            width: '100%',
          }}
        >
          <Spin size="large" />
        </Flex>
      )}

      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 },
        ]}
      >
        {data?.data.map((item, index) => (
          <Col key={index} sm={12} md={8} xl={8} xxl={6}>
            <TaskCard
              typeId={item.typeId}
              progress={Number(parseFloat(item.progressOverall).toFixed(2))}
              startDate={dayjs(item.publishDate).format('DD/MM/YYYY')}
              endDate={dayjs(item.endDate).format('DD/MM/YYYY')}
              responsibleName={item.responsible.name}
              countClasses={item.countClasses}
              countStudents={item.countStudents}
              gameImageUrl={item.game?.coverImageUrl}
              competenceName={
                item.tag.name
                  ? item.tag.name[currentLocale as keyof typeof item.tag.name]
                  : undefined
              }
              onOpen={() => {
                setActivityId(item.id);
                setIsOpenActivityProgress(true);
              }}
            />
          </Col>
        ))}

        <Col span={24}>
          <Flex justify="center">
            <Pagination
              style={{ margin: 16 }}
              current={page ?? 1}
              total={data?.total ?? 0}
              pageSize={pageSize ?? 0}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
            />
          </Flex>
        </Col>
      </Row>
    </>
  );
}

export default ProgressOverview;
