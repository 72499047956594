import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import useAnalysisTypes from 'core/features/questions/hooks/useAnalysisTypes';
import { t } from 'core/resources/strings';

function AnalysisTypeInput({ index }: { index: number }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  const allAnalysisTypes = useAnalysisTypes();

  if (graph?.category === 'emotion' || graph?.category === 'autoAvaliation') {
    return null;
  }

  return (
    <Form.Item
      label={t('app.tipoAnalise')}
      rules={[
        {
          required: true,
          message: t('app.mensagemErroTipoAnalise'),
        },
      ]}
      name={[index, 'analysisTypeId']}
    >
      <Radio.Group id="id-tour-indicators-custom-panel-input-analysisType">
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          {allAnalysisTypes?.data?.map((type) => (
            <Radio value={type.id} key={type.id}>
              {t(`app.${type.name}`)}
            </Radio>
          ))}
          <Radio value={3} key={3}>
            {t('app.todos')}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default AnalysisTypeInput;
