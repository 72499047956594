import { Button, Col, Flex, Row, Typography } from 'antd';
import Layout from 'components/layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
import Title from 'components/typography/title';
import ClassInformation from './components/classInformationCard';
import SafeSpaceRecords from 'components/safeSpaceRecords';
import InspirationsRecomendations from 'components/inspirationsRecomendations';
import { useParams } from 'react-router-dom';
import useClassDashboardInfo from 'core/features/classes/hooks/useClassDashboardDetails';
import Views from 'components/Views';
import Emotionometer from 'components/Emotionometer';
import { useCurrentUser } from 'core/features/user/store';
import QeduInfo from './components/qeduInfo';
import LearningRoute from 'components/learning-route';
import { TagType } from 'core/features/competence/typings';
import Text from 'components/typography/text';
import teachers from 'assets/images/rotori-teacher.png';
import students from 'assets/images/letters-book.png';
import teacher from 'assets/images/white-label/teacher.png';
import student from 'assets/images/white-label/student-girl.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';

function ClassPanel() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [lowestCompetence, setLowestCompetence] = useState<string>();
  const { classId, tenantId } = useParams<{
    classId: string;
    tenantId: string;
  }>();
  const { data: classInfo } = useClassDashboardInfo(classId);
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();

  const showSafeSpace =
    user?.resources.includes('safeSpace.viewall') ||
    user?.resources.includes('u4hero.tenant.edit') ||
    user?.resources.includes('safeSpace.view');

  return (
    <Layout.Container>
      <Flex vertical gap={'24px'} style={{ marginBottom: '16px' }}>
        <Flex gap={isMobile ? '19px' : '40px'} align="center">
          <Button
            type="primary"
            size={isMobile ? 'middle' : 'large'}
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
          />
          <Title primary>{t('app.painelDaTurma')}</Title>
        </Flex>

        <Row align="stretch" gutter={[40, isMobile ? 16 : 20]}>
          <Col xs={24} order={1} md={18} xxl={20}>
            <ClassInformation classInfo={classInfo} />
          </Col>
          <Col xs={24} md={6} xxl={4} order={isMobile ? 2 : 3}>
            <Flex
              vertical
              gap={16}
              justify="space-between"
              style={{ minHeight: '100%' }}
            >
              <Flex
                className="box-shadow-generic-card"
                style={{ background: '#fff', padding: '8px' }}
                justify="space-around"
                align="center"
              >
                <img src={user?.whiteLabel ? student : students} width="90px" />
                <Text align="center">
                  <strong style={{ fontSize: 28, color: colorPrimary }}>
                    {(classInfo && classInfo?.studentsCount > 9) ||
                    classInfo?.studentsCount === 0
                      ? classInfo?.studentsCount
                      : `0${classInfo?.studentsCount}`}
                  </strong>
                  <br />
                  {t('app.Estudantes')}
                </Text>
              </Flex>
              <Flex
                className="box-shadow-generic-card"
                style={{ background: '#fff', padding: '8px' }}
                justify="space-around"
                align="center"
              >
                <img src={user?.whiteLabel ? teacher : teachers} width="90px" />
                <Text align="center">
                  <strong style={{ fontSize: 28, color: colorPrimary }}>
                    {(classInfo && classInfo?.teachersCount > 9) ||
                    classInfo?.teachersCount === 0
                      ? classInfo?.teachersCount
                      : `0${classInfo?.teachersCount}`}
                  </strong>
                  <br />
                  {t('app.professores')}
                </Text>
              </Flex>
            </Flex>
          </Col>

          {user?.inep_id && (
            <Col md={18} xxl={20} xs={24} order={isMobile ? 3 : 2}>
              <QeduInfo serie={classInfo?.serie ?? ''} />
            </Col>
          )}
        </Row>

        <Views
          setLowestCompetence={setLowestCompetence}
          serieId={classInfo?.serieId}
          classId={classId}
          allowCompare={true}
          tagType={TagType.Competências}
          tenantId={tenantId}
          showAutoAvaliation={true}
        />

        <Views
          setLowestCompetence={setLowestCompetence}
          serieId={classInfo?.serieId}
          allowCompare={true}
          tagType={TagType['Clima']}
          tenantId={tenantId}
          classId={classId}
        />
        <InspirationsRecomendations lowest={lowestCompetence} />
        <Emotionometer
          serieId={classInfo?.serieId}
          allowCompare={true}
          className={classInfo?.name}
        />
        <LearningRoute
          type="class"
          classDetails={{
            id: classId,
            name: classInfo?.name!,
            period: classInfo?.period!,
            serieId: classInfo?.serieId!,
          }}
          studentsCount={classInfo?.studentsCount}
        />
        {showSafeSpace && <SafeSpaceRecords />}
      </Flex>
    </Layout.Container>
  );
}

export default ClassPanel;
