import { Carousel } from 'antd';
import styled from 'styled-components';

export const CarouselWrapper = styled(Carousel)`
  height: 100% !important;

  .slick-track {
    display: flex !important;
    height: 100% !important;
  }

  .slick-list {
    height: 100% !important;
  }

  .slick-slider {
    height: 100% !important;
  }

  .slick-slide {
    height: inherit !important;

    > div {
      height: 100% !important;
    }
  }

  > .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
`;
