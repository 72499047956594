import { Permissions } from 'core/resources/enums/permissions';
import { useCurrentUser } from 'core/features/user/store';
import Layout from 'components/layout';
import { Col, Row } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import NewsIcon from 'assets/images/icons/NewsIcon';
import BlogIcon from 'assets/images/icons/BlogIcon';
import ManageCard from 'components/ManageCard';
import { Link } from 'react-router-dom';
import Title from 'components/typography/title';
import {
  PiCastleTurretLight,
  PiLightbulbFilamentLight,
  PiQuestionLight,
} from 'react-icons/pi';
import EventsIcon from 'assets/images/icons/EventsIcon';

const permissionsForTab = [
  {
    permission: Permissions.U4hero.Posts.View,
    label: 'Publicações do Blog',
    icon: BlogIcon,
    link: '/blog-posts/manager',
    id: 'id-tour-blog-posts-tab',
  },
  {
    permission: Permissions.U4hero.Posts.View,
    label: 'Novidades da Plataforma',
    icon: NewsIcon,
    link: '/posts/manager',
    id: 'id-tour-news-posts-tab',
  },
  {
    permission: Permissions.U4hero.Posts.View,
    label: 'Modo História',
    icon: PiCastleTurretLight,
    link: '/stories/manager',
    id: 'id-tour-history-posts-tab',
  },
  {
    permission: Permissions.U4hero.Practices.View,
    label: 'Inspirações Pedagógicas',
    icon: PiLightbulbFilamentLight,
    link: '/practices',
    id: 'id-tour-practices-tab',
  },
  {
    permission: Permissions.U4hero.Helpers.Edit,
    label: 'Central de Ajuda',
    icon: PiQuestionLight,
    link: '/helpers-u4hero',
    id: 'id-tour-helpers-u4hero-tab',
  },
  {
    permission: Permissions.U4hero.Helpers.Edit,
    label: 'Gerenciar Eventos',
    icon: EventsIcon,
    link: '/events/manager',
    id: 'id-tour-events-tab',
  },
];

function PostsHub() {
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let cards: { label: string; icon: any; link: string; id: string }[] = [];
  permissionsForTab.forEach((element) =>
    user?.resources.includes(element.permission)
      ? cards.push({
          label: element.label,
          icon: element.icon,
          link: element.link,
          id: element.id,
        })
      : null
  );

  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: isMobile ? '16px' : '24px',
        }}
      >
        Central de Conteúdo
      </Title>

      <Row gutter={isMobile ? [0, 18] : [42, 48]}>
        {cards.map((item) => (
          <Col sm={8} style={{ width: '100%' }} key={item.id}>
            <Link to={item.link}>
              <ManageCard
                isMobile={isMobile}
                icon={item.icon}
                title={item.label}
                id={item.id}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </Layout.Container>
  );
}

export default PostsHub;
