import { useQuery } from 'react-query';
import axios from 'infra/http';
import { UserDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseUsersParams {
  page: number;
  search?: string;
  profiles?: string[];
  tenantId?: string;
  active?: boolean;
  limit?:number;
  type?:string;
}

function useUsers(params: UseUsersParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseUsersParams
  ): Promise<{ data: UserDetails[]; total: number }> => {
    const { data, headers } = await axios.get<UserDetails[]>(`/v2/users`, {
      params: {
        ...params,
        search: debouncedQuery,
        
      },
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [`/v2/users`, {...params, search: debouncedQuery }],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useUsers;
