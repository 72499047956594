import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row, Spin } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useEmotionalStats from 'core/features/graphs/hooks/useEmotionalGraphV2';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { t } from 'core/resources/strings';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import SadIcon from 'components/icons/SadIcon';
import { useState } from 'react';
import ModalEmotions from 'components/modals/modal-emotions';
import emptyEmotionometer from 'assets/images/white-label/empty-emotionometer.png';
import { useCurrentUser } from 'core/features/user/store';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { emotions } from 'infra/helpers/types';
import { currentLocale } from 'core/resources/strings/polyglot';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function Emotion() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const initialDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD');
  const { data, isLoading: isLoadingStats } = useEmotionalStats({
    initialDate,
    endDate,
  });
  const { user } = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);
  const [emotion, setEmotion] = useState<any>();
  const [intensity, setIntensity] = useState<number | undefined>();
  const totalCount = data?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );

  const labels = emotions.map((c) => c.name);
  const innerLabels = emotions.flatMap((c) =>
    Array(5)
      .fill(c.name)
      .map((name, i) => `${c.intensities[i].text[currentLocale]}`)
  );
  const orderedData = data?.data?.sort((a, b) => a.optionId - b.optionId);

  const emotionsData = {
    labels: labels,
    datasets: [
      {
        data: orderedData?.map((item) => item.percent),
        backgroundColor: emotions.map((c) => c.color),
        borderWidth: 0,
        weight: 1,
        labels: labels,
      },
      {
        data: orderedData?.flatMap((item) =>
          item.intensities.map((intensity) => intensity.percent)
        ),
        labels: innerLabels,
        backgroundColor: emotions.flatMap((c) =>
          Array(5)
            .fill(c.color)
            .map((color, i) => `${color}${50 + Math.round(i * 10)}`)
        ),
        borderWidth: 0,
        weight: 0.6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: '60%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetIndex = context.datasetIndex;
            const value = context.raw;

            const label = context.dataset.labels[context.dataIndex];

            if (value === 0) return '';
            return `${label}: ${value.toFixed(1)}%`;
          },
        },
      },
      datalabels: {
        formatter: (value: number, ctx: any) => {
          if (value === 0) return '';
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: number) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + '%';
          return percentage;
        },
        color: '#fff',
      },
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const element = elements[0];
        const index = element.index;
        const datasetIndex = element.datasetIndex;

        if (datasetIndex === 0) {
          setEmotion(emotions[index]);
          setIntensity(undefined);
        } else {
          const emotionIndex = Math.floor(index / 5);
          const intensityValue = (index % 5) + 1;
          setEmotion(emotions[emotionIndex]);
          setIntensity(intensityValue);
        }
        setIsOpen(true);
      }
    },
  };

  return (
    <GenericCard padding="8px" height="100%" id="id-tour-section-emotionometer">
      {isOpen && (
        <ModalEmotions
          emotion={emotion}
          initialDate={initialDate}
          endDate={endDate}
          onClose={() => {
            setIsOpen(false);
            setIntensity(undefined);
          }}
          intensity={intensity}
        />
      )}
      <Flex vertical style={{ width: '100%', height: '100%' }}>
        <p
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: 700,
          }}
        >
          {t('app.emocionometro')}
        </p>
        {totalCount !== 0 && (
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '12px' : '14px',
              fontWeight: 500,
              alignSelf: 'end',
            }}
          >
            {t(`app.${data?.period}`)}
          </p>
        )}

        {isLoadingStats ? (
          <Spin size="large" />
        ) : (
          <div>
            {totalCount === 0 ? (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                style={{ height: '100%' }}
              >
                {user?.whiteLabel ? (
                  <img src={emptyEmotionometer} width={'170px'} />
                ) : (
                  <SadIcon color="#7B7B7B" size={60} />
                )}
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: '267px',
                  }}
                >
                  {t('app.emocionometroNaoPreenchido')}
                </p>
              </Flex>
            ) : (
              <Row align="middle" justify="center" style={{ width: '100%' }}>
                <Col span={isMobile ? 24 : 14}>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: isMobile ? '235px' : '250px',
                    }}
                  >
                    <Doughnut
                      data={emotionsData}
                      options={chartOptions}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      id="id-tour-emotionometer-graph"
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Flex vertical={!isMobile} wrap={'wrap'} gap="16px">
                    {emotions.map((item, index) => (
                      <Flex
                        gap="8px"
                        key={index}
                        align="center"
                        id={`id-tour-legend-item-${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsOpen(true);
                          setEmotion(item);
                        }}
                      >
                        <img
                          alt={item.name}
                          src={item.img}
                          width={isMobile ? '24px' : '30px'}
                        />
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '16px',
                            fontWeight: 400,
                            color: 'black',
                            textAlign: 'right',
                          }}
                        >
                          {item.name} (
                          {item.intensities
                            .map((i) => i.text[currentLocale])
                            .join(', ')}
                          )
                        </p>
                      </Flex>
                    ))}
                  </Flex>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Flex>
    </GenericCard>
  );
}

export default Emotion;
