import useSeries from 'core/features/series/hooks/useSeries';
import useAllClasses from 'core/features/classes/hooks/useAllClasses';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';
import { useParams } from 'react-router-dom';
import { t } from 'core/resources/strings';

const studentTypes = [
  { value: 'deleted', label: t('app.estudantesDesativados') },
  { value: 'active', label: t('app.estudantesComChaveAtiva') },
  { value: 'without-class', label: t('app.estudantesSemTurma') },
];

const progressOptions = [
  { value: 'not_started', label: t('app.naoIniciado0') },
  { value: 'in_progress', label: t('app.emAndamento179') },
  { value: 'completed', label: t('app.concluido100') },
];

function StudentFilter({ filters, onChangeFilters }: FilterProps) {
  const { data } = useSeries();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: dataClasses } = useAllClasses({ tenantId });

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.tipo'),
          filterName: 'types',
          options: studentTypes,
        },
        {
          title: t('app.serie'),
          filterName: 'series',
          options:
            data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: t('app.turma'),
          filterName: 'classes',
          options:
            dataClasses?.data.map((item) => {
              return {
                label:
                  item.name.length > 20
                    ? item.name.substr(0, 17) + '...'
                    : item.name,
                value: item.id,
              };
            }) ?? [],
        },
        {
          title: t('app.progresso'),
          filterName: 'progress',
          options: progressOptions,
        },
        {
          title: t('app.sexo'),
          options: [
            { label: t('app.menino'), value: 'M' },
            { label: t('app.menina'), value: 'F' },
          ],
          filterName: 'gender',
          type: 'radio',
        },
        {
          title: t('app.ultimoAcessoApartirDe'),
          filterName: 'lastAccess',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default StudentFilter;
