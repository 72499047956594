import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import {
  GraphCategory,
  EmotionView,
} from 'core/features/indicators-profile/typings';
import { t } from 'core/resources/strings';

function EmotionViewInput({ index }: { index: number }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);

  if (graph && graph.category !== GraphCategory.Emotion) {
    return null;
  }

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: t('app.mensagemErroVisualizarEmocao'),
        },
      ]}
      name={[index, 'emotionView']}
      label={t('app.visualizar')}
    >
      <Radio.Group id="id-tour-my-indicators-custom-panel-emotion-view-input">
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Radio value={EmotionView.Main}>{t('app.emocoesPrincipais')}</Radio>
          <Radio value={EmotionView.Intensity}>
            {t('app.intensidadeDasEmocoes')}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default EmotionViewInput;
