import { useQuery } from 'react-query';
import axios from 'infra/http';

interface StudentFrequencyGraphParams {
  startDate?: string;
  endDate?: string;
  tenantId?: string;
}
function useStudentFrequencyGraph(params: StudentFrequencyGraphParams) {
  const fetchData = async (params: StudentFrequencyGraphParams): Promise<{
    data: { date: string; distinctUserCount: number }[];
  }> => {
    const { data } = await axios.get<
      { date: string; distinctUserCount: number }[]
    >(`/v2/indicators/student-frequency`, {
      params: params,
    });

    return { data: data };
  };

  return useQuery([`/v2/indicators/student-frequency`, params], () =>
    fetchData(params)
  );
}

export default useStudentFrequencyGraph;
