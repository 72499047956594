import { Col, Flex, Pagination, Row, Spin } from 'antd';
import { useState } from 'react';
import TaskCard from 'ui/pages/app/indicators/components/taskCard';
import Text from 'components/typography/text';
import SelectActivityType from 'ui/pages/app/indicators/components/select-activity-type';
import useActivitiesReport from 'core/features/activity/hooks/useActivitiesReport';
import dayjs from 'dayjs';
import { currentLocale } from 'core/resources/strings/polyglot';
import Report from 'ui/pages/app/indicators/components/new-report';
import ReportFilter from 'ui/pages/app/indicators/components/reportFilter';
import { FilterType } from 'infra/helpers/types';
import AppliedFilters from 'components/applied-filters';
import { useParams } from 'react-router-dom';

function ReportView() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<number | undefined>(3);
  const [reportType, setReportType] = useState<'simplified' | 'complete'>(
    'simplified'
  );
  const currentYear = new Date().getFullYear();

  const [activityId, setActivityId] = useState<string | undefined>(undefined);

  const [filters, setFilters] = useState<FilterType>({
    years: [
      {
        label: currentYear.toString(),
        value: currentYear.toString(),
      },
    ],
    period: [
      {
        label: 'Anual',
        value: 0,
      },
    ],
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  const handleFilterChange = (newFilters: FilterType) => {
    if (
      newFilters.years?.[0]?.value !== filters.years?.[0]?.value &&
      newFilters.years?.[0]?.value !== '' &&
      newFilters.years?.[0]?.value !== undefined
    ) {
      setFilters({
        ...newFilters,
        period: [
          {
            label: 'Anual',
            value: 0,
          },
        ],
      });
    } else {
      setFilters(newFilters);
    }
  };

  const getDateRange = (periodValue: number) => {
    if (!filters.years?.[0]?.value)
      return { startDate: undefined, endDate: undefined };

    if (periodValue === 0) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }

    if (periodValue === 10) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }

    if (periodValue >= 1 && periodValue <= 6) {
      const startMonth = (periodValue - 1) * 2;
      return {
        startDate: dayjs(
          `${filters.years?.[0]?.value}-${(startMonth + 1)
            .toString()
            .padStart(2, '0')}-01`
        )
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(
          `${filters.years?.[0]?.value}-${(startMonth + 2)
            .toString()
            .padStart(2, '0')}-01`
        )
          .endOf('month')
          .format('YYYY/MM/DD'),
      };
    }

    if (periodValue === 7) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-06-30`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }
    if (periodValue === 8) {
      return {
        startDate: dayjs(`${filters.years?.[0]?.value}-07-01`)
          .startOf('day')
          .format('YYYY/MM/DD'),
        endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
          .endOf('day')
          .format('YYYY/MM/DD'),
      };
    }

    return {
      startDate: dayjs(`${filters.years?.[0]?.value}-01-01`)
        .startOf('day')
        .format('YYYY/MM/DD'),
      endDate: dayjs(`${filters.years?.[0]?.value}-12-31`)
        .endOf('day')
        .format('YYYY/MM/DD'),
    };
  };

  const dateRange = getDateRange(filters.period[0]?.value);

  const { data, isLoading } = useActivitiesReport({
    // tagId: competence,
    page: page,
    limit: pageSize,
    typeIds:
      type === 1 ? [1] : type === 2 ? [2, 4] : type === 3 ? [0, 3] : undefined,
    publishDate: dateRange.startDate,
    endDate: dateRange.endDate,
    tenantId,
  });

  return (
    <Flex vertical gap={16}>
      {isOpen && (
        <Report
          onClose={() => setIsOpen(false)}
          activityId={activityId}
          modelType={reportType}
          tenantId={tenantId}
        />
      )}
      <Flex gap={16} align="center">
        <Flex align="center" gap={8}>
          <Text size="md">Tipo:</Text>
          <SelectActivityType
            value={type}
            defaultValue={3}
            onChange={(v) => setType(v)}
          />
        </Flex>
        <Flex gap={16} align="center">
          <ReportFilter
            filters={filters}
            onChangeFilters={handleFilterChange}
          />
          <AppliedFilters
            appliedFilters={filters}
            onChangeFilters={handleFilterChange}
          />
        </Flex>
      </Flex>
      {isLoading && <Spin size="large" />}
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 },
        ]}
      >
        {data?.data.map((item, index) => (
          <Col key={index} sm={12} md={8} xl={8} xxl={6}>
            <TaskCard
              typeId={item.typeId}
              progress={Number(parseFloat(item.progressOverall).toFixed(2))}
              startDate={dayjs(item.publishDate).format('DD/MM/YYYY')}
              endDate={dayjs(item.endDate).format('DD/MM/YYYY')}
              responsibleName={item.responsible.name}
              countClasses={item.countClasses}
              countStudents={item.countStudents}
              gameImageUrl={item.game?.coverImageUrl}
              competenceName={
                item.tag.name
                  ? item.tag.name[currentLocale as keyof typeof item.tag.name]
                  : undefined
              }
              onOpenReport={(v) => {
                setActivityId(item.id);
                setReportType(v);
                setIsOpen(true);
              }}
            />
          </Col>
        ))}
      </Row>
      <Flex justify="center">
        <Pagination
          style={{ margin: 16 }}
          current={page ?? 1}
          total={data?.total ?? 0}
          pageSize={pageSize ?? 0}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Flex>
    </Flex>
  );
}

export default ReportView;
