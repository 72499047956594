import { useMediaQuery } from '@chakra-ui/react';
import Button from 'components/buttons/button';
import { Button as ButtonAntd } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useRef, useState } from 'react';

import {
  PiUploadSimpleThin,
  PiSpeakerHighBold,
  PiTrashBold,
} from 'react-icons/pi';
import Swal from 'sweetalert2';
import axios from 'infra/http';

function SelectMusic({
  onChange,
  value,
}: {
  onChange: (v: string) => void;
  value: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getFileName = (url: string) => {
    if (!url) return '';
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleRemove = () => {
    onChange('');
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const uploadAndAddMusic = async (file: any) => {
    if (file !== undefined) {
      if (!file.type.startsWith('audio/')) {
        Swal.fire({
          title: 'Aviso',
          text: 'Por favor, selecione um arquivo de áudio válido',
          icon: 'info',
        });
        return null;
      }

      if (file.size > 10e6) {
        // 10MB limit
        Swal.fire({
          title: 'Aviso',
          text: 'O arquivo de áudio deve ter menos que 10MB',
          icon: 'info',
        });
        return null;
      }
      const formData = new FormData();

      formData.append('file', file);

      try {
        const { data }: { data: { result: string } } = await axios.post(
          `/v1/file/generic-upload?folderKey=u4heroEvents&bucketName=u4hero-files&keepOriginalName=true`,
          formData
        );
        onChange(data.result);
      } finally {
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '8px',
      }}
    >
      <Button
        variant="outline"
        size="md"
        id="id-tour-event-create-button-music"
        style={{
          display: 'flex',
          gap: isMobile ? '4px' : '16px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleButtonClick}
      >
        <PiUploadSimpleThin size={isMobile ? 16 : 28} color={colorPrimary} />
        ESCOLHER MÚSICA
      </Button>

      <div
        style={{
          display: value ? 'flex' : 'none',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span style={{ flex: 1 }}>{getFileName(value)}</span>
        <ButtonAntd
          variant="outlined"
          onClick={handlePlayPause}
          style={{ minWidth: 'auto', padding: '8px' }}
        >
          <PiSpeakerHighBold size={20} color={'black'} />
        </ButtonAntd>
        <ButtonAntd
          variant="outlined"
          onClick={handleRemove}
          style={{ minWidth: 'auto', padding: '8px' }}
        >
          <PiTrashBold size={20} color="black" />
        </ButtonAntd>
      </div>
      <input
        type="file"
        accept="audio/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => uploadAndAddMusic(e.target.files?.[0])}
      />
      {value && (
        <audio ref={audioRef} src={value} onEnded={() => setIsPlaying(false)} />
      )}
    </div>
  );
}

export default SelectMusic;
