import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useClasses from 'core/features/classes/hooks/useClasses';
import { ClassDetails } from 'core/features/classes/types';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router';

interface SelectClassProps {
  value: string;
  onChange: (v: string, classe: ClassDetails) => void;
  cleanDependentFields?: () => void;
  serieId?: string;
  tenantId?: string;
  id: string;
}

function SelectClass({
  value,
  onChange,
  cleanDependentFields,
  serieId,
  tenantId,
  id,
}: SelectClassProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useClasses({
    series: serieId ? [serieId] : undefined,
    disableConcat: true,
    page: 1,
    limit: 50,
    tenantId: tenantId,
  });

  return (
    <Select
      id={id}
      placeholder={t('app.selecioneATurma')}
      disabled={!serieId}
      options={data?.data.map((classe) => ({
        label: classe.name,
        value: classe.id,
      }))}
      onChange={(v) => {
        const classe = data?.data.find((item) => item.id === v) as ClassDetails;
        onChange(v, classe);
        cleanDependentFields && cleanDependentFields();
      }}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectClass;
