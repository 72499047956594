import { BrowserRouter, Router, Route } from 'react-router-dom';

import ChakraUiProvider from 'infra/packages/@chakra-ui';
import { ReactQueryProvider } from 'infra/packages/react-query';
import 'infra/packages/antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import './index.css';
import AppPages from './ui/pages';
import AntdProvider from 'infra/packages/antd';
import { QueryParamProvider } from 'use-query-params';
import { useEffect } from 'react';
import history from 'infra/helpers/history';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; //
import utc from 'dayjs/plugin/utc';
import { currentLocale } from 'core/resources/strings/polyglot';
import B2cGroups from 'ui/pages/app/mySchool/pages/B2cGroups';

dayjs.locale(currentLocale);
moment.locale(currentLocale);
dayjs.extend(utc);

function App() {
  useEffect(() => {
    history.listen(() => {
      window.scrollTo({ top: 0 });
    });
  }, []);

  return (
    <ReactQueryProvider>
      <ChakraUiProvider>
        <AntdProvider>
          <BrowserRouter>
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <AppPages />
              </QueryParamProvider>
            </Router>
          </BrowserRouter>
        </AntdProvider>
      </ChakraUiProvider>
    </ReactQueryProvider>
  );
}

export default App;
