import { useQuery } from 'react-query';
import axios from 'infra/http';
import { AutoAvaliationResult } from 'core/features/students/typings';

export interface UseStudentAutoAvaliationParams {
  userId?: string;
  classId?: string;
  startDate?: string;
  endDate?: string;
}


function useStudentAutoAvaliation(params: UseStudentAutoAvaliationParams) {
  const fetchData = async (
    params: UseStudentAutoAvaliationParams
  ): Promise<{ data: AutoAvaliationResult[] }> => {

    const { data } = await axios.get<AutoAvaliationResult[]>(
      `/v2/student/auto-avaliation`,
      {
        params,
      }
    );

    return { data: data };
  };

  return useQuery([`/v2/student/auto-avaliation`, params], () =>
    fetchData(params)
  );

}

export default useStudentAutoAvaliation;
