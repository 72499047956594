import { Flex, Spin, Tooltip } from 'antd';
import { ApexOptions } from 'apexcharts';
import SadIcon from 'components/icons/SadIcon';
import emptyEmotionometer from 'assets/images/white-label/empty-emotionometer.png';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useEmotionometerGraph from 'core/features/indicators/hooks/useEmotionometerGraph';
import { t } from 'core/resources/strings';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { FiTrash2 } from 'react-icons/fi';
import { useCurrentUser } from 'core/features/user/store';
import useEmotionometerIntensityGraph from 'core/features/indicators/hooks/useEmotionometerIntensityGraph';
import BarGraph from 'ui/pages/app/indicators/tabs/components/customGraph/graphs/barGraph';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useParams } from 'react-router-dom';

function EmotionalIntensityGraph({ onRemove }: { onRemove?: () => void }) {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data, isLoading } = useEmotionometerIntensityGraph({ tenantId });
  const { user } = useCurrentUser();
  const { colorGreyBorderAndFont } = useU4heroColors();

  const totalCount = data?.data.reduce((acc, emotion) => {
    return acc + emotion;
  }, 0);

  return (
    <GenericCard height="100%">
      <Flex vertical style={{ width: '100%' }} justify="space-between">
        <Flex justify="space-between">
          <SubTitle primary>{t('app.intensidadeDasEmocoes')}</SubTitle>
          {onRemove && (
            <Tooltip title={t('app.deletar')}>
              <FiTrash2
                onClick={onRemove}
                style={{
                  fontSize: 16,
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Flex>
        <Text
          size="sm"
          color={colorGreyBorderAndFont}
          style={{ alignSelf: 'flex-end' }}
        >
          {t('app.ultimo7Dias')}
        </Text>
        <Text
          style={{
            borderRadius: '0px 10px 10px 0px',
            background: 'rgba(157, 157, 157, 0.30)',
            padding: '4px 8px',
            width: 'fit-content',
          }}
        >
          {t('app.totalDeRespostas')}: <strong>{totalCount}</strong>
        </Text>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <div style={{ height: '100%' }}>
            {totalCount === 0 ? (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                style={{ height: '100%' }}
              >
                {user?.whiteLabel ? (
                  <img src={emptyEmotionometer} width={'170px'} />
                ) : (
                  <SadIcon color="#7B7B7B" size={60} />
                )}
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: '267px',
                  }}
                >
                  {t('app.emocionometroNaoPreenchido')}
                </p>
              </Flex>
            ) : (
              <BarGraph
                categories={data?.categories!}
                series={[{ data: data?.data! }]}
              />
            )}
          </div>
        )}
      </Flex>
    </GenericCard>
  );
}

export default EmotionalIntensityGraph;
