import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex, Switch } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';

function PreviewModal({
  onClose,
  billboard,
  splashArt,
  description,
  additionalImage1,
  additionalImage2,
  additionalImage3,
}: {
  onClose: () => void;
  billboard?: string;
  splashArt?: string;
  description?: string;
  additionalImage1?: string;
  additionalImage2?: string;
  additionalImage3?: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { colorPrimary, colorPrimaryMedium } = useU4heroColors();

  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={onClose}>
            Fechar
          </Button>
        </Flex>,
      ]}
      title={<SubTitle primary>Prévia do Evento</SubTitle>}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : '666px'}
    >
      <Flex
        vertical
        gap={16}
        style={{
          position: 'relative',
        }}
      >
        <img
          src={additionalImage1}
          alt="additionalImage1"
          style={{
            position: 'absolute',
            bottom: -7,
            left: -34,
            zIndex: 3,
          }}
        />
        <img
          src={additionalImage2}
          alt="additionalImage2"
          style={{
            position: 'absolute',
            top: 18,
            right: -57,
            zIndex: 3,
          }}
        />
        <img
          src={additionalImage3}
          alt="additionalImage3"
          style={{
            position: 'absolute',
            bottom: -69,
            right: -135,
            zIndex: 1,
          }}
        />

        <Flex gap="16px" align="center" justify="flex-end">
          <Text size="sm">Visualizar no modo escuro:</Text>
          <Switch
            onChange={(checked) => {
              setIsDarkMode(checked);
            }}
          />
        </Flex>

        <Flex
          style={{
            borderRadius: '10px',
            borderRight: `3px solid ${
              isDarkMode ? '#272727' : colorPrimaryMedium
            }`,
            borderBottom: `4px solid ${
              isDarkMode ? '#272727' : colorPrimaryMedium
            }`,
            background: isDarkMode ? '#3D3C3C' : colorPrimary,
            padding: '16px',
            zIndex: 2,
          }}
          vertical
          gap={8}
        >
          <img src={billboard} alt="billboard" />
          <img src={splashArt} alt="splashArt" />
          <SubTitle color="white" style={{ fontWeight: 400 }}>
            {description}
          </SubTitle>
          <Flex gap={16} justify="center" style={{ marginTop: '8px' }}>
            <button
              type="button"
              style={{
                borderRadius: '10px',
                borderTop: '0.5px solid #ED810F',
                borderRight: '2px solid #ED810F',
                borderBottom: '3px solid #ED810F',
                borderLeft: '0.5px solid #ED810F',
                background: 'rgba(247, 157, 22, 0.25)',
                fontSize: '20px',
                color: 'white',
                padding: '8px 16px',
              }}
            >
              Ver Depois
            </button>
            <button
              type="button"
              style={{
                borderRadius: '10px',
                borderRight: '2px solid #ED810F',
                borderBottom: '3px solid #ED810F',
                background: '#F79D16',
                color: 'white',
                fontSize: '20px',
                padding: '8px 16px',
              }}
            >
              CONFERIR NA LOJA
            </button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default PreviewModal;
