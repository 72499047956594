import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import CustomizedRequiredMark from 'components/required-mark';
import CustomizeGraphs from '../modal-customize-panel/components/customizeGraphs';
import EducatorOptions from './components/educatorOptions';
import { Graph } from 'core/features/indicators-profile/typings';
import ManagerOptions from './components/managerOptions';
import OrganizationOptions from './components/organizationOptions';
import { t } from 'core/resources/strings';

function ModalAddIndicators({
  graphs,
  onClose,
  setGraphs,
  mode,
  graphIndex,
}: {
  graphs: Graph[];
  onClose: () => void;
  setGraphs: React.Dispatch<React.SetStateAction<Graph[]>>;
  mode: 'educator' | 'manager' | 'organization';
  graphIndex?: number;
}) {
  const [form] = Form.useForm();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const onSubmit = (values: any) => {
    setGraphs(values.graphs);
    notificateSucess(`Indicadores incluidos com sucesso!`);
    onClose();
  };

  return (
    <Modal
      title={<SubTitle primary>{t('app.adicionarIndicadores')}</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? '100%' : 875}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="id-tour-indicators-custom-panel-form"
        onFinish={(ev) => onSubmit(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={{
          options: {},
          graphs: graphs.length ? graphs : [],
        }}
      >
        <Flex vertical gap={16}>
          <CustomizeGraphs graphIndex={graphIndex} />
          {mode === 'educator' && <EducatorOptions />}
          {mode === 'manager' && <ManagerOptions />}
          {mode === 'organization' && <OrganizationOptions />}

          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({ onClose, colorPrimary, isMobile });
                } else {
                  onClose();
                }
              }}
              id="id-tour-indicators-custom-panel-button-cancel"
            >
              {t('app.cancelar')}
            </Button>

            <Button
              htmlType="submit"
              size="md"
              id="id-tour-indicators-custom-panel-button-save"
            >
              {t('app.salvar')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalAddIndicators;
