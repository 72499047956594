import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ActivitiesStats } from 'core/features/students/typings';

interface useActivitiesStatsParams {
  classId?: string;
  userId?: string;
  tenantId?: string;
  publishDate?: string;
  endDate?: string;
  typeIds?: number[];
  
}

function useActivitiesStats(params: useActivitiesStatsParams) {
  const fetchData = async (params:useActivitiesStatsParams): Promise<ActivitiesStats> => {
    const { data } = await axios.get<ActivitiesStats>(
      `/v2/activities/stats`,
      {
        params,
      }
    );
    return data;
  };

  return useQuery([`/v2/activities/stats`, params], () => fetchData(params), {
    enabled: !!params.classId || !!params.userId || !!params.tenantId,
  });
}

export default useActivitiesStats;
