import { useMediaQuery } from '@chakra-ui/media-query';
import { Dropdown, Flex, Menu } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { StoreItemDetails } from 'core/features/store-items/typings';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import Dot from 'components/dot';
import { FaEllipsis } from 'react-icons/fa6';
import { RiSparkling2Fill } from 'react-icons/ri';
import u4coin from 'assets/images/u4coin.png';
import useToggleStoreItem from 'core/features/store-items/hooks/useToggleStoreItem';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';

function ItemCard({
  item,
  onEdit,
}: {
  item: StoreItemDetails;
  onEdit: (v: string) => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const { mutate: handleToggle } = useToggleStoreItem();
  const queryClient = useQueryClient();

  return (
    <GenericCard padding="0px 16px 16px 16px" height="100%">
      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Flex
          style={{
            background: colorPrimary,
            padding: '9px 33px',
            borderRadius: '0px 0px 10px 10px',
            width: 'fit-content',
            alignSelf: 'center',
          }}
        >
          <Text color="white" bold>
            {item.name}
          </Text>
        </Flex>
        <Flex
          style={{
            borderRadius: '20px',
            background: '#F5F5F5',
            boxShadow:
              '0px -0.5px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            padding: '8px 8px 0px 8px',
            position: 'relative',
          }}
          vertical
        >
          <Flex justify="space-between">
            <Flex
              style={{
                border: `2px solid ${colorGreyBorderAndFont}`,
                borderRadius: '10px',
                padding: '6px',
              }}
              gap={4}
              align="center"
            >
              <RiSparkling2Fill color={colorGreyBorderAndFont} />
              <Text size="sm" color={colorGreyBorderAndFont}>
                {item.endDate ? 'Raro' : 'Clássico'}
              </Text>
            </Flex>
            <Flex
              style={{
                background: 'white',
                borderRadius: '10px',
                boxShadow:
                  '0px -0.5px 4px 0px rgba(196, 196, 196, 0.25), 0px 4px 4px 0px rgba(196, 196, 196, 0.25)',
                padding: '6px',
              }}
              align="center"
              gap={5}
            >
              <img src={u4coin} width={18} height={18} alt="u4coin" />
              <Text>{item.price}</Text>
            </Flex>
          </Flex>
          <img src={item.imageUrl} alt={item.name} />
          <Text
            style={{
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
              padding: '4px 8px',
              width: 'fit-content',
              borderRadius: '5px',
              alignSelf: 'center',
              position: 'absolute',
              bottom: -15,
              background: '#F5F5F5',
            }}
            size="sm"
          >
            {t(`app.${item.type}`)}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" gap={4}>
            <Dot size={8} color={item.active ? '#336732' : '#BB191E'} />
            <Text>{item.active ? 'Ativo' : 'Desativado'}</Text>
          </Flex>
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  if (e.key === 'edit') {
                    onEdit(item.id);
                  }
                  if (e.key === 'toggle') {
                    handleToggle(
                      {
                        id: item.id,
                        isActive: item.active,
                      },
                      {
                        onSuccess: () => {
                          notificateSucess(
                            `O item foi ${
                              item.active ? 'desativado' : 'ativado'
                            } com sucesso!`
                          );
                          queryClient.invalidateQueries('/v2/store-items');
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao ${
                              item.active ? 'desativar' : 'ativar'
                            } este item!`
                          );
                        },
                      }
                    );
                  }
                }}
              >
                <Menu.Item key={'edit'} id="id-tour-table-row-action-edit">
                  Editar
                </Menu.Item>

                <Menu.Item key={'toggle'} id="id-tour-table-row-action-delete">
                  {item.active ? 'Desativar' : 'Ativar'}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <FaEllipsis size={24} color="black" style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default ItemCard;
