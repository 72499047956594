import { Col, Flex, Form, Pagination, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SearchBar from 'components/V2/SearchBar';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';
import { FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import StoreItemFilter from 'ui/pages/app/manager-store/components/StoreItemFilter';
import lumiInCar from 'assets/images/lumi-in-car.png';
import lumiSearching from 'assets/images/lumi-searching.png';
import EventItemCard from 'ui/pages/app/posts-hub/events/components/EventItemCard';
import SubTitle from 'components/typography/subTitle';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { StoreItemDetails } from 'core/features/store-items/typings';

function EventItems() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filters, setFilters] = useState<FilterType>({
    types: [
      {
        label: 'Ícone',
        value: 'icon',
      },
      {
        label: 'Moldura',
        value: 'frame',
      },
      {
        label: 'Fundo',
        value: 'background',
      },
    ],
    classification: [],
  });

  const { colorGreyCard, colorGreyBorderAndFont, colorPrimary } =
    useU4heroColors();
  const form = Form.useFormInstance();
  const frames = Form.useWatch('frames', form) ?? [];
  const icons = Form.useWatch('icons', form) ?? [];
  const bgs = Form.useWatch('backgrounds', form) ?? [];

  const { data, isLoading } = useStoreItems({
    page,
    limit: pageSize,
    search,
    types: filters.types.map((item) => item.value) ?? undefined,
    active: true,
    classification: filters.classification?.length
      ? filters.classification.map((item) => item.value)[0]
      : undefined,
  });

  const items = [
    {
      label: 'Icones',
      items: icons,
      name: 'icons',
    },
    {
      label: 'Molduras',
      items: frames,
      name: 'frames',
    },
    {
      label: 'Fundos',
      items: bgs,
      name: 'backgrounds',
    },
  ];
  const history = useHistory();

  const handleCheckChange = (item: StoreItemDetails, checked: boolean) => {
    const field = item.type + 's';
    const currentItems = form.getFieldValue(field) || [];

    if (checked) {
      form.setFieldsValue({ [field]: [...currentItems, item] });
    } else {
      form.setFieldsValue({
        [field]: currentItems.filter((i: StoreItemDetails) => i.id !== item.id),
      });
    }
  };

  return (
    <GenericCard>
      <Flex vertical gap="16px" style={{ width: '100%' }}>
        <Title primary>Definição dos Itens</Title>
        <Flex gap="16px" align="center">
          <SearchBar
            onSearch={setSearch}
            width="100%"
            placeholder="Pesquisar item"
          />
          <StoreItemFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />

        <Flex
          justify="space-between"
          gap="32px"
          style={{ display: isMobile ? 'none' : 'flex' }}
        >
          {items.map((category) => (
            <Form.Item
              key={category.name}
              name={category.name}
              label={''}
              rules={[
                {
                  required: true,
                  message: `Por favor, atribua os ${category.label.toLocaleLowerCase()}!`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue(category.name).length === 5) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Selecione 5 ${category.label.toLocaleLowerCase()}!`
                      )
                    );
                  },
                }),
              ]}
            >
              <Flex
                style={{
                  borderRadius: '10px',
                  border: '1.5px solid #C4C4C4',
                  background: '#FFF',
                  padding: '8px',
                }}
                vertical
              >
                <Text size="md" color={colorPrimary} bold>
                  {category.label}
                </Text>
                <Flex gap="16px" align="center">
                  {[0, 1, 2, 3, 4].map((_, index) => (
                    <Flex
                      key={index}
                      align="center"
                      style={{
                        borderRadius: '5px',
                        border: `1px dashed ${colorGreyCard}`,
                        background: '#FFF',
                        height: '52px',
                        width: '52px',
                      }}
                      justify="center"
                    >
                      {category.items?.[index]?.imageUrl ? (
                        <img
                          src={category.items?.[index]?.imageUrl}
                          alt=""
                          style={{
                            width: '32px',
                            height: '32px',
                          }}
                        />
                      ) : (
                        <Text size="md" color={colorGreyBorderAndFont}>
                          {index + 1}
                        </Text>
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Form.Item>
          ))}
        </Flex>

        {isLoading ? <Spin size={'large'} /> : null}

        {data && data.data.length > 0 ? (
          <>
            <Row gutter={[36, isMobile ? 24 : 32]} align="stretch">
              {data?.data.map((item) => (
                <Col key={item.id} span={isMobile ? 24 : 6}>
                  <EventItemCard
                    item={item}
                    value={[...frames, ...bgs, ...icons].some(
                      (i) => i.id === item.id
                    )}
                    onCheckChange={(checked) =>
                      handleCheckChange(item, checked)
                    }
                  />
                </Col>
              ))}
            </Row>
            <Flex
              justify="center"
              style={{
                marginTop: isMobile ? '18px' : '26px',
                marginBottom: isMobile ? '10px' : '18px',
              }}
            >
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                current={page}
                pageSize={pageSize}
                onChange={(v) => setPage(v)}
                onShowSizeChange={(v, size) => setPageSize(size)}
                total={data?.total}
              />
            </Flex>
          </>
        ) : null}
        {data && data.data.length === 0 && !search ? (
          <Flex vertical align="center" justify="center" gap={8}>
            <img src={lumiInCar} alt="loja do lumi" />
            <Title>Inventário Vazio</Title>
            <Text align="justify" size="lg">
              Atualmente, não há itens disponíveis para exibição. Comece agora a
              criar seus primeiros itens!
            </Text>
            <Button size="md" onClick={() => history.push('/manager-store')}>
              Criar
            </Button>
          </Flex>
        ) : null}
        {data?.data.length === 0 && search ? (
          <Flex vertical gap={8} justify="center" align="center">
            <img
              src={lumiSearching}
              width={198}
              alt={'nenhum item encontrado'}
            />
            <SubTitle>Item não encontrado</SubTitle>
            <Text size="lg" align="center">
              Ops, não há itens com esse nome. Por favor, verifique e tente
              novamente!
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </GenericCard>
  );
}

export default EventItems;
