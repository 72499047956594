import { useQuery } from 'react-query';
import axios from 'infra/http';

function useEmotionometerGraph(params?: { tenantId?: string }) {
  const fetchData = async (): Promise<{
    data: { name: string; data: number[] }[];
  }> => {
    const { data } = await axios.get<{ name: string; data: number[] }[]>(
      `/v2/indicators/emotionometer`,
      {
        params,
      }
    );

    return { data: data };
  };

  return useQuery([`/v2/indicators/emotionometer`], () => fetchData());
}

export default useEmotionometerGraph;
