import { Button, Flex, Typography } from 'antd';
import Layout from 'components/layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@chakra-ui/react';
import StudentInformation from './components/studentInformationCard';
import { useState } from 'react';
import Title from 'components/typography/title';
import SafeSpaceRecords from 'components/safeSpaceRecords';
import InspirationsRecomendations from 'components/inspirationsRecomendations';
import Views from 'components/Views';
import Emotionometer from 'components/Emotionometer';
import { useCurrentUser } from 'core/features/user/store';
import LearningRoute from 'components/learning-route';
import { TagType } from 'core/features/competence/typings';
import { t } from 'core/resources/strings';
import useStudentInfo from 'core/features/students/hooks/useStudentInfo';
import { useParams } from 'react-router-dom';

function StudentPanel() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [lowestCompetence, setLowestCompetence] = useState<string>();
  const { id, tenantId } = useParams<{
    tenantId: string;
    id: string;
  }>();

  const { data: userInfo } = useStudentInfo(id);
  const { user } = useCurrentUser();

  const showSafeSpace =
    user?.resources.includes('safeSpace.viewall') ||
    user?.resources.includes('u4hero.tenant.edit') ||
    user?.resources.includes('safeSpace.view');

  return (
    <Layout.Container>
      <Flex vertical gap={'24px'} style={{ marginBottom: '16px' }}>
        <Flex gap={isMobile ? '19px' : '40px'} align="center">
          <Button
            type="primary"
            size={isMobile ? 'middle' : 'large'}
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
          />
          <Title primary>{t('app.painelDoEstudante')}</Title>
        </Flex>

        <StudentInformation userInfo={userInfo!} />

        <Views
          setLowestCompetence={setLowestCompetence}
          tagType={TagType.Competências}
          allowCompare
          classId={userInfo?.classId!}
          tenantId={tenantId}
          userId={id}
          serieId={userInfo?.serieId!}
          showAutoAvaliation={true}
        />

        <Views
          setLowestCompetence={setLowestCompetence}
          tagType={TagType['Clima']}
          allowCompare
          classId={userInfo?.classId!}
          tenantId={tenantId}
          userId={id}
          serieId={userInfo?.serieId!}
        />
        <InspirationsRecomendations lowest={lowestCompetence} />
        <Emotionometer />
        <LearningRoute
          type="student"
          classDetails={{
            id: userInfo?.classId!,
            name: userInfo?.className!,
            period: userInfo?.period!,
            serieId: userInfo?.serieId!,
          }}
          studentName={userInfo?.name!}
        />
        {showSafeSpace && <SafeSpaceRecords />}
      </Flex>
    </Layout.Container>
  );
}

export default StudentPanel;
