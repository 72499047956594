export interface IndicatorsProfileForm {
  id?: string;
  profile: {
    graphs: Graph[];
    options: Options;
  };
}

export enum GraphType {
  LineGraph = 'line-graph',
  AreaGraph = 'area-graph',
  BarGraph = 'bar-graph',
  RadarGraph = 'radar-graph',
  PizzaGraph = 'pizza-graph',
  DonutGraph = 'donut-graph',
  ColumnGraph = 'column-graph',
  StackedGraph = 'stacked-graph',
}

export enum GraphSource {
  School = 'school',
  Serie = 'serie',
  Class = 'class',
  Student = 'student',
}

export enum GraphCategory {
  Activity = 'activity',
  Avaliation = 'avaliation',
  SelfAvaliation = 'selfAvaliation',
  Emotion = 'emotion',
  AutoAvaliation = 'autoAvaliation',
}

export enum TimePeriod {
  Month = 'month',
  Bimester = 'bimester',
  Trimester = 'trimester',
  Semester = 'semester',
}

export enum GraphView {
  Year = 'year',
  Time = 'time',
  Comparative = 'comparative',
}

export enum EmotionView {
  Main = 'main',
  Intensity = 'intensity',
}

export enum ComparativePeriod {
  Year = 'year',
  Bimester = 'bimester',
  Trimester = 'trimester',
  Quadrimester = 'quadrimester',
  Semester = 'semester',
}

export interface Graph {
  id:string;
  category: GraphCategory;
  graphView: GraphView;
  source: GraphSource;
  tags?: {
    id: string;
    parentId: string | null;
  }[];
  serieId?: string;
  classId?: string;
  studentId?: string;
  tenantId?: string;
  analysisTypeId?: number;
  analysisEnvironmentId?: number;
  yearOfAnalysis: number;
  timePeriod?: TimePeriod;
  comparativePeriod?: ComparativePeriod;
  comparisonMode?: 'time' | 'student-versus-class';
  comparative1?: number;
  comparative2?: number;
  emotionView?: EmotionView;
  type: GraphType;
  name: string;
}

interface Options {
  [key: string]: boolean;
}
