import { Checkbox, Col, Collapse, Form, Row } from 'antd';
import Text from 'components/typography/text';
import useAllClasses from 'core/features/classes/hooks/useAllClasses';
import {
  Graph,
  GraphCategory,
  GraphSource,
  GraphType,
  GraphView,
} from 'core/features/indicators-profile/typings';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type Serie = {
  id: string;
  name: string;
  minAge: number;
  maxAge: number;
};

type ClassDetails = {
  serie: Serie;
  // Add other properties of ClassDetails if needed
};

function EducatorOptions() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { user } = useCurrentUser();
  const { data } = useAllClasses({ tenantId: tenantId ?? user?.tenantId });
  const { data: competences } = useTagsCompetence({
    competence: true,
  });
  const year = new Date().getFullYear();
  const uniqueSeries = Object.values(
    (data?.data || []).reduce<Record<string, Serie>>(
      (acc, curr: ClassDetails) => {
        acc[curr.serie.id] = curr.serie;
        return acc;
      },
      {}
    )
  );
  const form = Form.useFormInstance();
  const graphs = Form.useWatch(['graphs'], form);

  const handleChange = (action: 'remove' | 'add', serie: Serie) => {
    const classes = data?.data.filter((item) => item.serie.id === serie.id);

    if (action === 'add') {
      const aditions: Graph[] = (classes || []).map((classe) => ({
        id: uuidv4(),
        category: GraphCategory.Activity,
        graphView: GraphView.Year,
        source: GraphSource.Class,
        classId: classe.id,
        serieId: serie.id,
        yearOfAnalysis: year,
        tags:
          competences?.map((item) => ({
            id: item.id,
            parentId: item.parentId,
          })) ?? [],
        analysisTypeId: 3,
        analysisEnvironmentId: 5,
        type: GraphType.ColumnGraph,
        name: classe.name,
      }));
      form.setFieldValue('graphs', [...graphs, ...aditions]);
    }
    if (action === 'remove') {
      const names = classes?.map((item) => item.name);
      const newGraphs = graphs.filter(
        (item: Graph) => !names?.includes(item.name)
      );
      form.setFieldValue('graphs', newGraphs);
    }
  };
  return (
    <Collapse style={{ width: '100%' }} expandIconPosition="end">
      <Collapse.Panel
        style={{
          background: '#F5F5F5',
          borderRadius: '10px',
          width: '100%',
        }}
        header={<Text>{t('app.sugestoesParaVoce')}</Text>}
        key={'1'}
        forceRender
      >
        <Row style={{ padding: '16px' }}>
          {uniqueSeries.map((item) => (
            <Col key={item.id} sm={24}>
              <Checkbox
                onChange={(e) =>
                  handleChange(e.target.checked ? 'add' : 'remove', item)
                }
              >
                {item.name} - {t('app.atividades')}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
}

export default EducatorOptions;
