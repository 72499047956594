import { Flex } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import CustomPanel from './tabs/custom-panel';
import EducatorView from './tabs/educatorView';
import ManagerView from './tabs/managerView';
import OrganizationView from './tabs/organizationView';
import { Permissions } from 'core/resources/enums/permissions';
import GroupButton from 'components/buttons/group-button';
import { NumberParam, useQueryParam } from 'use-query-params';
import { t } from 'core/resources/strings';
import ReportView from 'ui/pages/app/indicators/tabs/reportView';
import StudentView from 'ui/pages/app/indicators/tabs/studentView';
import ClassView from 'ui/pages/app/indicators/tabs/classView';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import { useParams } from 'react-router-dom';
import InstitutionHeader from 'components/institution-header';
import HeaderImage from 'assets/images/indicators-header-image.png';

function IndicatorsPage() {
  const [check] = useCheckPermissions();
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant } = useTenant(tenantId);

  if (!step) {
    setStep(1);
  }
  const views = [
    {
      label: t('app.meuPainel'),
      value: 1,
      show: true,
    },
    {
      label: t('app.turma'),
      value: 6,
      show: check([Permissions.Class.View, Permissions.U4hero.Tenant.View]),
    },
    {
      label:
        t('app.estudante').slice(0, 1).toUpperCase() +
        t('app.estudante').slice(1),
      value: 7,
      show: check([Permissions.Students.View, Permissions.U4hero.Tenant.View]),
    },
    {
      label: t('app.educador'),
      value: 2,
      show: check([
        Permissions.Indicators.View,
        Permissions.U4hero.Tenant.View,
      ]),
    },
    {
      label: t('app.gestor'),
      value: 3,
      show: check([
        Permissions.Indicators.ViewAll,
        Permissions.U4hero.Tenant.View,
      ]),
    },
    {
      label: t('app.visaoOrganizacao'),
      value: 4,
      show: check([Permissions.Org.Indicators.View]),
    },
    {
      label: t('app.relatorio'),
      value: 5,
      show: check([
        Permissions.Indicators.View,
        Permissions.U4hero.Tenant.View,
      ]),
    },
  ];

  return (
    <Layout.Container>
      <Flex vertical gap={16}>
        <Title primary>{t('app.painelDeIndicadores')}</Title>

        <Flex justify="flex-end">
          <GroupButton
            options={views.filter((item) => item.show)}
            value={step as number}
            onChange={(v) => setStep(v as number)}
          />
        </Flex>
        {tenant ? (
          <InstitutionHeader image={HeaderImage} tenantName={tenant.name} />
        ) : null}
        {step === 1 ? <CustomPanel /> : null}
        {step === 2 ? <EducatorView /> : null}
        {step === 3 ? <ManagerView /> : null}
        {step === 4 ? <OrganizationView /> : null}
        {step === 5 ? <ReportView /> : null}
        {step === 6 ? <ClassView /> : null}
        {step === 7 ? <StudentView /> : null}
      </Flex>
    </Layout.Container>
  );
}

export default IndicatorsPage;
