function EventsIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="4230535-calendar-event-star_115022 (1) 1">
        <g id="Group">
          <path
            id="Vector"
            d="M29.5852 51.7969L28.3969 58.7742C28.2141 59.9016 28.6711 61.0289 29.5852 61.6992C30.1031 62.0953 30.7125 62.2781 31.3523 62.2781C31.8398 62.2781 32.2969 62.1562 32.7539 61.943L39.0305 58.6523L45.307 61.943C46.3125 62.4914 47.5313 62.4 48.4758 61.7297C49.4203 61.0594 49.8773 59.932 49.6641 58.8047L48.4758 51.8273L53.5336 46.8914C54.3562 46.0992 54.6609 44.9109 54.2953 43.8141C53.9297 42.7172 53.0156 41.925 51.8883 41.7727L44.8805 40.7672L41.7422 34.4297C41.2242 33.3937 40.1883 32.7539 39.0609 32.7539C37.9031 32.7539 36.8672 33.3937 36.3797 34.4297L33.2414 40.7672L26.2336 41.7727C25.1062 41.925 24.1617 42.7172 23.8266 43.8141C23.4609 44.9109 23.7656 46.0992 24.5883 46.8914L29.5852 51.7969ZM34.3992 43.6008C34.8867 43.5398 35.3133 43.2047 35.5266 42.7781L38.9695 35.7094L42.443 42.7781C42.6563 43.2352 43.0828 43.5398 43.5703 43.6008L51.3398 44.6977L45.7336 50.182C45.368 50.5172 45.2156 51.0352 45.307 51.5227L46.6781 59.2617L39.7008 55.6055C39.2742 55.3617 38.7258 55.3617 38.2992 55.6055L31.3828 59.2617L32.7234 51.4922C32.8148 51.0047 32.632 50.4867 32.2969 50.1516L26.6297 44.6977L34.3992 43.6008Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M71.4492 10.9078H62.918V6.3375C62.918 5.51484 62.2477 4.81406 61.3945 4.81406C60.5414 4.81406 59.8711 5.48438 59.8711 6.3375V10.9078H18.1289V6.3375C18.1289 5.51484 17.4586 4.81406 16.6055 4.81406C15.7828 4.81406 15.082 5.48438 15.082 6.3375V10.9078H6.55078C3.9 10.9078 1.76719 13.0406 1.76719 15.6914V23.2781V68.4023C1.76719 71.0531 3.9 73.1859 6.55078 73.1859H71.4492C74.1 73.1859 76.2328 71.0531 76.2328 68.4023V23.2781V15.6914C76.2328 13.0406 74.0695 10.9078 71.4492 10.9078ZM73.2164 68.4023C73.2164 69.3773 72.4242 70.1695 71.4492 70.1695H6.55078C5.57578 70.1695 4.7836 69.3773 4.7836 68.4023V24.8016H73.1859V68.4023H73.2164ZM73.2164 21.7852H4.7836V15.6914C4.7836 14.7164 5.57578 13.9242 6.55078 13.9242H71.4492C72.4242 13.9242 73.2164 14.7164 73.2164 15.6914V21.7852Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
}

export default EventsIcon;
