export enum reportTypes {
  Activity = 'activity',
  Student = 'student',
  Class = 'class',
  School = 'school',
}

export interface HistoryReportItem {
  id: string;
  description: string | null;
  humor: boolean;
  typeId: number;
  privacyId: number;
  authorId: string | null;
  minAge: number;
  maxAge: number;
  analysisTypeId: number;
  analysisEnvironmentId: number;
  text: string;
  methodology: number;
  tagType: number;
  likertType: null;
  image: null;
  audio: string;
  locale: string;
  percentage?: boolean;
  options: {
    id: string;
    text: string;
    number: null;
    value: number;
    name: string;
    icon: string | null;
    percentage: string | null;
  }[];
  comments: {
    id: string;
    comment: string;
    createdAt: string;
    user: {
      id: string;
      name: string;
      imageUrl: string | null;
    };
  }[];
  name: string;
  skill: string;
  competence: string;
  competenceIcon: string;
  skillIcon: string;
  answer: {
    answerId: string;
    id: string;
    name: string;
    hypothesis: string;
    orientation: string;
  };
}

export interface HistoryReport {
  game: string;
  user: string;
  class: {
    id: string;
    name: string;
    period: string;
    year: number;
    serieId: string;
    userId: string;
    tenantId: string;
    expirateDate: string | null;
    hasDoneAvaliation: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    serie: {
      id: string;
      name: string;
    };
  };
  tenant: string;
  tenantImageUrl: string;
  items: HistoryReportItem[];
}

export interface ReportResult {
  totalCount: number;
  question: {
    id: string;
    analysisTypeId: number;
    questionTags: {
      id: string;
      tagId: string;
      tag: {
        id: string;
        name: {
          en: string;
          es: string;
          'pt-br': string;
        };
        parentId: string | null;
        icon: string;
        color: string;
        reportMessages: {
          ranges: {
            max: number;
            min: number;
            message: {
              en: string;
              es: string;
              'pt-br': string;
            };
            analysisTypeId: number;
          }[];
        };
      };
    }[];
  };
  option: {
    totalValue: number;
  };
}

export interface Report {
  results: {
    [key: number]: ReportResult[];
  };
  activity: {
    id: string;
    tagId: string;
    typeId: number;
    countStudents: number;
    countClasses: number;
    countQuestions: number;
    countGirls: number;
    countBoys: number;
    publishDate: string;
    endDate: string;
    tags: {
      id: string;
      name: {
        en: string;
        es: string;
        'pt-br': string;
      };
      parent: {
        id: string;
        name: {
          en: string;
          es: string;
          'pt-br': string;
        };
      };
    }[];
  };
  countAvaliations: {
    classifications: {
      safeSpaceClassification: string;
      count: number;
    }[];
  };
  predominantEmotion: {
    optionId: number;
    count: number;
  };
}
