import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Modal, Pagination, Row, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useEmotions from 'core/features/graphs/hooks/useEmotions';
import dayjs from 'dayjs';
import { t } from 'core/resources/strings';
import U4Avatar from 'components/u4avatar';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { DataTranslate } from 'core/features/games/typings';
import { currentLocale } from 'core/resources/strings/polyglot';

function ModalEmotions({
  onClose,
  emotion,
  initialDate,
  endDate,
  intensity,
}: {
  emotion?: {
    color: string;
    name: string;
    img: string;
    id: string;
    intensities: {
      id: number;
      text: DataTranslate;
      phrases: DataTranslate[];
    }[];
  };
  initialDate?: string;
  endDate?: string;
  onClose: () => void;
  intensity?: number;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);

  const { data: emotions, isLoading: isLoadingEmotions } = useEmotions({
    page: page,
    pageSize: pageSize,
    initialDate: initialDate
      ? dayjs(initialDate).format('YYYY-MM-DD')
      : undefined,
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
    optionIds: emotion?.id ? [Number(emotion?.id)] : [],
    intensity: intensity,
  });

  return (
    <Modal
      title={
        <SubTitle primary style={{ maxWidth: isMobile ? '238px' : undefined }}>
          {t('app.detalhamentoDeEstudantesPorEmoções')} - {emotion?.name}
        </SubTitle>
      }
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '95%' : 1091}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      {isLoadingEmotions ? (
        <Flex justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <Row gutter={[18, 18]} style={{ marginTop: 16 }}>
          {emotions?.data?.map((emo, key) => (
            <Col xs={24} md={8} key={key}>
              <Flex
                className="box-shadow-generic-card"
                style={{
                  padding: '16px 18px',
                  width: '100%',
                  position: 'relative',
                }}
                gap={'12px'}
                align="start"
              >
                <Flex style={{ minWidth: '80px' }}>
                  <U4Avatar
                    frame={
                      emo?.user?.avatar
                        ? emo?.user?.avatar?.frame?.imageUrl
                        : defaultFrame
                    }
                    background={
                      emo?.user?.avatar
                        ? emo?.user?.avatar?.background?.imageUrl
                        : defaultBackground
                    }
                    icon={
                      emo?.user?.avatar
                        ? (emo?.user?.avatar?.icon?.imageUrl as string)
                        : defaultIcon
                    }
                    size="medium"
                  />
                </Flex>

                <Flex vertical gap="8px">
                  <Text
                    size="md"
                    style={{
                      background: hex2rgba(emotion?.color!, 0.2),
                      padding: '4px 8px',
                      borderRadius: '4px',
                    }}
                    bold
                  >
                    {emo?.user?.name}
                  </Text>
                  <Text size="md">
                    {emo?.class?.serie?.name} - {emo?.class?.name}
                  </Text>
                  <Text size="md" bold color={emotion?.color}>
                    {
                      emotion?.intensities.find(
                        (item) => item.id === emo.intensity
                      )?.text[currentLocale]
                    }
                  </Text>
                  <Text
                    size="sm"
                    style={{ alignSelf: 'flex-end' }}
                    color={colorGreyBorderAndFont}
                  >
                    {dayjs(emo.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Text>
                </Flex>
              </Flex>
            </Col>
          ))}
        </Row>
      )}

      <Flex justify="center">
        <Pagination
          style={{ margin: 16 }}
          current={page ?? 1}
          total={emotions?.total ?? 0}
          pageSize={pageSize ?? 0}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Flex>
    </Modal>
  );
}

export default ModalEmotions;
