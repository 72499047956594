import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Steps } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import {
  PiCalendarStarLight,
  PiCirclesFourLight,
  PiEyeFill,
} from 'react-icons/pi';
import { useHistory, useParams, useLocation } from 'react-router';
import Button from 'components/buttons/button';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import moment from 'moment';
import PreviewModal from 'ui/pages/app/posts-hub/events/components/PreviewModal';
import { useSaveEvent } from 'core/features/events/hooks/useSaveEvent';
import useEvent from 'core/features/events/hooks/useEvent';
import { EventForm } from 'core/features/events/typings';
import EventDetails from 'ui/pages/app/posts-hub/events/pages/CreateEvent/steps/eventDetails';
import { FaCheckCircle } from 'react-icons/fa';
import React from 'react';
import EventItems from 'ui/pages/app/posts-hub/events/pages/CreateEvent/steps/eventItems';
import { t } from 'core/resources/strings';

function CreateEvent() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const location = useLocation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams<{
    id: string;
  }>();
  const { data } = useEvent(params.id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveEvent();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [isPreviewOpen, setIsPreviewOpen] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get('preview') === 'true';
  });

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = ({ frames, backgrounds, icons, ...values }: EventForm) => {
    const storeItems = [
      ...(frames ?? []),
      ...(backgrounds ?? []),
      ...(icons ?? []),
    ].map((item) => item.id);

    handleSave(
      {
        ...values,
        active: values.active || true,
        id: params.id,
        storeItems,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'criar'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              !values.active
                ? 'Evento salvo como rascunho!'
                : `Evento ${params.id ? 'editado' : 'criado'} com sucesso!`
            }`
          );
          setTimeout(() => {
            history.push('/events/manager');
          }, 200);
        },
      }
    );
  };

  const handleClosePreview = () => {
    const params = new URLSearchParams(location.search);
    params.delete('preview');
    history.replace({ search: params.toString() });
    setIsPreviewOpen(false);
  };

  const handleOpenPreview = () => {
    const params = new URLSearchParams(location.search);
    params.set('preview', 'true');
    history.replace({ search: params.toString() });
    setIsPreviewOpen(true);
  };

  const isStepEnabled = (stepIndex: number) => {
    if (stepIndex === 0) {
      return true;
    }
    if (stepIndex === 1) {
      return (
        formValues?.name?.length > 1 &&
        formValues?.startDate &&
        formValues?.endDate &&
        formValues?.description?.length > 1 &&
        formValues?.splashArt &&
        formValues?.billboard
      );
    }
  };

  const steps = [
    {
      title: 'Detalhes do Evento',
      content: <EventDetails />,
      icon: <PiCalendarStarLight size={32} />,
    },
    {
      title: 'Definição dos Itens',
      content: <EventItems />,
      icon: <PiCirclesFourLight size={32} />,
    },
  ];

  const items = steps.map((item, index) => ({
    key: item.title,
    title: isMobile ? undefined : (
      <span
        id={`id-tour-activity-create-step-${item.title.toLowerCase()}`}
        style={{
          fontSize: '16px',
          fontWeight: 700,
          color: current >= index ? colorPrimary : colorGreyBorderAndFont,
        }}
      >
        {item.title}
      </span>
    ),
    icon:
      current > index ? (
        <FaCheckCircle
          size={32}
          color={colorPrimary}
          onClick={() => {
            setCurrent(index);
          }}
          cursor="pointer"
        />
      ) : (
        React.cloneElement(item.icon, {
          color: current === index ? colorPrimary : colorGreyBorderAndFont,
          onClick: () => {
            if (params.id) {
              setCurrent(index);
            } else {
              if (isStepEnabled(index)) {
                setCurrent(index);
              }
            }
          },
          cursor: params.id
            ? 'pointer'
            : isStepEnabled(index)
            ? 'pointer'
            : 'not-allowed',
        })
      ),
  }));

  return (
    <Layout.Container>
      {isPreviewOpen && (
        <PreviewModal onClose={handleClosePreview} {...formValues} />
      )}

      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data
            ? {
                ...data,
                startDate: moment.utc(data.startDate).format('YYYY-MM-DD'),
                endDate: moment.utc(data.endDate).format('YYYY-MM-DD'),
                frames: data.items.filter((item) => item.type === 'frame'),
                backgrounds: data.items.filter(
                  (item) => item.type === 'background'
                ),
                icons: data.items.filter((item) => item.type === 'icon'),
              }
            : {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                active: true,
                frames: [],
                backgrounds: [],
                icons: [],
              }
        }
      >
        <Flex gap="23px" align="center" style={{ marginBottom: '12px' }}>
          <BackButton
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  colorPrimary,
                  isMobile,
                  onClose: () => history.push('/events/manager'),
                });
              } else {
                history.push('/events/manager');
              }
            }}
          />

          <Title primary>{params.id ? 'Editar' : 'Criar'} Evento</Title>
        </Flex>

        <Steps
          responsive={false}
          current={current}
          items={items}
          style={{ marginBottom: '16px' }}
        />

        <Flex
          gap="16px"
          justify="end"
          style={{ marginBottom: isMobile ? '8px' : '16px' }}
        >
          {current > 0 ? (
            <Button
              variant="outline"
              size="md"
              id="id-tour-events-create-button-previous"
              onClick={() => setCurrent(current - 1)}
            >
              {t('app.anterior')}
            </Button>
          ) : (
            <Button
              variant="outline"
              size="md"
              id="id-tour-events-create-button-preview"
              style={{
                display: 'flex',
                gap: isMobile ? '4px' : '16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleOpenPreview}
            >
              <PiEyeFill size={isMobile ? 16 : 28} color={colorPrimary} />{' '}
              PRÉVIA
            </Button>
          )}

          {current < steps.length - 1 ? (
            <Button
              size="md"
              id="id-tour-create-event-button-next"
              disabled={!isStepEnabled(1)}
              loading={isLoadingSave}
              onClick={() => setCurrent(current + 1)}
            >
              {t('app.proximo')}
            </Button>
          ) : null}
          {current === steps.length - 1 ? (
            <Button
              htmlType="submit"
              size="md"
              id="id-tour-create-event-button-next"
              disabled={false}
              loading={isLoadingSave}
            >
              {t('app.finalizar')}
            </Button>
          ) : null}
        </Flex>

        {steps.map((item, index) => (
          <div
            style={{
              marginBottom: '16px',
              display: current === index ? '' : 'none',
            }}
          >
            {item.content}
          </div>
        ))}
      </Form>
    </Layout.Container>
  );
}

export default CreateEvent;
